import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GetStartedService } from 'src/app/core/services/get-started.service';
import { OrderHelperService } from 'src/app/core/services/order-helper.service';
import { OrderService } from 'src/app/core/services/order.service';

@Component({
  selector: 'app-confirm-order',
  templateUrl: './confirm-order.component.html',
  styleUrls: ['./confirm-order.component.scss']
})
export class ConfirmOrderComponent implements OnInit {
  @Input('orderId') orderId: any;
  @Input('garmentList') garmentList: any;
  @Input('isEditOrder') isEditOrder: any;
  @Input('pickupDefaultAddress') pickupDefaultAddress: any;
  @Input('deliveryDefaultAddress') deliveryDefaultAddress: any;
  @Input('defaultAddress') defaultAddress: any;
  @Input('userSubscription') userSubscription: any;
  @Output() confirmOrderSubmit: any = new EventEmitter<string>();
  @Output() confirmOrderBack: any = new EventEmitter<string>();
  @Output() payLater: any = new EventEmitter<string>();
  orderInfo:any;
  totalAmount: any = 0;
  taxAmount = 0.00;
  discountAmount = 0.00;
  discountTypeAmount = 0.00;
  appTypeDiscountAmount = 0.00;
  promotionDiscountAmount = 0.00;
  subscriptionDiscountAmount = 0.00;

  constructor(private orderHelperService : OrderHelperService, private orderService: OrderService)  { }

  ngOnInit(): void {
     this.orderInfo =  this.orderHelperService.getOrder();
     this.getOrderInvoice();
  }

  getOrderInvoice() {
    let selectedItem  = [];
    let orderDetails: any = this.orderHelperService.getAddedGarment();
    let garmentGroups = Object.keys(orderDetails);
    for (let garmentGroup of garmentGroups) {
      let garmentSubGroup = Object.keys(orderDetails[garmentGroup]);
      for (let garmentSub of garmentSubGroup) {
        orderDetails[garmentGroup][garmentSub].map((list) => {
          let item = {};
          let serviceKeys = Object.keys(list.garment?.serviceInstructions);
          let serviceId =  list.garment?.serviceInstructions![serviceKeys[0]];
          item["garmentId"] = list.garment.garmentId;
          item["garmentName"] = list.garment.garmentName;
          item["serviceId"] = serviceId,
          item["serviceName"] = garmentGroup;
          item['garmentsCount'] = 1;
          item['updatedPrice'] = list.amount;
          selectedItem.push(item);
        });
      }
    }
    let payload = {
        "vendorId":'',
        "storeId":'',
        "orderId": this.orderId,
        "userId":'',
        "priceListId":0,
        "discountAmount":0.0,
        "promotionCode":null,
        "items":selectedItem
    }
    this.orderService.orderInvoice(payload).subscribe(
      (res)=> {
        this.taxAmount =  res.taxAmount;
        this.totalAmount = res.totalAmount;
        this.discountAmount = res.discountAmount;
        this.discountTypeAmount = res.discountTypeAmount;
        this.appTypeDiscountAmount = res.appTypeDiscountAmount;
        this.promotionDiscountAmount = res.promotionDiscountAmount;
        this.subscriptionDiscountAmount = res.subscriptionDiscountAmount;
      },
      (err)=> {
       console.log('order invoice api error');
      }
    )
  }

 

  getTotalCount() {
    let totalCount = 0;
    for (let key in this.garmentList) {
      for (let item of this.garmentList[key]) {
        totalCount += item.count;
      }
    }
    return totalCount;
  }

  getTotalPrice() {
    let totalPrice = 0;
    for (let key in this.garmentList) {
      for (let item of this.garmentList[key]) {
        totalPrice += item.count * ( item.prices![0]?.price ?? 0 );
      }
    }
    return totalPrice.toFixed(2);
  }

  getAmount(count: number, price: number): number {
    return +count * +price;
  }

  getFinalTotal(): number {
    const total = Number(this.getTotalPrice());
    const tax = Number(this.taxAmount) || 0;
    const discount = Number(this.discountAmount) || 0;
    return total + tax - discount;
  }

  onConfirmOrder() {
    let order = this.orderHelperService.getOrder();
    order['chrgAmt'] = this.totalAmount;
    order['orderAmount'] = this.totalAmount;
    order['taxAmount'] = this.taxAmount;
    order['discount'] = this.discountAmount ?? 0.0;
    this.orderHelperService.setOrder(order);
    this.confirmOrderSubmit.emit(true);
  }

  onconfirmOrderBack() {
   this.confirmOrderBack.emit(true);
  }

  onPayLater() {
    this.payLater.emit(true);
  }
}
