<div class="stain-damage">
    <h1 class="header-title">add garment</h1>
    <div class="header-line"></div>
  
    <div class="row">
      <form [formGroup]="imageUpload" class="col-sm-12">
        <div class="row image-drop drag-drop-container" id="imageDrop" (click)='imageInput.click()' (drop)="drop($event)"
          (dragover)="allowDrop($event)" #imageDrop>
          <ng-container *ngIf="files.length <=0">
            <span class="upload material-icons">
              file_upload
            </span>
            <p>drag and drop images here</p>
          </ng-container>
          <div class="image-list">
            <ng-container *ngFor="let url of files;let i=index">
              <mat-card class="example-card">
                <mat-icon class="material-icon" aria-hidden="false" aria-label="Example home icon"
                  >close</mat-icon>
                <img mat-card-image [src]="url" class="rounded" width="100">
              </mat-card>
            </ng-container>
          </div>
          <input type="file" accept="image/x-png,image/gif,image/jpeg, image/jpg" formControlName="imageInput"  #imageInput id="imageInput"
            (change)='imageChange($event)'>
        </div>
  
        <div class="row">
          <div class="col-sm-12 comment-addstain">
            <textarea class="custom-textbox add-comment" formControlName="comment"
              placeholder="describe your garment here.."></textarea>
          </div>
        </div>
        <div class="col-sm-12 footer-btn">
          <button class="started-btn back-btn" mat-button (click)="onBack()">back</button>
          <button class="started-btn next-btn" (click)="onSave()" mat-button>save</button>
        </div>
      </form>
  </div>
</div>  
