<div class="payment ">
  <div class="material-new-tabs">
    <div #dropinContainer></div>
    <button (click)="submitPayment()" class="next-btn pay-button mt-4">Pay</button>
    <!-- <mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabClick($event)">
      <mat-tab label="credit/debit card">
        <ng-template mat-tab-label>
          <img src="../../../assets/icon/Icon__credit card.png" width="40px">
          <span>credit/debit card</span>
        </ng-template>
        
        <form id="cardForm" *ngIf="braintreeForm">
          <div class="panel">
            <div class="panel__content row">
              <div class="textfield--float-label col-sm-6">
                <label class="hosted-field--label" for="card-number"><span class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
                    </svg></span> Card Number
                </label>
                <div id="card-number" class="hosted-field"></div>
              </div>
              <div class="textfield--float-label col-sm-6">
                <label class="hosted-field--label" for="expiration-date">
                  <span class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                      <path
                        d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z" />
                    </svg>
                  </span>
                  Expiration Date</label>
                <div id="expiration-date" class="hosted-field"></div>
              </div>
              <div class="textfield--float-label col-sm-6">
                <label class="hosted-field--label" for="cvv">
                  <span class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                      <path
                        d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                    </svg>
                  </span>
                  CVV</label>
                <div id="cvv" class="hosted-field"></div>
              </div>
              <div class="custom-name col-sm-6">
                <label class="hosted-field--label" for="cardholder-name" name="cardholderName">
                  <span class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path
                        d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                  </span>
                   name</label>
                <input type="text" placeholder="Name" class="name-custom-input" [(ngModel)]="cardholdersName"
                  name="cardholderName" />
              </div>
            <div class="col-sm-12 billing-address">
                <div class="flex">
                    <h3>billing address </h3>
                </div>
                <ng-container *ngIf="regAddress">
                  <p>
                      {{regAddress.firstName}},<br> {{regAddress.address[0].addressLine1}}, 
                      <span *ngIf="regAddress?.address[0]?.addressLine2">{{regAddress.address[0].addressLine2}}, </span> <br> {{regAddress.address[0].city}} {{regAddress.address[0].zipCode}}
                  </p>
                </ng-container>
            </div>
            </div>
            <footer class="panel__footer col-sm-12">
              <div class="footer-btn col-sm-12">
                <button class="started-btn back-btn" mat-button matStepperPrevious>cancel</button>
                <input mat-button class="started-btn next-btn" type="button" value="review order" (click)="submitPayment();" />
              </div>
            </footer>
          </div>
        </form>
        
      </mat-tab>
      <mat-tab label="paypal">
        <ng-template mat-tab-label>
          <img src="../../../assets/icon/Icon__paypal.png" width="40px">
          <span>paypal</span>
        </ng-template>

      </mat-tab>
      <mat-tab label="net banking">
        <ng-template mat-tab-label>
          <img src="../../../assets/icon/Icon__bank.png" width="40px">
          <span>net banking</span>
        </ng-template>
      </mat-tab>
    </mat-tab-group> -->
  </div>
</div>