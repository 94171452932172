
<div [ngClass]="{'pt-200 pl-100 pr-100 box-center': firstTimeUser}" class="profile-form">
    <h1 class="header-title">my profile</h1>
    <div class="header-line"></div>
    <div class="w-900">
        <form  [formGroup]="profileForm" class="row w-900">
            <div class="col-sm-6">
                <mat-form-field dense appearance="legacy" class="field-6">
                    <mat-label>first name</mat-label>
                    <input type="text" formControlName="firstName" matInput
                        placeholder="">
                </mat-form-field>
            </div>
            <div class="col-sm-6 ">
                <mat-form-field dense appearance="legacy" class="field-6">
                    <mat-label>last name</mat-label>
                    <input type="text" formControlName="lastName" matInput
                        placeholder="">
                </mat-form-field>
            </div>
            <div class="col-sm-6 ">
                <mat-form-field dense appearance="legacy" class="field-6">
                    <mat-label>mobile number</mat-label>
                    <input type="text" formControlName="phoneNumber"  matInput
                        placeholder="">
                </mat-form-field>
            </div>
            <div class="col-sm-6 ">
                <mat-form-field dense appearance="legacy" class="field-6">
                    <mat-label>email address</mat-label>
                    <input type="text" formControlName="emailId" matInput
                        placeholder="">
                </mat-form-field>
            </div>
            <div class="col-sm-6 ">
                <mat-form-field dense appearance="legacy" class="field-6">
                    <mat-label>gender</mat-label>
                    <mat-select name="gender" formControlName="gender" >
                      <mat-option *ngFor="let gender of genderTypes" [value]="gender">
                       {{gender}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-6 ">
                <mat-form-field  dense appearance="legacy" class="field-6">
                  <mat-label>Date of Birth</mat-label>
                  <input matInput [max]="maxDate" [matDatepicker]="picker" formControlName="dob">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-12 ">
                  <mat-form-field dense appearance="legacy" class="field-12" >
                    <mat-label>search your address here</mat-label>
                    <input matGoogleMapsAutocomplete #addressLine1
                    country="us" 
                    (onAutocompleteSelected)="onAutocompleteSelected($event)"
                    (onLocationSelected)="onLocationSelected($event)"
                     matInput>
                  </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field dense appearance="legacy" class="field-6 ">
                  <mat-label>location type</mat-label>
                  <mat-select name="locationType" formControlName="addressTypeId" disableOptionCentering >
                    <mat-option *ngFor="let location of locationTypes" [value]="location.id">
                     {{location.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- <div class="col-md-6 pl40">
                <mat-form-field dense appearance="legacy" class="field-6 ">
                  <mat-label>select country</mat-label>
                  <mat-select name="countryId" formControlName="country" disableOptionCentering (selectionChange)="getState()">
                    <mat-option *ngFor="let country of countryList" [value]="country.rowId">
                     {{country.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
      
              <div class="col-md-6 pr40">
                <mat-form-field dense appearance="legacy" class="field-6 ">
                  <mat-label>select state</mat-label>
                  <mat-select name="stateId" formControlName="state" disableOptionCentering (selectionChange)="getCity()">
                    <mat-option *ngFor="let state of stateList" [value]="state.rowId">
                     {{state.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
      
              <div class="col-md-6 pl40">
                <mat-form-field dense appearance="legacy" class="field-6 ">
                  <mat-label>select city</mat-label>
                   <mat-select name="cityId" formControlName="city" disableOptionCentering>
                    <mat-option *ngFor="let city of cityList" [value]="city.rowId">
                     {{city.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->
              <div class="col-md-6 ">
                <mat-form-field dense appearance="legacy" class="field-6 ">
                  <mat-label>zip code</mat-label>
                  <input formControlName="zipCode" type="text" matInput placeholder="">
                </mat-form-field>
              </div>
        </form>
        <div class="footer-btn">
            <button *ngIf="!showBackButton" class="back-btn" mat-button (click)="onBack()">back</button>
            <button  class="next-btn" (click)="onSave()" mat-button>save</button>
        </div>
    </div>
</div>