<div class="delete-account">
    <h1 class="header-title">delete account</h1>
    <div class="header-line"></div>
    <button class="confirm-btn next-btn mb-50 mt-20" mat-button (click)="getConfirmationCode()"> send code for confirmation</button>
    <div class="text-center">
        <ng-otp-input (paste)="onOtpPaste($event)" #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
    </div>
    <div class="showCodeOtp p-3">
        <button id="showcode" class="show-code" (click)="showCode()">
            <mat-icon matSuffix class="eye-icon">visibility_off</mat-icon>
            show code
        </button>
        <button class="resend-otp" (click)="resendOtp()">resend otp</button>
    </div>
    <div class="full-width pb-30 pt-30">
        <div class="setting-checkbox">
            <mat-checkbox checked="true" class="i-agree text-white"><a>I'm sure i want to delete my account</a>
            </mat-checkbox>
        </div>
    </div>
    <button class="confirm-btn next-btn" mat-button (click)="onConfirm()"> confirm</button>
</div>