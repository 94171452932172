import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {DataViewModule} from 'primeng/dataview';
import {SelectButtonModule} from 'primeng/selectbutton';
import {InputTextModule} from 'primeng/inputtext';
import {PaginatorModule} from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import {DropdownModule} from 'primeng/dropdown';

const primeModules = [
  TableModule,
  ButtonModule,
  CardModule,
  DataViewModule,
  SelectButtonModule,
  InputTextModule,
  PaginatorModule,
  TooltipModule,
  DropdownModule,
  TableModule,
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...primeModules
  ], 
  exports:[...primeModules]
})
export class PrimengModule { }
