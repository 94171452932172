<div class="your-address">
  <ng-container *ngIf="addressList">
    <h1 class="header-title">select {{headingTitle}} address</h1>
    <div class="header-line"></div>
    <ul class="address-list">
      <ng-container *ngFor="let address of ListOfAddress">
        <li class="address"  [ngClass]="displayActiveness(address)" (click)="selectedAddress = 0">
          <ul>
            <li>{{address.addressLine1}}</li>
            <li>{{address.addressLine2}}</li>
            <li>{{address.city}}, {{address.state}}</li>
            <li>{{address.country}} - {{address.zipCode}}</li>
            <li>
              <a (click)="updateAddress(address)">change</a>
              <a *ngIf="address.addressDefault == 'false'" (click)="onDeleteAddress(address)">remove</a>
              <a (click)="onMakeDefault(address)">default</a>
            </li>
          </ul>
        </li>
      </ng-container>
      <li class="add-address" (click)="onAddressAdd()">
        <span class="material-icons">
          add
        </span>
      <p>add new address</p>
    </li>
    </ul>
    <div class="button-group pt-4" style=width:300px >
      <button class="next-btn" (click)="addressListBack()" mat-button style=width:300px>back</button>
    </div>
  </ng-container>
  <ng-container *ngIf="addAddress">
    <app-add-address (addAddressEvent)="onAddressBack()" [editAddress]="updateAddressInfo" ></app-add-address>
  </ng-container>
</div>