<div class="technical-support container">
  <h1 class="header-title">technical support</h1>
  <div class="header-line"></div>
  <section class="contact-us">
    <h2 class="col-sm-12 text-center message-title">contact us</h2>
    <div class="">
      <div class="row-wrap">
        <span>email:</span>
        <span>{{supportEmail}}</span>
      </div>
      <div class="row-wrap">
        <span>phone:</span>
        <span>(706)-216-5245</span>
      </div>
      <div class="row-wrap">
        <span>hours:</span>
        <span>monday to friday 8 a.m. to 6 p.m</span>
      </div>
      <div class="row-wrap">
        <span>address:</span>
        <span>66, S 400 Center Ln, Suite 185, Dawsonville, GA 30534
        </span>
      </div>
    </div>
  </section>
  <form class="row">
    <h2 class="col-sm-12 text-center message-title">leave us your message</h2>
    <div class="col-sm-6">
      <mat-form-field dense class="field-6" appearance="legacy">
        <input matInput placeholder="name" />
      </mat-form-field>
    </div>
    <div class="col-sm-6">
      <mat-form-field dense class="field-6" appearance="legacy">
        <input matInput placeholder="email address" />
      </mat-form-field>
    </div>
    <div class="col-sm-12">
      <mat-form-field dense class="full-width" appearance="legacy">
        <input matInput placeholder="your message" />
      </mat-form-field>
    </div>
    <button class="single-btn mt-3" mat-button>submit</button>
  </form>
</div>