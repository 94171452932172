<div class="payment-history">
  <h1 class="header-title">payment history</h1>
  <div class="header-line"></div>

  <p-table [value]="rows" [paginator]="isPagination" [rows]="5" [resizableColumns]="true" [totalRecords]="rows.length"
    [responsive]="true" [showCurrentPageReport]="true" [rowsPerPageOptions]="[5,10,15]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [loading]="loading">
    <ng-template pTemplate="header">
      <tr>
        <th >invoice no</th>
        <th pResizableColumn>invoice date</th>
        <th pResizableColumn>
          amount
        </th>
        <th pResizableColumn>
          payment mode
        </th>
        <th pResizableColumn>action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">

      <tr>
        <td class="ui-resizable-column">{{rowData.invoiceId}}</td>
        <td class="ui-resizable-column" style="width:500px">{{getDate(rowData.paymentDate)}}</td>
        <td class="ui-resizable-column">{{rowData.currency}} {{rowData.paymentAmount}}</td>
        <td class="ui-resizable-column">{{rowData.paymentMethodType}}</td>
        <td class="ui-resizable-column">
          <a class="datatable-link">
            <span>view</span>
            <span class="line">|</span>
            <span>pdf</span>
          </a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>