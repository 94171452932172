<div class="container get-started-page">
  <div class="row ">
    <div class="col-md-4">
      <div class="main-circle">
        <ul>
          <li class=" basic circle" (click)="move(0)"
            [ngClass]="{'active': stepper.selectedIndex == 0, 'completed': stepper.selectedIndex > 0}"><a
              ><span>basic</span>
            </a>
          </li>
          <li class=" address circle" (click)="move(1)"
            [ngClass]="{'active': stepper.selectedIndex == 1, 'completed': stepper.selectedIndex > 1}"><a
              ><span>address</span>
            </a>
          </li>
          <li class=" package circle" (click)="move(2)"
            [ngClass]="{'active': stepper.selectedIndex == 2, 'completed': stepper.selectedIndex > 2}"><a
              ><span>package</span>
            </a>
          </li>
          <li class=" payment circle" (click)="move(3)"
            [ngClass]="{'active': stepper.selectedIndex == 3, 'completed': stepper.selectedIndex > 3}"><a
              ><span>payment</span>
            </a>
          </li>
          <li class=" confirm circle"  (click)="move(4)"
            [ngClass]="{'active': stepper.selectedIndex == 4, 'completed': stepper.selectedIndex > 4}"><a
             ><span>confirm</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-8 ">
      <mat-vertical-stepper #stepper [@.disabled]="true">
        <mat-step label="Step 1">
          <div class="box col-12">
            <app-basic-details (basicNextEvent)="basicNext($event)"></app-basic-details>
          </div>
        </mat-step>
        <mat-step label="Step 2">
          <div class="box col-12" style="padding-top: 70px;">
            <app-address [basicDetails]="basicFormDetails" (addressNextEvent)="addressNext($event)"></app-address>
          </div>
        </mat-step>
        <mat-step label="Step 3">

          <div class="material-new-tabs box col-12" style="padding-top: 30px;">
          <mat-tab-group >

             <!-- Tab for À la carte Account -->
             <mat-tab label="onetime">
              <ng-container>
                <app-alacarte (alacarteNextEvent)="onAlacarteNext($event)"></app-alacarte>
            </ng-container>
            </mat-tab>

            <!-- Tab for Individual Account -->
            <mat-tab label="subscription">
              <ng-container>
                <app-individual-account [footerButton]="footerButton"
                 (packNextEvent) = "onPackBack($event)"
                 (invoiceEvent) = "invoiceEvent($event)"></app-individual-account>
              </ng-container>
            </mat-tab>
        
            <!-- Tab for Corporate Account -->
            <mat-tab label="business">
              <ng-container>
                  <app-corporate-account  (corpNextEvent)="onCorpNext($event)"></app-corporate-account>
              </ng-container>
            </mat-tab>
          </mat-tab-group>
        </div>
        </mat-step>
        <mat-step label="Step 4">
          <div class="box">
            <ng-container *ngIf="stepper.selectedIndex == 3">
              <app-payment-method [rxToken]="rxToken" (nonceEvent) = "nonceEvent($event)"></app-payment-method>
            </ng-container>
          </div>
        </mat-step>
        <mat-step label="Step 5">
          <div class="box" *ngIf="stepper.selectedIndex == 4">
            <app-order-summary 
              [invoiceDetails]="invoiceDetails" 
              [nonceDetails]="nonceDetails"
              (changePaymentEvent)="changePayment($event)"
              (paymentSuccess)="paymentSuccess($event)"
               ></app-order-summary>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </div>
</div>

