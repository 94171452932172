import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import dropin from 'braintree-web-drop-in';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { PaymentService } from 'src/app/core/services/payment.service';

@Component({
  selector: 'app-braintree-payment',
  templateUrl: './braintree-payment.component.html',
  styleUrls: ['./braintree-payment.component.scss']
})
export class BraintreePaymentComponent implements OnInit {

  @ViewChild('dropinContainer', { static: true }) dropinContainer!: ElementRef;
  instance: any;
  @Input('paymentPayload') paymentPayload: any;
  @Output() paymentStatus: any = new EventEmitter<string>();

  constructor(
    private http: HttpClient,
    private paymentService: PaymentService,
    private authService: AuthService,
  ) {}

  scrollTop() {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
  }

  ngOnInit(): void {
    this.scrollTop();
    if(this.paymentPayload.txToken){
      this.initializeBraintree();
    }
  }

  initializeBraintree(): void {
    let totalAmount = this.paymentPayload.totalAmount?.toString();
    dropin.create(
      {
        authorization: this.paymentPayload.txToken,
        container: this.dropinContainer.nativeElement,
        card: {
          cardholderName: {
            required: true
          }
        },styles: {
          input: {
            'font-size': '16px',
            'color': '#333', // Customize text color
            'font-family': 'Arial, sans-serif',
          },
          ':focus': {
            color: '#007bff', // Customize focus color
          },
          '.valid': {
            color: '#28a745' // Customize valid state color
          },
          '.invalid': {
            color: '#dc3545' // Customize invalid state color
          }
        },
        applePay: true, // Enable Apple Pay if required
        googlePay: {
          googlePayVersion: 2,
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPrice: totalAmount,  // Replace with your dynamic total
            currencyCode: 'USD'
          },
          merchantInfo: {
            merchantId: '466xqmx9ryy7rcbx',
            merchantName: 'YOUR_MERCHANT_NAME'
          }
        }
      },
      (err: any, dropinInstance: any) => {
        if (err) {
          console.error('Error initializing Braintree drop-in:', err);
          return;
        }
        this.instance = dropinInstance;
      }
    );
  }

  async submitPayment(): Promise<void> {
    if (!this.instance) {
      console.error('Braintree instance not initialized');
      return;
    }

    this.instance.requestPaymentMethod((err: any, payload: any) => {
      if (err) {
        console.error('Error requesting payment method:', err);
        return;
      }
      let user = this.authService.getUser();
      var data = {
        "userId": user.userId,
        "vendorId": user.vendorId,
        "orderId": this.paymentPayload.orderId,
        "paymentMethodNonce": payload.nonce,
        "invoiceId": this.paymentPayload.invoiceId ,
        "paymentNote": "sale",
      };

      this.paymentService.paymentCheckout(data).subscribe(
        (err)=> {
          this.paymentStatus.next(true);
        },
        (err)=> {
          console.log('payment failed');
        }
      )
    });
  }

}
