<div class="first-time-user">
  <app-my-profile *ngIf="firstUserStep == 0" [firstTimeUser]="firstTimeUser" (ProfileEvent)="onMove($event)">
  </app-my-profile>

  <app-preference *ngIf="firstUserStep == 1" [firstTimeUser]="firstTimeUser" (PreferenceEvent)="onMove($event)">
  </app-preference>

  <!-- <app-garment-care *ngIf="firstUserStep == 2" [firstTimeUser]="firstTimeUser" (careEvent)="onMove($event)">
  </app-garment-care> -->

  <app-pickup-delivery-address *ngIf="firstUserStep == 2" [firstTimeUser]="firstTimeUser"
    (pickupEvent)="onMove($event)">
  </app-pickup-delivery-address>
</div>