import { Component, OnInit } from '@angular/core';

import { ApplePaySession, ApplePay, googlePayment, client, Client } from 'braintree-web';

declare var braintree: any;
declare var google: any;

@Component({
  selector: 'app-payment-integration',
  templateUrl: './payment-intergration.component.html',
  styleUrls: ['./payment-intergration.component.scss']
})
export class PaymentIntegrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      // this.loadgpay();
      // this.loadapplepay();
      this.loadCreditCard();
      this.loadgpay1();
    }, 2000);
  }

  loadgpay() {
    const button: any = document.querySelector('#google-pay-button');
    const paymentsClient = new google.payments.api.PaymentsClient({
      environment: 'TEST' // Or 'PRODUCTION'
    });

    braintree.client.create({
      authorization: "sandbox_mfhchgdh_466xqmx9ryy7rcbx"
    }).then((clientInstance: any) => {
      return braintree.googlePayment.create({
        client: clientInstance,
        googlePayVersion: 2,
        googleMerchantId: '466xqmx9ryy7rcbx' // Replace with your Google Merchant ID
      });
    }).then((googlePaymentInstance: any) => {
      return paymentsClient.isReadyToPay({
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
        existingPaymentMethodRequired: true // Optional
      }).then((response: any) => {
        if (response.result) {
          button.addEventListener('click', (event: any) => {
            event.preventDefault();

            const paymentDataRequest = googlePaymentInstance.createPaymentDataRequest({
              transactionInfo: {
                currencyCode: 'USD',
                totalPriceStatus: 'FINAL',
                totalPrice: '100.00' // Your amount
              }
            });

            const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
            cardPaymentMethod.parameters.billingAddressRequired = true;
            cardPaymentMethod.parameters.billingAddressParameters = {
              format: 'FULL',
              phoneNumberRequired: true
            };

            paymentsClient.loadPaymentData(paymentDataRequest)
              .then((paymentData: any) => googlePaymentInstance.parseResponse(paymentData))
              .then((result: any) => {
                // Send result.nonce to your server
                // result.type may be either "AndroidPayCard" or "PayPalAccount", and
                // paymentData will contain the billingAddress for card payments
              })
              .catch((err: any) => {
                // Handle errors
                console.error(err);
              });
          });
        }
      });
    }).catch((err: any) => {
      // Handle creation errors
      console.error(err);
    });
  }

  loadapplepay() {
    console.log("test apple pay");
    const button: any = document.querySelector('#apple-pay-button');

    braintree.client.create({
      authorization: "sandbox_mfhchgdh_466xqmx9ryy7rcbx"
    }).then((clientInstance: any) => {
      return braintree.applePay.create({ client: clientInstance });
    }).then((applePayInstance: any) => {
      const promise: any = ApplePaySession.canMakePaymentsWithActiveCard(applePayInstance.merchantIdentifier);
      promise.then((canMakePaymentsWithActiveCard: any) => {
        if (canMakePaymentsWithActiveCard) {
          // Set up Apple Pay buttons
        }
      });

      button.addEventListener('click', (event: any) => {
        event.preventDefault();

        const paymentRequest = applePayInstance.createPaymentRequest({
          total: {
            label: 'My Company',
            amount: '19.99'
          }
        });

        const session = new ApplePaySession(3, paymentRequest);

        session.onvalidatemerchant = (event: any) => {
          applePayInstance.performValidation({
            validationURL: event.validationURL,
            displayName: 'My Great Store'
          }, (validationErr: any, validationData: any) => {
            if (validationErr) {
              console.error(validationErr);
              session.abort();
              return;
            }
            session.completeMerchantValidation(validationData);
          });
        };
      });
    }).catch((err: any) => {
      console.error("err", err);
      // Handle creation errors
    });
  }

  loadCreditCard() {
    braintree.client.create({
      authorization: "sandbox_mfhchgdh_466xqmx9ryy7rcbx"
    }).then((clientInstance: any) => {
      return braintree.hostedFields.create({
        client: clientInstance,
        styles: {
          'input': {
            'font-size': '16px',
            'color': '#3a3a3a'
          },
          ':focus': {
            'color': 'black'
          },
          '.valid': {
            'color': 'green'
          },
          '.invalid': {
            'color': 'red'
          }
        },
        fields: {
          number: {
            selector: '#card-number',
            placeholder: '4111 1111 1111 1111'
          },
          cvv: {
            selector: '#cvv',
            placeholder: '123'
          },
          expirationDate: {
            selector: '#expiration-date',
            placeholder: 'MM/YY'
          }
        }
      });
    }).then((hostedFieldsInstance: any) => {
      const form = document.querySelector('#payment-form');
      form.addEventListener('submit', (event) => {
        event.preventDefault();

        hostedFieldsInstance.tokenize((err: any, payload: any) => {
          if (err) {
            console.error(err);
            return;
          }

          // Send payload.nonce to your server
          console.log('Got nonce:', payload.nonce);
        });
      });
    }).catch((err: any) => {
      console.error(err);
    });
  }

  loadgpay1(){

    let button: any = document.querySelector('#google-pay-button');
    var paymentsClient = new google.payments.api.PaymentsClient({
      environment: 'TEST' // Or 'PRODUCTION'
    });



    braintree.client.create({
      authorization: "sandbox_mfhchgdh_466xqmx9ryy7rcbx"
    }).then( (clientInstance: any) => {
      return braintree.googlePayment.create({
                    client: clientInstance,
                    googlePayVersion: 2,
                    googleMerchantId: '466xqmx9ryy7rcbx' // Optional in sandbox; if set in sandbox, this value must be a valid production Google Merchant ID
      });
    }).then(async function (googlePaymentInstance: any) {
          return paymentsClient.isReadyToPay({
            // see https://developers.google.com/pay/api/web/reference/object#IsReadyToPayRequest
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
            existingPaymentMethodRequired: true // Optional
          }).then((response: any) => {
            if (response.result) {
              button.addEventListener('click',  (event: any) => {
                event.preventDefault();
        
                var paymentDataRequest = googlePaymentInstance.createPaymentDataRequest({
                  transactionInfo: {
                    currencyCode: 'USD',
                    totalPriceStatus: 'FINAL',
                    totalPrice: '100.00' // Your amount,
                    
                  }
                });
        
                // We recommend collecting billing address information, at minimum
                // billing postal code, and passing that billing postal code with all
                // Google Pay card transactions as a best practice.
                // See all available options at https://developers.google.com/pay/api/web/reference/object
                var cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
                cardPaymentMethod.parameters.billingAddressRequired = true;
                cardPaymentMethod.parameters.billingAddressParameters = {
                  format: 'FULL',
                  phoneNumberRequired: true
                };
        
                paymentsClient.loadPaymentData(paymentDataRequest).then(function(paymentData) {
                  return googlePaymentInstance.parseResponse(paymentData);
                }).then( (result: any) => {
                  // Send result.nonce to your server
                  // result.type may be either "AndroidPayCard" or "PayPalAccount", and
                  // paymentData will contain the billingAddress for card payments
                }).catch((err: any) => {
                  // Handle errors
                });
              });
            }
          });
    });
           
  }

}
