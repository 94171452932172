import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-change-number-email',
  templateUrl: './change-number-email.component.html',
  styleUrls: ['./change-number-email.component.scss']
})
export class ChangeNumberEmailComponent implements OnInit {
  @Output() changeNumberEvent: any = new EventEmitter<string>();
  errorMessage = null;
  constructor(private fb:FormBuilder) { }

  mobileOrEmailRequired(group: FormGroup) {
    const oldMobile = group.get('oldMobileNumber')?.value;
    const newMobile = group.get('newMobileNumber')?.value;
    const oldEmail = group.get('oldEmailAddress')?.value;
    const newEmail = group.get('newEmailAddress')?.value;

    const mobileValid = oldMobile && newMobile;
    const emailValid = oldEmail && newEmail;

    return mobileValid || emailValid ? null : { mobileOrEmailRequired: true };
  }
  
  changeNumberForm = this.fb.group({
    oldMobileNmber: [''],
    oldEmailAddress:[''],
    newMobileNumber:[''],
    newEmailAddress:[''],
    pinNumber:['']
  },  { validators: this.mobileOrEmailRequired });

  ngOnInit(): void {
  }
  
  onCancel(){
    for(let control in this.changeNumberForm.controls){
       this.changeNumberForm.controls[control].setValue('');
    }
    this.changeNumberEvent.emit(true);
  }
  onConfirm(){
    if (this.changeNumberForm.valid) {
      this.changeNumberEvent.emit(true);
    } else {
      this.errorMessage = "At least one mobile number or email address must be provided."
    }
  }
}
