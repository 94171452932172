import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  fromOrderSummary: any = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
    this.fromOrderSummary = window.history.state['fromOrderSummary'];
  }

  onBack(){
    this.router.navigate(['get-started'], { state: { OrderSummaryScreen: true }});
  }
 
}
