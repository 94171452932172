<div class="stain-damage">
  <h1 class="header-title">{{title}}</h1>
  <div class="header-line"></div>

  <div class="row">
    <form [formGroup]="imageUpload" class="col-sm-12">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field dense appearance="legacy" class="field-6">
            <mat-label>dresses</mat-label>
            <mat-select formControlName="selectedDress"  (selectionChange)="onGarmentChange()" class="care-dropdown" name="" disableOptionCentering>
              <mat-option *ngFor="let added of addedGarment" [value]="added.garment.garmentTagId">
                {{added.garment.garmentTagId}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field dense appearance="legacy" class="field-6">
            <mat-label *ngIf="!damageInfo">stain type</mat-label>
            <mat-label *ngIf="damageInfo">damage type</mat-label>
            <mat-select formControlName="selectedStain" class="care-dropdown" name="" disableOptionCentering>
              <ng-container *ngIf="!damageInfo">
                <mat-option *ngFor="let type of stainTypes" [value]="type">
                  {{type}}
                </mat-option>
              </ng-container>
              <ng-container *ngIf="damageInfo">
                <mat-option *ngFor="let type of damageTypes" [value]="type">
                  {{type}}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="row image-drop drag-drop-container" id="imageDrop" (click)='imageInput.click()' (drop)="drop($event)"
        (dragover)="allowDrop($event)" #imageDrop>
        <ng-container *ngIf="files.length <=0">
          <span class="upload material-icons">
            file_upload
          </span>
          <p>drag and drop images here</p>
        </ng-container>
        <div class="image-list">
          <ng-container *ngFor="let url of files;let i=index">
            <mat-card class="example-card">
              <mat-icon class="material-icon" aria-hidden="false" aria-label="Example home icon"
                (click)="onRemove(i, $event)">close</mat-icon>
              <img mat-card-image [src]="url.imageURL" class="rounded" width="100">
            </mat-card>
          </ng-container>
        </div>
        <input type="file" multiple="true" accept="image/x-png,image/gif,image/jpeg, image/jpg" formControlName="imageInput"  #imageInput id="imageInput"
          (change)='imageChange($event)'>
      </div>
      <div class="row">
         <div class="col-sm-12 float-right pull-right">
           <span class="stain-reset" (click)="onReset()">cancel</span>
         </div>
      </div>

      <div class="row">
        <div class="col-sm-12 comment-addstain">
          <textarea class="custom-textbox add-comment" formControlName="comment"
            placeholder="add comment"></textarea>
          <button class="add-stain" [disabled]="imageUpload.invalid" (click)="addImage()" mat-button>
            <span *ngIf="!damageInfo"> add stain</span>
            <span *ngIf="damageInfo">add damage</span>
          </button>
        </div>
      </div>

    </form>


    <ng-container *ngIf="rows && rows.length > 0">
      <div class="col-sm-12 mt-3">

        <p-table [value]="rows"  [paginator]="isPagination" [rows]="2" 
        [resizableColumns]="true" [totalRecords]="rows.length" [responsive]="true"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[5,10,15]" 
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
          <ng-template pTemplate="header">
            <tr>
              <th pResizableColumn>dress</th>
              <th pResizableColumn>
                 <span *ngIf="!damageInfo"> stain</span>
                 <span *ngIf="damageInfo"> damage</span>
              </th>
              <th pResizableColumn>comment</th>
              <th pResizableColumn  width="30%"> images </th>
              <th pResizableColumn> action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            
            <tr>
              <td class="ui-resizable-column">{{rowData.selectedDress}}</td>
              <td class="ui-resizable-column">{{rowData.selectedStain}}</td>
              <td class="ui-resizable-column">{{rowData.addComment}}</td>
              <td class="ui-resizable-column" width="30%">
                  <div class="image-list">
                    <ng-container *ngFor="let url of rowData.stains;let i=index">
                      <div class="image">
                        <!-- [disabled]="onEditAction()" -->
                        <button >
                          <mat-icon class="material-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="rowStainRemove(rowIndex,i)">close</mat-icon>
                        </button>
                        <img [src]="url.imageURL" class="rounded" width="50">
                      </div>
                    </ng-container>
                  </div>
              </td>
              <td class="ui-resizable-column">
                <!-- [disabled]="editIndex == 0 || editIndex" -->
                <!-- [disabled]="onEditAction()" -->
                  <!-- <button  pButton type="button"  icon="pi pi-pencil" class="ui-button-warning button-circle" (click)="stainEdit(rowIndex)"  >
                  </button> -->
                  <button  pButton type="button"  icon="pi pi-trash" class="ui-button-danger button-circle" (click)="stainDelete(rowIndex)" >
                  </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-container>

    <div class="col-sm-12 footer-btn">
      <button class="started-btn back-btn" (click)="stainDamageback()" mat-button>back</button>
      <button class="started-btn next-btn" (click)="onSave()" mat-button>save</button>
    </div>
  </div>
</div>