<div class="container faq pt-200">
    <div class="row">
        <div class="col-sm-3">
            <h1 class="headerTitle">faq</h1>
             <hr>
            <ul>
                <li><a  [ngClass]="{'active': selectedIndex == 0}" (click)="changeQuestion('top questions',0)">top questions</a></li>
                <li><a [ngClass]="{'active': selectedIndex == 1}" (click)="changeQuestion('our services',1)">our services</a></li>
                <li><a [ngClass]="{'active': selectedIndex == 2}" (click)="changeQuestion('my account',2)">my account</a> </li>
                <li><a [ngClass]="{'active': selectedIndex == 3}" (click)="changeQuestion('billing and payments',3)">billing and payments</a></li>
                <li><a [ngClass]="{'active': selectedIndex == 4}" (click)="changeQuestion('policies',4)">policies</a></li>
            </ul>
        </div>
        <div class="col-sm-9">
           <div class="accordion">
            <h1>{{headerTitle}}</h1>
            <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Is there a minimum order size for wash and fold?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        Yes, the minimum order size is ten pounds.
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Do I need to download the app in order to use your services?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>No, feel free to stop by our brick and mortar store for all your dry cleaning and laundry needs.</p>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            What is the difference between laundry and dry cleaning?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        The main difference between laundry and dry cleaning is the type of solvent used to clean fabrics: wet cleaning uses water-based solvents, while dry cleaning uses chemical solvents.  Wet cleaning is a good option for fabrics like cotton, linen, or denim.  Dry cleaning is often recommended for delicate items like silk, wool, or cashmere.
                        </p>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Can my garments be folded?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        Yes, please specify if you would like your garments to be returned to you either folded or hung upon check-in.
                        </p>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Do you offer same day service?  
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        In most cases, if you drop off your garments by 8:00 am we can have them back the same day.
                        </p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        </div> 
    </div>
</div>