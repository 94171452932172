<div class="dialog-container">
    <div class="close-icon">
        <button mat-icon-button class="close-button" [mat-dialog-close]="false" >
            <mat-icon class="icon" color="warn">close</mat-icon>
        </button>
    </div>
    <div class="content">
        <ng-container>
            <div mat-dialog-content>
              <p-listbox #dt [options]="addedGarments" [(ngModel)]="selectedGarment"
               [metaKeySelection]="false" [checkbox]="true" [filter]="true" [multiple]="true"
                 optionLabel="barcode" [listStyle]="{'max-height':'250px'}" [filterPlaceHolder]="" >
                <ng-template let-garment pTemplate="item">
                    <div class="country-item">
                        <!-- <img src="assets/showcase/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" /> -->
                        <div>{{garment.garment.garmentType}}</div>
                    </div>
                </ng-template>
              </p-listbox>
            
            </div>
            <div mat-dialog-actions>
                <div class="btn-container button-group">
                    <div class="display-inline">
                        <button type="button" class="btn-fill btn-yes" (click)="onRemoveGarment()"  >delete</button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
