import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaymentService } from 'src/app/core/services/payment.service';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { GetStartedService } from 'src/app/core/services/get-started.service';
import { DialogService } from 'src/app/core/services/dialog.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {
  @Input('invoiceDetails') invoiceDetails: any;
  @Input('nonceDetails') nonceDetails: any;
  @Output() paymentSuccess: any = new EventEmitter<string>();
  activationCode: any;
  summaryDetails: any = {
    basicPackage: 0,
    currencySymbol: '',
    discount: '',
    totalCost: ''
  };
  regAddress: any;
  termsInfo: any;
  termsPolicy = false;
  constructor(
    private PaymentService: PaymentService,
    private userService: UserService,
    private getStartedService: GetStartedService,
    private authService: AuthService,
    private dialogService: DialogService) { }
  guestUser: any;
  @Output() changePaymentEvent: any = new EventEmitter<string>();

  ngOnInit(): void {
    this.guestUser = this.authService.getGuestUser();
    this.getRegAddress();
    if (this.invoiceDetails) {
      this.summaryDetails['basicPackage'] = this.invoiceDetails.totalAmount;
      this.summaryDetails['currencySymbol'] = this.invoiceDetails.currencySymbol;
      this.summaryDetails['totalCost'] = this.invoiceDetails.totalAmount;
    }
  }

  getRegAddress() {
    this.userService.getUserProfile(this.guestUser.userId).subscribe(
      (res) => {
        this.regAddress = res.userDetails;
      },
      (err) => {
      }
    )
  }

  changePayment() {
    this.changePaymentEvent.emit(-1);
  }

  applyActivationCode() {
    let data = {
      "userId": this.guestUser.userId,
      "discountCode": this.activationCode,
      "invoiceId": this.invoiceDetails.invoiceId
    }
    this.PaymentService.getDisCount(data).subscribe(
      (res) => {
        this.summaryDetails['discount'] = res.invoiceItems[1].amount;
        this.summaryDetails['totalCost'] = res.totalAmount;
      },
      (err) => {
      })
  }

  // purchase
  onCreatePurchase() {
    let body = {
      "paymentMethodNonce": this.nonceDetails,
      "invoiceId": this.invoiceDetails.invoiceId,
      "userId": this.guestUser.userId,
      "paymentNote": "sale"
    }
    this.PaymentService.paymentCheckout(body).subscribe(
      (res) => {
        this.paymentSuccess.emit(1);
      },
      (err) => {
      }
    )
  }

  onTermsNprivacy(type) {
    this.getStartedService.termsNprivacy(this.regAddress.address[0].zipCode).subscribe(
      (res: any) => {
        this.termsInfo = res.documentList.filter(item => item.documentType === type);
        this.dialogService.termsConditionDialog(this.termsInfo[0].content)
          .afterClosed()
          .subscribe(res => {

          });
      },
      (err) => {
      }
    )
  }
}
