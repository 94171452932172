<div class="locations container">
    <h1 class="header-title">locations</h1>
    <div class="header-line"></div>
    <form class="row">
      <div class="input-group custom-search mb-3">
        <input type="text" class="form-control" placeholder="search">
        <div class="input-group-prepend">
          <span class="input-group-text"><mat-icon matSuffix>search</mat-icon></span>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-sm-12">
        <ul class="location-list">
          <li>
            <ul>
              <li>(706)-216-5245</li>
              <li>support@premiercleanersga.com</li>
              <li>66, S 400 Center Ln, Suite 185, </li>
              <li>Dawsonville, GA 30534</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
</div>