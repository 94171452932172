import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  selectedItem = 1;
  footerButton = false;
  constructor() { }

  ngOnInit(): void {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
  }
}
