import { APP_INITIALIZER, NgModule } from '@angular/core';
 import {NgxMatIntlTelInputModule} from 'ngx-mat-intl-tel-input';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgOtpInputModule } from 'ng-otp-input';

//material 
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';

import { AppComponent } from './app.component';
import { GetStartedComponent } from './guest/get-started/get-started.component';
import { HeaderComponent } from './guest/header/header.component';
import { FooterComponent } from './guest/footer/footer.component';
import { HomeComponent } from './guest/home/home.component';
import { LoginComponent } from './guest/login/login.component';
import { PricingComponent } from './guest/pricing/pricing.component';
import { PaymentMethodComponent } from './guest/get-started/payment-method/payment-method.component';
import { OrderSummaryComponent } from './guest/get-started/order-summary/order-summary.component';
import { AddressComponent } from './guest/get-started/address/address.component';
import { BasicDetailsComponent } from './guest/get-started/basic-details/basic-details.component';

import { OnboarduserModule } from './on-board-user/onboarduser.module';
import {  OrderByPipe } from './core/pipes/order-by.pipe';
import { MaterialModule } from './shared/material.module';
import { SharedModule } from './shared/shared.module';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { GetStartedService } from './core/services/get-started.service';
import { AuthInterceptor } from './core/Interceptor/Auth.interceptor';
import { LocationsComponent } from './guest/locations/locations.component';
import { TechnicalSupportComponent } from './guest/technical-support/technical-support.component';
import { OtpAuthorizationComponent } from './guest/otp-authorization/otp-authorization.component';
import {LocationStrategy, HashLocationStrategy, DatePipe} from '@angular/common';
import { PrivacyPolicyComponent } from './on-board-user/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './on-board-user/terms-of-use/terms-of-use.component';
import { FaqComponent } from './on-board-user/faq/faq.component';
import { InvestorRelationsComponent } from './on-board-user/investor-relations/investor-relations.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PickupDeliveryAddressComponent } from './guest/pickup-delivery-address/pickup-delivery-address.component';
import { ChangeNumberEmailComponent } from './guest/change-number-email/change-number-email.component';
import { ToastrModule } from 'ngx-toastr';
import {AgmCoreModule} from '@agm/core';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import { GooglePlacesDirective } from './core/directives/google-places.directive';
import { NoServiceableAreaComponent } from './guest/no-serviceable-area/no-serviceable-area.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { UserSetupComponent } from './user-setup/user-setup.component';
import { MdePopoverModule } from '@material-extended/mde';
import { CustomDatePipe } from './core/pipes/custom.datepipe';
import { InlineSVGModule } from 'ng-inline-svg';
import { AppConfigService } from './core/services/app-config.service';
import { NotFoundComponent } from './not-found/not-found.component';

export function initConfig(appConfig: AppConfigService) {
  return () => appConfig.loadConfig();
}

@NgModule({
  declarations: [
    GooglePlacesDirective,
    AppComponent,
    GetStartedComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    PricingComponent,
    PaymentMethodComponent,
    OrderSummaryComponent,
    AddressComponent,
    BasicDetailsComponent,
    OrderByPipe,
    LocationsComponent,
    TechnicalSupportComponent,
    OtpAuthorizationComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    FaqComponent,
    InvestorRelationsComponent,
    PickupDeliveryAddressComponent,
    ChangeNumberEmailComponent,
    NoServiceableAreaComponent,
    UserSetupComponent,
    CustomDatePipe,
    NotFoundComponent,
  ],
  imports: [
    AppRoutingModule,    
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxMatIntlTelInputModule,
    NgOtpInputModule,
    MaterialModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    ToastrModule.forRoot(
      {
        timeOut: 10000,
        positionClass: 'toast-top-right', // Set the position as needed
        closeButton: true, // Show close button
        preventDuplicates: true,
        // Add more options as needed
      }
    ), 
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBaIg2lylJk8pyke_QrNBGqbOU4MTDP0oM',
      libraries: ['places'],
    }),
    MatGoogleMapsAutocompleteModule,
    NgxSpinnerModule,
    OnboarduserModule,
    SharedModule,
    MdePopoverModule,
    InlineSVGModule.forRoot()
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initConfig, deps: [AppConfigService], multi: true },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
   { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    MaterialModule,
    DatePipe
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
