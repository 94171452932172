<div class="container investor-relation pt-200">
    <div class="row">
        <div class="col-sm-12">
             <h1 class="headerTitle">investor relations</h1>
             <div class="headerLine"></div>
        </div>
        <div class="col-sm-12">
            <p>please contact {{supportEmail}} to get more details on investment and franchise opportunities.</p>
        </div>
    </div>   
 </div>
