import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-investor-relations',
  templateUrl: './investor-relations.component.html',
  styleUrls: ['./investor-relations.component.scss']
})
export class InvestorRelationsComponent implements OnInit {
  supportEmail:any;
  constructor(private dataService: DataService) {
    this.dataService.getVendorConfig().subscribe(config => {
      if(config) {
        this.supportEmail = `invest@${config.vendorHost}`;
      }
    });
   }

  ngOnInit(): void {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
  }

}
