import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-deactivate-account',
  templateUrl: './deactivate-account.component.html',
  styleUrls: ['./deactivate-account.component.scss'],
  providers: [DatePipe]
})
export class DeactivateAccountComponent implements OnInit {
  dateTimeRange = '';
  currentUser: any;
  startEnddate: any;
  startDate: Date | null = null;
  endDate: Date | null = null;

  constructor(
    private dialogService: DialogService,
    private userService: UserService,
    private authService: AuthService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getUser();
  }

   // Prevent past dates for the start date
   startDateFilter = (date: Date | null): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Clear time
    return date ? date >= today : false;
  };

  // Prevent selecting an end date earlier than the start date
  endDateFilter = (date: Date | null): boolean => {
    return date && this.startDate ? date > this.startDate : true;
  };
  
  onDeactivate() {
    let startDate = this.datePipe.transform(this.startDate, "yyyy-MM-dd'T'HH:mm:ss");
    let endDate = this.datePipe.transform(this.endDate, "yyyy-MM-dd'T'HH:mm:ss");
    let body = {
      "startDate": startDate,
      "endDate": endDate,
      "userId": this.currentUser.userId
    }
   this.dialogService.onConfirmationDialog("Are you sure to deactivate this account ?")
   .afterClosed().subscribe(res => {
     if (res) {
      this.userService.deactivateAccount(body).subscribe(
        (res) => {
          let info = {
            title: 'your account is <br> now deactivated',
            msg: `your account will be permanently deleted in 30 days <br>
             if you change your mind, you can reactivate by <a (click)="onLoggin()">logging in</a> <br> before your account is deleted permanently.`,
            page: 'deactivate'
          }
          this.dialogService.successMsg(info)
            .afterClosed().subscribe(res => {
            });
        },
        (err) => {
        }
      )
     }
   })
  }
}
