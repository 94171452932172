
<div class="dialog-container">
  <div class="close-icon">
      <button mat-icon-button class="close-button" [mat-dialog-close]="false">
          <mat-icon class="icon" color="warn">close</mat-icon>
      </button>
  </div>
  <div class="content">
      <div mat-dialog-content>
          <!-- <img class="logo" src="../../../assets/icon/logo.png" width="100px"> -->
          <!-- <span class="material-icons">
            done
            </span> -->
          <p class="content-span barcode-text">{{data.message}}</p>
          <h1 [innerHtml]="data.title"></h1>
          <p class="order-no" [innerHtml]="data.msg"></p>
      </div>
      <!-- <div mat-dialog-actions>
          <div class="btn-container button-group">
              <div class="display-inline">
                  <button type="button" class="btn-fill btn-no" [mat-dialog-close]="false">no</button>
              </div>
              <div class="display-inline">
                  <button type="button" class="btn-fill btn-yes" [mat-dialog-close]="true">yes</button>
              </div>
          </div>
      </div> -->
  </div>
</div>

 