import { Injectable } from '@angular/core';
import { config } from 'config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../Auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  baseURL: string;
  constructor(private http: HttpClient,  private authService: AuthService) {
    this.baseURL = environment.apiUrl;
  }

  // get discount
  getDisCount(data): Observable<any> {
    return this.http
      .post(this.baseURL + '/invoice/applyDiscount', data)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  //get token
  getClientToken(data): Observable<string> {
    return this.http.post<any>(this.baseURL + '/payment/initiate', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //purchase || order payment
  public paymentCheckout(body): Observable<any> {
    return this.http
      .post(this.baseURL + '/payment/checkout', body)
      .pipe(map((response: any) => {
        return response;
      }));
  }

   // get Payment History
   public getPaymentHistory() {
    let user = this.authService.getUser();
    let params = new HttpParams()
    .set('email', user.email ?? '')
    .set('vendorId', user.vendorId)
    .set('phoneNumber', user.username ?? '');
    return this.http.get(this.baseURL + '/payment/payments', {params: params});
  }
  
  //get invoice and token - alacarte user
  spotInitiate(data:any) {
    return this.http.post<any>(this.baseURL + '/payment/spotInitiate', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
