<div class="has-barcode-dialog-container">
    <div class="close-icon">
        <button mat-icon-button class="close-button" (click)="iconClose()">
            <mat-icon class="icon" color="warn">close</mat-icon>
        </button>
    </div>
    <div class="content">
        <ng-container *ngIf="!openBarcode">
            <div mat-dialog-content>
                <p class="content-span barcode-text">does the garment has a dryo barcode ?</p>
            </div>
            <div mat-dialog-actions>
                <div class="btn-container modal-button-group">
                    <div>
                        <button type="button" class="btn-fill btn-no" (click)="onNoClick()">no</button>
                        <button type="button" class="btn-fill btn-yes" (click)="onYesClick()">yes</button>
                    </div>
                    <div class="skip-actions">
                      <span (click)="onSkip()">skip</span>
                      <span (click)="onSkipAll()">skip all</span>
                    </div>  
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="openBarcode">
            <form [formGroup]="barcodeForm">
                <mat-form-field class="barcode-class" appearance="legacy">
                    <input formControlName="barCode" matInput placeholder="enter barcode">
                </mat-form-field>
                <div class="action-buttons">
                    <button type="button" class="btn-fill btn-yes" (click)="onSubmit()">submit</button>
                </div>

            </form>
        </ng-container>
    </div>
</div>