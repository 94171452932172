import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {
  email:any;
  constructor() { }


  ngOnInit(): void {
    let userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
    this.email = userProfile?.emailId;
  }

}
