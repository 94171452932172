import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { UserService } from 'src/app/core/services/user.service';
@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss']
})
export class AddressListComponent implements OnInit {
  selectedAddress = 0;
  @Input('addressType') addressType: any;
  @Output() addressListEmit: any = new EventEmitter<string>();
  headingTitle = "";
  addressList = true;
  addAddress = false;
  addressForm: FormGroup;
  ListOfAddress: any;
  currentUser: any;
  updateAddressInfo: any;
  pickupDefault: any = "false"
  deliveryDefault: any = "false";
  workFlow: string;
  editOrderData: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toasterService: ToastrService,
    private route: ActivatedRoute,
    private userService: UserService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getUser();
    let addressData = window.history.state;
    this.route.paramMap.subscribe(params => { 
      this.headingTitle = params.get('type');
      //edit order flow
      this.workFlow = params.get('workflow'); 
    });
      //edit order flow
    if(this.workFlow == 'edit') {
      this.editOrderData = addressData["editOrderData"];
    } 
    this.ListOfAddress = addressData["data"];
    if(!this.ListOfAddress) {
      this.getAddresList();
    }
  }

  // get address list
  getAddresList() {
    this.userService.getAddressList(this.currentUser.userId)
      .subscribe(
        (res: any) => {
          this.ListOfAddress = [];
          this.ListOfAddress = JSON.parse(JSON.stringify(res.addressList));
        },
        (error) => {
        });
  }

  // delete address
  onDeleteAddress(event) {
    let params = {
      addressId: event.id,
      userId: this.currentUser.userId
    }
    this.userService.deleteAddress(params)
      .subscribe(
        (res: any) => {
          this.toasterService.success(res.message);
          this.getAddresList();
        },
        (error) => {
    });
  }

  //make default address
  onMakeDefault(address) {
    let params = {
      "addressId": address.id,
      "userId": this.currentUser.userId,
      "key": this.headingTitle
    }
    this.userService.addressDefault(params)
     .subscribe(
      (res: any) => {
        this.toasterService.success(res.message);
        this.getAddresList();
      },
      (error) => {
    });
  }
  

  displayActiveness(address) {
    if(this.workFlow == 'create') {
      if (this.headingTitle == 'pickup' && (address.pickupDefault == 'true')) {
        return 'active' 
      }
      if (this.headingTitle == 'delivery' && (address.deliveryDefault == 'true')) {
        return 'active' 
      }
    } else {
      if (this.headingTitle == 'pickup' && (address.id == this.editOrderData['pickupAddressId'])) {
        return 'active' 
      }
      if (this.headingTitle == 'delivery' && (address.id == this.editOrderData['deliveryAddressId'])) {
        return 'active' 
      }
    }
    
 }

  //change/update address
  updateAddress(address) {
    this.onAddressAdd();
    this.updateAddressInfo = address;
  }

  //add address
  onAddressAdd() {
    this.addressList = false;
    this.addAddress = true;
    this.updateAddressInfo = {};
  }

  //add address back
  onAddressBack() {
    this.addressList = true;
    this.addAddress = false;
    this.getAddresList();
  }

  //address list back
  addressListBack() {
    if(this.workFlow == 'edit') {
      this.router.navigate(['/my-orders','order',this.editOrderData.orderId ],{state:{data: this.ListOfAddress, myOrder: this.editOrderData }})
    } else {
      this.router.navigate(['/create-order'],{state:{data: this.ListOfAddress}})
    } 
  }
}
