<div class="address-started">
  <p class="header-text">
    At Dryo, we aim to redefine convenience and deliver a warm, personalized experience with every laundry service. Please provide your exact address to help us reach you directly.
  </p>
  <div class="content">
    <div *ngIf="addressInfo">
    <form [formGroup]="addressForm">
      <div class="row">
        <div class="col-md-12 ">
          <!-- <mat-form-field dense appearance="legacy" class="field-12 ">
            <mat-label>address line 1</mat-label>
            <input formControlName="addressLine1" matInput placeholder="">
          </mat-form-field> -->
            <mat-form-field dense appearance="legacy" class="field-12" >
              <mat-label>search your address here</mat-label>
              <input matGoogleMapsAutocomplete #addressLine1
              country="us" 
              (onAutocompleteSelected)="onAutocompleteSelected($event)"
              (onLocationSelected)="onLocationSelected($event)"
               matInput>
            </mat-form-field>
        </div>
        <div class="col-md-6 ">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label>Select Store</mat-label>
            <mat-select name="store" formControlName="store" >
              <mat-option *ngFor="let store of storeList" [value]="store.storeId">
               {{store.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div class="col-md-6 ">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label>location type</mat-label>
            <mat-select name="locationType" formControlName="addressType" disableOptionCentering >
              <mat-option *ngFor="let location of locationTypes" [value]="location.id">
               {{location.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- <div class="col-md-6 ">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label>address line 2</mat-label>
            <input formControlName="addressLine2" matInput placeholder="">
          </mat-form-field>
        </div> -->
        <div class="col-md-6 ">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label>select country</mat-label>
            <mat-select name="countryId" formControlName="country" disableOptionCentering (selectionChange)="getState()">
              <mat-option *ngFor="let country of countryList" [value]="country.rowId">
               {{country.name}}
              </mat-option>
            </mat-select>
            <!-- <input formControlName="country" type="text" matInput placeholder=""> -->
          </mat-form-field>
        </div>

        <div class="col-md-6 ">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label>select state</mat-label>
            <!-- <input formControlName="state" type="text" matInput placeholder=""> -->
            <mat-select name="stateId" formControlName="state" disableOptionCentering (selectionChange)="getCity()">
              <mat-option *ngFor="let state of stateList" [value]="state.rowId">
               {{state.name}}
              </mat-option>
            </mat-select>
            <!-- <input formControlName="state" type="text" matInput placeholder=""> -->
          </mat-form-field>
        </div>

        <div class="col-md-6 ">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label>select city</mat-label>
            <!-- <input formControlName="city" type="text" matInput placeholder="">  -->
             <mat-select name="cityId" formControlName="city" disableOptionCentering>
              <mat-option *ngFor="let city of cityList" [value]="city.rowId">
               {{city.name}}
              </mat-option>
            </mat-select>
            <!-- <input formControlName="city" type="text" matInput placeholder=""> -->
          </mat-form-field>
        </div>
        <div class="col-md-6 ">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label>zip code</mat-label>
            <input formControlName="zipCode" type="text" matInput placeholder="">
          </mat-form-field>
        </div>
        <!-- <div class="col-md-6 ">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label>security code / call box number (optional)</mat-label>
            <input formControlName="securityCode" type="text" matInput placeholder="">
          </mat-form-field>
        </div> -->
        <!-- <div class="col-md-12">
          <mat-form-field dense appearance="legacy" class="field-12 ">
            <mat-label>additional instruction to find the address (optional)</mat-label>
            <input formControlName="findAddress" type="text" matInput placeholder="">
          </mat-form-field>
        </div> -->
      </div>
    </form>
    </div>
    <div class="authorization-code text-center" *ngIf="authorization">
      <p class="verification">enter authorization code</p>
      <ng-otp-input (paste)="onOtpPaste($event)" #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
      <div class="showCodeOtp signup-otp p-3">
        <button id="showcode" class="show-code" (click)="showCode()">
          <mat-icon matSuffix class="eye-icon">visibility_off</mat-icon>
          show code
        </button>
        <button class="resend-otp" (click)="resendOtp()">resend otp</button>
      </div>
    </div>
    <div class="footer-btn">
      <button class="started-btn back-btn" *ngIf="addressInfo" mat-button (click)="addressBack()" >back</button>
      <button class="started-btn next-btn" *ngIf="addressInfo" mat-button (click)="onSubmit()">next</button>
      <button class="started-btn back-btn" *ngIf="authorization" mat-button (click)="otpBack()" >back</button>
      <button class="started-btn next-btn" *ngIf="authorization" mat-button (click)="otpSubmit()" >next</button>
    </div>
  </div>
</div>

