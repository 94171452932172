import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { OrderHelperService } from 'src/app/core/services/order-helper.service';
import { OrderService } from 'src/app/core/services/order.service';

const CareMapping = {
  'dryCleaning': 'garmentDryCleanId',
  'wetCleaning': 'garmentWetCleanId',
  'bleach': 'garmentBleachId',
  'iron': 'garmentIronId',
  'startch': 'garmentStarchId'
}
const loadCare = {
  dryCleaning: [
    {
      activeimageUrl: './assets/images/garment/Icon__dry clean.svg',
      imageUrl: './assets/images/garment/Icon__dry clean copy.svg',
      name: 'dry cleaning',
    }
  ],
  wetCleaning: [
    {
      activeimageUrl: './assets/images/garment/Icon__cool-cold.svg',
      imageUrl: './assets/images/garment/Icon__cool-cold copy.svg',
      name: 'cool/cold',
    },
    {
      activeimageUrl: './assets/images/garment/Icon__warm.svg',
      imageUrl: './assets/images/garment/Icon__warm copy.svg',
      name: 'warm',
    },
    {
      activeimageUrl: './assets/images/garment/Icon__hot.svg',
      imageUrl: './assets/images/garment/Icon__hot copy.svg',
      name: 'hot',
    }
  ],
  bleach: [
    {
      activeimageUrl: './assets/images/garment/Icon__any bleach.svg',
      imageUrl: './assets/images/garment/Icon__any bleach copy.svg',
      name: 'any bleach',
    },
    {
      activeimageUrl: './assets/images/garment/Icon__non-chlorine.svg',
      imageUrl: './assets/images/garment/Icon__non-chlorine copy.svg',
      name: 'only non-chlorine',
    },
    {
      activeimageUrl: './assets/images/garment/Icon__do not bleach.svg',
      imageUrl: './assets/images/garment/Icon__do not bleach copy.svg',
      name: 'do not bleach',
    }
  ],
  iron: [
    {
      activeimageUrl: './assets/images/garment/Icon__Iron_low.svg',
      imageUrl: './assets/images/garment/Icon__Iron_low copy.svg',
      name: 'low',
    },
    {
      activeimageUrl: './assets/images/garment/Icon__Iron_medium.svg',
      imageUrl: './assets/images/garment/Icon__Iron_medium copy.svg',
      name: 'medium',
    },
    {
      activeimageUrl: './assets/images/garment/Icon__Iron_high.svg',
      imageUrl: './assets/images/garment/Icon__Iron_high copy.svg',
      name: 'high',
    }
  ],
  startch: [
    {
      activeimageUrl: './assets/images/garment/Icon__Starch.svg',
      imageUrl: './assets/images/garment/Icon__Starch copy.svg',
      name: 'starch',
    }
  ]
};

@Component({
  selector: 'app-garment-care',
  templateUrl: './garment-care.component.html',
  styleUrls: ['./garment-care.component.scss']
})
export class GarmentCareComponent implements OnInit {
  @Input('firstTimeUser') firstTimeUser: any;
  @Output() careEvent: any = new EventEmitter<string>();
  @Output() garmentDetailId: any = new EventEmitter<string>();
  @Input('counter') currentCounter: any;
  @Input('orderId') orderId: any;
  @Input('addedGarment') addedGarment: any; 
  garmentsCare: any = {};
  currentUser: any;
  garmentTagName: string = null;
  currentOrder: any;
  defaultCare: any = {};
  orderTypes = [
    {name: 'Active orders', code: '0'},
    {name: 'Completed orders', code: '1'},
];
  constructor(
    private orderService: OrderService,
    private authService: AuthService,
    private orderHelperService: OrderHelperService,
    private toasterService: ToastrService
  ) { 
  }

  ngOnInit(): void {
    this.currentUser = this.authService.getUser();
    this.currentOrder = this.orderHelperService.getOrder();
    //set dropdown values
    this.garmentTagName = this.addedGarment && this.addedGarment[0].barcode;
    this.selectionGarment();
    this.initialLoad();
  }

  //onInit
  initialLoad() {
    this.defaultCare = this.orderHelperService.getDefaultCare();
    if (!this.defaultCare) {
      this.getGarmentsCare();
    } else {
      this.GarmentCareWithImage(this.defaultCare);
    }

  }

  //load care
  getGarmentsCare() {
    this.garmentsCare = {};
    this.currentUser = this.authService.getUser();
    let userId = this.currentUser && this.currentUser.userId ? this.currentUser.userId : '';
    this.orderService.getGarmentCare(userId).subscribe(
      (res) => {
        this.defaultCare = res.response;
        this.GarmentCareWithImage(res.response);
      },
      (err) => {
      }
    )
  }

  //local image added for active
  GarmentCareWithImage(cares) {
    let careGroups = Object.keys(cares);
    for (let careGroup of careGroups) {
      cares[careGroup].map(care => {
        for (let load of loadCare[careGroup]) {
          if (care.name.toLowerCase().trim() === load.name.toLowerCase().trim()) {
            care['imageUrl'] = load.imageUrl;
            care['activeimageUrl'] = load.activeimageUrl;
          }
        }
      })
    }
    this.garmentsCare = cares;
  }

  //select care option
  selectCare(main, sub, selected) {
    this.garmentsCare[main].map((care, index) => {
      if (sub == index) {
        this.garmentsCare[main][index].selected = !selected;
      } else {
        this.garmentsCare[main][index].selected = false;
      }
    });
  }

  //save care
  onCareSave() {
    //first time user flow
    if (this.firstTimeUser) {
      let selectedCare = {};
      let garmentGroups = Object.keys(this.garmentsCare);
      for (let garment of garmentGroups) {
        let selected = this.garmentsCare[garment].filter(list => list.selected);
        selectedCare[CareMapping[garment]] = selected && selected.length ? selected[0].id : null;
      }
      selectedCare['userId'] = this.currentUser.userId;
      this.orderService.updateGarmentCare(selectedCare).subscribe(
        (res) => {
          this.careEvent.emit(1);
        },
        (err) => {
        }
      )
    } else {
       this.addGarmentCareForBarcode();
       //update care in added garment object 
    }
  }

  //create order flow - save garment care
  addGarmentCareForBarcode() {
    let selectedCare: any = this.orderHelperService.selectedCare(this.garmentsCare);
    let garmentData = {
      orderId: this.orderId,
      amount: 0,
      counter: this.currentCounter,
      deliveryDate: this.currentOrder.deliveryDate,
      deliveryTime: this.currentOrder.deliveryTime,
      garment: {
        comment: this.currentOrder.comment,
        description: "this is a description",
        machineCycle: "permanent presss",
        bleach: selectedCare.bleach.name,
        washTemperature: selectedCare.wetCleaning.name,
        ironTemperature: selectedCare.iron.name,
        dryClean: selectedCare.dryCleaning.name,
        starch: selectedCare.startch.name,
        dryerHeat: "",
        garmentId: "",
        garmentStatusId: "",
        garmentTagId: this.garmentTagName, // garment barcode id
        garmentCategoryId: "",
        instruction: "this is an instruction",
        price: 0,
      }
    };
      garmentData['barcode'] = this.garmentTagName;
      this.garmentDetailId.emit(garmentData);
      this.toasterService.success('care added successfully');

      //added garment update in this page
     let findIndex =  this.addedGarment.findIndex(list => list.barcode == this.garmentTagName);
      this.addedGarment.splice(findIndex, 1, garmentData);
  }

  //select garment based on barcode
  selectionGarment() {
    if(this.addedGarment && this.addedGarment.length) {
      let filteredGarment = this.addedGarment.find(list => list.barcode == this.garmentTagName);
      let defaultCare = this.orderHelperService.getDefaultCare();
      this.existingGarmentCare(filteredGarment.garment, this.garmentTagName, defaultCare);
    }
  }

  //map exiting selected care 
  existingGarmentCare(existingGarment: any, garmentTagName, defaultCare) {
    let garmentGroups = Object.keys(defaultCare);
    for (let garment of garmentGroups) {
      defaultCare[garment].map(list => {
        if (garment == 'dryCleaning') {
          list.selected = list.name == existingGarment.dryClean ? true : false
        } else if (garment == 'bleach') {
          list.selected = list.name == existingGarment.bleach ? true : false;
        } else if (garment == 'iron') {
          list.selected = list.name == existingGarment.ironTemperature ? true : false;
        } else if (garment == 'startch') {
          list.selected = list.name == existingGarment.starch ? true : false;
        } else {
          list.selected = list.name == existingGarment.washTemperature ? true : false;
        }
      });
    }
    this.garmentTagName = garmentTagName;
    this.garmentsCare = defaultCare;
    this.GarmentCareWithImage(this.garmentsCare);
  }

  descOrder = (a, b) => {
    if (a.key < b.key) return b.key;
  }

  keyFormat(val) {
    return val.replace(/([A-Z])/g, ' $1').trim();
  }

  onCareBack() {
    this.careEvent.emit(-1);
  }
}
