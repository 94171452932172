<div class="notification">
    <h1 class="header-title">account notification preferences</h1>
    <div class="header-line"></div>
    <p class="notifie-msg" class="mb-4">you will receive these notifications at <span class="deliveremail"><a>{{email}}</a></span></p>
    <div class="setting-checkbox">
        <mat-checkbox checked="true" class="i-agree text-white">
            <a>you are currently enrolled to receive promotional emails related to exculsive offers <br> and other service related updates.</a>
        </mat-checkbox>
    </div>
    <div class="setting-checkbox mt-3">
        <mat-checkbox checked="true" class="i-agree text-white">
            <a>you are currently not enrolled in paperless billing.<br>your invoice will be sent to
                <span >{{addressLine1}}</span>
            </a>
        </mat-checkbox>
    </div>
    <div class="setting-checkbox mt-3">
        <mat-checkbox checked="true" class="i-agree text-white">
            <a>you are currently not enrolled to receive emails for all communications notices <br>and legally required disclosures related to your account.</a>
        </mat-checkbox>
    </div>
    <button class="confirm-btn next-btn mt-4 mb-5" mat-button> edit account preferences</button>

    <h1 class="header-title">delivery notification preferences</h1>
    <div class="header-line"></div>
    <p class="deliveremail">delivery email:<a>{{email}}</a></p>
    <p class="delivernumber">delivery phone number:<a>{{phoneNumber}}</a></p>
    <p class="deliverMobile">mobile phone number:<a >{{phoneNumber}}</a></p>
     <div class="flex delivery-notification mt-4">
         <div>
           <h5>notification type</h5>
           <p>delivery reminder two days before delivery </p>
           <p>
            alerts on delivery day once the order is on its way</p>
           <p> post delivery confirmation</p>
         </div>
         <div>
            <h5>phone call alerts</h5>
            <div class="setting-checkbox">
                <mat-checkbox checked="true" class="text-white"></mat-checkbox>
            </div>
            <div class="setting-checkbox">
                <mat-checkbox checked="true" class="text-white"></mat-checkbox>
            </div>
            <div class="setting-checkbox">
                <mat-checkbox checked="false" class="text-white"></mat-checkbox>
            </div>
         </div>
         <div>
            <h5>email alerts</h5>
            <div class="setting-checkbox">
                <mat-checkbox checked="true" class="text-white"></mat-checkbox>
            </div>
            <div class="setting-checkbox">
                <mat-checkbox checkxed="false" class="text-white"></mat-checkbox>
            </div>
            <div class="setting-checkbox">
                <mat-checkbox checked="false" class="text-white"></mat-checkbox>
            </div>
         </div>
         <div>
           <h5>text alerts</h5>
           <div class="setting-checkbox">
            <mat-checkbox checked="true" class="text-white"></mat-checkbox>
           </div>
           <div class="setting-checkbox">
               <mat-checkbox checked="true" class="text-white"></mat-checkbox>
           </div> 
           <div class="setting-checkbox">
            <mat-checkbox checked="false" class="text-white"></mat-checkbox>
            </div>
           </div>
         </div>
     <button class="confirm-btn next-btn mt-4 mb-5" mat-button> edit delivery preferences</button>
</div>

