<div class="communication">
    <h1 class="header-title">communication</h1>
    <div class="header-line"></div>
    <p>we'd like to stay in touch, but only in ways that you find useful.</p>
    <p><span class="email">email:</span><span> {{email}}</span></p>
    <h4>would you like to receive emails from dryo</h4>
    <div class="setting-checkbox">
        <mat-checkbox checked="true" class="i-agree text-white"><a>Yes! I don't want to miss a thing</a>
        </mat-checkbox>
    </div>
    <div class="setting-checkbox">
        <mat-checkbox checked="true" class="i-agree text-white"><a>No, Thanks I'm good for now.</a>
        </mat-checkbox>
    </div>
    <h4 class="pt-3">what do you want to see? (select all that apply)</h4>
    
    <div class="setting-checkbox">
        <mat-checkbox checked="true" class="i-agree text-white"><a>offers and promotions</a>
        </mat-checkbox>
    </div>
    <div class="setting-checkbox">
        <mat-checkbox checked="true" class="i-agree text-white"><a>daily pick - don't miss one day deals</a>
        </mat-checkbox>
    </div>
    <div class="setting-checkbox">
        <mat-checkbox checked="true" class="i-agree text-white"><a> new year updates</a>
        </mat-checkbox>
    </div>
    
    <button class="confirm-btn next-btn mt-4 mb-5" mat-button> update communications</button>
    
    <h4>unsubscribe me</h4>
    
    <div class="setting-checkbox mb-3">
        <mat-checkbox checked="true" class="i-agree text-white"><a> please remove me from email communications</a>
        </mat-checkbox>
    </div>
    <button class="confirm-btn unsubscribe mb-50" mat-button> unsubscribe</button>
</div>