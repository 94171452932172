<div class="container privacy-policy pt-200">
   <div class="row">
      <div class="col-sm-12">
         <h1 class="headerTitle">Privacy Policy</h1>
         <p>Thank you for choosing {{vendorName}}. Your privacy is important to us, and we are committed to protecting
            the personal information you provide to us. This Privacy Policy outlines how we collect, use, disclose, and
            protect your information when you use our {{vendorName}} application. By using the App, you agree to the
            terms of this Privacy Policy. If you do not agree with our practices, please do not use the App.</p>

         <h2>Information We Collect:</h2>
         <ul>
            <li><strong>Personal Information:</strong> When you create an account, we may collect your name, email
               address, and profile picture. If you choose to log in via Gmail or Facebook, we may collect information
               from those accounts. We collect information you provide when adding birthdays, events, and purchasing
               gifts, including names, dates, and addresses.</li>
            <li><strong>Payment Information:</strong> If you make purchases on the App, we collect payment information,
               such as credit card details. However, we do not store this information; it is securely processed by our
               payment service provider.</li>
            <li><strong>Uploaded Content:</strong> You may choose to upload images or videos for sending wishes. These
               will be stored securely and shared only as directed by you.</li>
            <li><strong>Location Information:</strong> We collect the delivery address for gifts and events based on the
               location you provide.</li>
            <li><strong>Voice Assistant Data:</strong> Voice commands used for adding events through the voice assistant
               are processed and stored temporarily to improve the functionality of the voice feature.</li>
         </ul>

         <h2>How We Use Your Information:</h2>
         <ul>
            <li><strong>To Provide Services:</strong> We use your information to provide the services offered by the
               App, including sending reminders, delivering gifts, and facilitating communication.</li>
            <li><strong>Personalization:</strong> We may use your data to personalize your experience within the App,
               including recommendations for gifts and event suggestions.</li>
            <li><strong>Communication:</strong> We may use your contact information to send you notifications, updates,
               and marketing materials related to the App. You can opt out of marketing communications at any time.</li>
         </ul>

         <h2>Account Information</h2>
         <p>If you would at any time like to review or change the information in your account or terminate your account,
            you can:</p>
         <ul>
            <li>Log in to your account &rarr; more &rarr; profile and update your user account.</li>
            <li>Log in to your account &rarr; more &rarr; deactivate account to deactivate your account.</li>
            <li>Log in to your account &rarr; more &rarr; delete account to terminate your account.</li>
            <li>Contact us using the contact information provided.</li>
         </ul>
         <p>Upon your request to deactivate/terminate your account, we will deactivate or delete your account and
            information from our active databases. However, we may retain some information in our files to prevent fraud
            (like detail of your order), troubleshoot problems, assist with any investigations, enforce our legal terms,
            and/or comply with applicable legal requirements.</p>

         <h2>Information Sharing:</h2>
         <ul>
            <li><strong>Third-Party Service Providers:</strong> We may share your information with third-party service
               providers who assist us in delivering our services, processing payments, and improving the App.</li>
            <li><strong>Legal Compliance:</strong> We may disclose your information to comply with legal obligations or
               respond to requests from law enforcement agencies.</li>
         </ul>

         <h2>Security:</h2>
         <p>We implement industry-standard security measures to protect your information. However, no method of
            transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use
            commercially acceptable means to protect your personal information, we cannot guarantee its absolute
            security.</p>

         <h2>Handling Google User Data:</h2>
         <ul>
            <li><strong>Sensitive Scopes Information:</strong> {{vendorName}} accesses your Google calendar events
               provided by Google OAuth APIs, which will help {{vendorName}} to save your birthday events and send
               reminders. We assure that the retrieved Google user data are secured as per the terms and privacy policy
               conditions of Limited Use Requirements and comply with the Google API Services User Data Policy. 
               {{vendorName}}  assures that the shared Google user data is not shared elsewhere and we do not store that data
               in our database. {{vendorName}} does not perform any edits or deletions of the sensitive scopes data.
            </li>
         </ul>

         <h2>Refund Policy:</h2>
         <p>Refund in cases of cancellations are mentioned in the Cancellation Policy; kindly refer to the same. The
            acceptance of an order is subject to availability of products, delivery areas, and time. The order may or
            may not be accepted. The order might be canceled after assessing the circumstances and communicated to the
            customer. In such a case, the amount paid by the customer is fully refunded to the bank account.</p>
         <ul>
            <li>Only one attempt for delivery will be made. Please confirm the availability of the recipient before
               choosing delivery time. If the recipient is not available at the time of delivery, a second attempt will
               not be made and the order will be canceled. No refund will be made in such a case.</li>
            <li>The refunds are only initiated once the customer confirms over mail where the refund has to be done—bank
               account. Refunds are processed to your payment method within 15 working days.</li>
         </ul>

         <h2>Address Change Requests:</h2>
         <p>Change in the address is permitted free of cost only before 24 hours of delivery. Within 24 hours before
            delivery, if the address is changed, we charge Rs 200/-. Sometimes the address change request cannot be
            accepted; in that case, the order will not be refunded or canceled.</p>

         <h2>Return Policy:</h2>
         <p>We have a 30-day return policy, which means you have 30 days after receiving your item to request a return.
            To be eligible for a return, your item must be in the same condition that you received it, unworn or unused,
            with tags, and in its original packaging. You’ll also need the receipt or proof of purchase. If your return
            is accepted, we’ll send you a return shipping label, as well as instructions on how and where to send your
            package. Items sent back to us without first requesting a return will not be accepted.</p>
         <p>You can always contact us for any return question at <a
            [href]="'mailto:' + supportEmail">{{supportEmail}}</a></p>

         <h2>Damages and Issues:</h2>
         <p>Please inspect your order upon reception and contact us immediately if the item is defective, damaged, or if
            you receive the wrong item, so that we can evaluate the issue and make it right.</p>

         <h2>Exceptions / Non-returnable Items:</h2>
         <p>Certain types of items cannot be returned, like perishable goods (such as food, flowers, or plants), custom
            products (such as special orders or personalized items), and personal care goods (such as beauty products).
            We also do not accept returns for hazardous materials, flammable liquids, or gases.</p>

         <h2>Exchanges:</h2>
         <p>The fastest way to ensure you get what you want is to return the item you have, and once the return is
            accepted, make a separate purchase for the new item.</p>

         <h2>Refunds:</h2>
         <p>We will notify you once we’ve received and inspected your return, and let you know if the refund was
            approved or not. If approved, you’ll be automatically refunded on your original payment method within 10
            business days. Please remember it can take some time for your bank or credit card company to process and
            post the refund too. If more than 15 business days have passed since we approved your return, please contact
            us at <a [href]="'mailto:' + supportEmail">{{supportEmail}}</a>.</p>

         <h2>Third-Party Integrations:</h2>
         <p>If you connect Alexa through Wi-Fi and Bluetooth, please review Alexa's privacy policy for information on
            how your data is handled by their services.</p>

         <h2>Changes to the Privacy Policy:</h2>
         <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on this page.</p>

         <h2>Contact Us:</h2>
         <p>If you have any questions or concerns about our Privacy Policy, please contact us at <a
            [href]="'mailto:' + supportEmail">{{supportEmail}}</a>. By using the Premier
            Cleaners application, you acknowledge that you have read and understood this Privacy Policy and agree to its
            terms.</p>
      </div>
   </div>
</div>