import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnboarduserRoutingModule } from './onboarduser-routing.module';
import { MaterialModule } from '../shared/material.module';
import { BillingMethodComponent } from './billing-method/billing-method.component';
import { CommunicationComponent } from './communication/communication.component';
import { CreateOrderComponent } from './create-order/create-order.component';
import { DeactivateAccountComponent } from './deactivate-account/deactivate-account.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { MembershipComponent } from './membership/membership.component';
import { NotificationComponent } from './notification/notification.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { MenuListItemComponent } from './menu-list/menu-list-item.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { SharedModule } from '../shared/shared.module';
import { OnBoardUserComponent } from './on-board-user.component';
import { GarmentSelectionComponent } from './create-order/garment-selection/garment-selection.component';
import { AddressListComponent } from './create-order/address-list/address-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StainDamageComponent } from './create-order/stain-damage/stain-damage.component';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PrimengModule } from '../shared/primeng.module';
import { NotifyDialogComponent } from './dialog/notify/notify-dialog.component';
import { CreateOrderDialogComponent } from './dialog/create-order/create-order-dialog.component';
import { ConfirmDialogComponent } from './dialog/confirm/confirm-dialog.component';
import { TermsPrivacyDialogComponent } from './dialog/terms-privacy/terms-privacy-dialog.component';
import { HasBarcodeComponent } from './dialog/has-barcode/has-barcode.component';
import { AddGarmentComponent } from './create-order/add-garment/add-garment.component';
import { RemoveGarmentComponent } from './dialog/remove-garment/remove-garment.component';
import {ListboxModule} from 'primeng/listbox';
import { InlineSVGModule } from 'ng-inline-svg';
import { AlacarteUserComponent } from './alacarte-user/alacarte-user.component';
import {MatMenuModule} from '@angular/material/menu';
import { SubscriptionComponent } from './subscription/subscription.component';
import { PaymentIntegrationComponent } from './payment-intergration/payment-intergration.component';
import { ConfirmOrderComponent } from './create-order/confirm-order/confirm-order.component';
import { BraintreePaymentComponent } from './braintree-payment/braintree-payment.component';
@NgModule({
  declarations: [
    BillingMethodComponent,
    CommunicationComponent,
    CreateOrderComponent,
    DeactivateAccountComponent,
    DeleteAccountComponent,
    MembershipComponent,
    MenuListItemComponent,
    NotificationComponent,
    OrderHistoryComponent,
    PaymentHistoryComponent,
    OnBoardUserComponent,
    GarmentSelectionComponent,
    AddressListComponent,
    StainDamageComponent,
    NotifyDialogComponent,
    CreateOrderDialogComponent,
    ConfirmDialogComponent,
    TermsPrivacyDialogComponent,
    HasBarcodeComponent,
    AddGarmentComponent,
    RemoveGarmentComponent,
    AlacarteUserComponent,
    SubscriptionComponent,
    PaymentIntegrationComponent,
    ConfirmOrderComponent,
    BraintreePaymentComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    OnboarduserRoutingModule,
    NgOtpInputModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    PrimengModule,
    ListboxModule,
    InlineSVGModule.forRoot(),
    MatMenuModule
  ],
  providers:[
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  entryComponents:[NotifyDialogComponent]
})
export class OnboarduserModule { }
