<div class="row garment-care-details" [ngClass]="{'pt-200 pl-100 pr-100 box-center': firstTimeUser}">
  <div class="profile-form pt-2">
    <ng-container *ngIf="!firstTimeUser">
      <div class="col-sm-12 care-dropdown">
        <!-- <select class="" [(ngModel)]="garmentTagName" (change)="selectionGarment(garmentTagName)">
          <option [value]="addedGarmen.barcode" *ngFor="let addedGarmen of addedGarment">{{addedGarmen.barcode}}
          </option>
        </select> -->
        <p-dropdown  styleClass="custom-p-dropdown" placeholder="Select garment" (onChange)="selectionGarment()" [options]="addedGarment"
         [(ngModel)]="garmentTagName" optionLabel="barcode" optionValue="barcode"></p-dropdown>
      </div>
    </ng-container>


    <div class="col-sm-12 pt-4">
      <ul class="garment-care">
        <li *ngFor="let care of garmentsCare | keyvalue:descOrder">
          <a class="heading">{{keyFormat(care.key)}}</a>
          <ul>
            <li *ngFor="let option of care.value; let j=index" (click)="selectCare(care.key,j,option.selected);">
              <!-- <img *ngIf="!option.selected" width="40px" src="{{option.imageUrl}}"> -->
              <!-- <img *ngIf="option.selected" width="40px" src="{{option.activeimageUrl}}"> -->
              <div [ngClass]="{'active': option.selected}" [inlineSVG]="option.imageUrl"></div>
              <!-- <div [inlineSVG] ="option.activeimageUrl"></div> -->
              <span>{{option.name}}</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <ng-container *ngIf="!firstTimeUser">
      <div class="col-sm-12 custom-checkbox care-checkbox">
        <mat-checkbox class="care-checkbox"><a>apply care for all the dresses</a>
        </mat-checkbox>
      </div>
    </ng-container>

    <div class="col-sm-12">
      <div class="footer-btn">
        <button class="back-btn" (click)="onCareBack()" mat-button>back</button>
        <button class="next-btn" (click)="onCareSave()" mat-button>save</button>
      </div>
    </div>
  </div>
</div>