import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { PaymentService } from 'src/app/core/services/payment.service';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss'],
  // encapsulation: ViewEncapsulation.Emulated
})
export class GetStartedComponent implements OnInit, AfterViewInit {
  isLinear = false;
  @ViewChild('stepper') stepper;
  @ViewChild('RegDialog', { static: true }) RegDialog: TemplateRef<any>;
  @ViewChild('alacarteSwitch') alacarteSwitch: any
  @ViewChild('indCorpSwitch') indCorpSwitch: any
  otp: any;
  showOtpComponent: boolean = true;
  individualAccount: any = true;
  corporateAccount: any = true;
  alacarteAccount = false;
  individual: any = '1';
  countries: any = [
    { name: 'usd ($)' },
    { name: 'uk ($)' },
  ];
  selectedItem = 1;
  accountType = 'corporate account';
  Userinfo: boolean;
  authorization = false;
  basicFormDetails: any;
  individualText: string = 'alacarte';
  footerButton = true;
  nonceDetails: any;
  invoiceDetails: any;
  currentUser: any;
  rxToken: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private PaymentService: PaymentService,
    private authService: AuthService
  ) { }
  ngOnInit() {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
    this.route.paramMap
      .pipe(map(() => window.history.state))
      .subscribe((val) => {
        if (val.topBorderLine) {
          var header = document.querySelector('header');
          header.classList.remove("header-bt");
        }
      });
  }
  ngAfterViewInit() {
    let payment = window.history.state['paymentScreen'];
    let OrderSummary = window.history.state['OrderSummaryScreen'];
    this.stepper.selectedIndex = payment ? 3 : OrderSummary ? 4 : 0;
  }
  move(index: number) {
    this.stepper.selectedIndex = index;
  }
  /*basic form */
  basicNext(basicValue) {
    // if (otp && otp.length > 2) {
    this.stepper.selectedIndex = 1;
    this.basicFormDetails = basicValue;
    // }
  }
  addressNext(id) {
    this.stepper.selectedIndex += id;
  }
  /*Package */
  onPackNext(stepper) {
    this.stepper.selectedIndex += stepper;
    this.scrollTop();
  }
  reviewOrder() {
    this.scrollTop();
  }
  scrollTop() {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
  }
  changePayment(stepper) {
    this.stepper.selectedIndex += stepper;
    this.scrollTop();
  }

  //corp back
  onCorpNext(id) {
    this.stepper.selectedIndex += id;
  }
  //alacarte back
  onAlacarteNext(id) {
    this.stepper.selectedIndex += id;
  }

  onPackBack(id) {
    this.stepper.selectedIndex += id;
  }

  invoiceEvent(event) {
    this.invoiceDetails = event;
    sessionStorage.setItem("invoiceDetails",JSON.stringify(this.invoiceDetails));
    this.getClientToken();
  }

  nonceEvent(nonce) {
    this.stepper.selectedIndex += 1;
    this.nonceDetails = nonce;
  }

  getClientToken() {
    this.currentUser = this.authService.getUser();
    if(!this.invoiceDetails) {
      this.invoiceDetails =  JSON.parse(sessionStorage.getItem("invoiceDetails"));
    }
    let data = {
      "userId": this.currentUser.userId,
      "storeId": this.currentUser.storeId,
      "vendorId": this.currentUser.vendorId,
      "settlementType": "AUTHCAPT",
      "paymentCustomerId": "",
      "chrgAmt": this.invoiceDetails ? this.invoiceDetails.totalAmount : 0,
      "invoiceId": this.invoiceDetails.invoiceId,
      "currencyId": "",
    }

    this.PaymentService.getClientToken(data).subscribe(
      (res:any) => {
        this.stepper.selectedIndex += 1;
        this.rxToken = res.txToken;
      },
      (err) => {
      }
    )
  }

  paymentSuccess(event) {
    
    document.body.scrollTop = 0;
    window.scroll({ top: 50, behavior: "smooth" });
  
    // Set a delay to call successMsg after scroll finishes
    setTimeout(() => {
      let info = {
        msg: 'Thank you for registering Dryo Laundry service.<br> Please continue to start using the service.'
      };
      this.dialogService.successMsg(info)
        .afterClosed().subscribe(res => {
          this.router.navigate(['/login']);
        });
    }, 500); // Adjust the delay time (in ms) if necessary
  }
  
}
