<div class="alacarteAccount material-sub-tabs " >
  <div class="individual-account" *ngIf="garmentListAvailable">
      <mat-tab-group >
       <ng-container *ngFor="let alacarteValues of alarcateLists | keyvalue : sortByIndex">
        <ng-container *ngIf="isServiceActive(alacarteValues.key)">
        <mat-tab  [label]="alacarteValues.key">
          <div class="col-md-12 pt-30 col-xs-12">
            <div class="row">
              <ng-container *ngFor="let alacarte of alacarteValues.value; let i = index">
                <ng-container *ngIf="alacarte.prices![0]?.isWebVisible ==1">
                <div class="col-md-4 col-sm-6 col-12">
                    <div class="row alacarte-item">
                      <div class="col-8 garment">
                        {{ alacarte.garmentName }}
                      </div>
                      <div class="col-4 amount" *ngIf="alacarte.prices.length && alacarte.prices[0]">
                        {{ alacarte.prices[0].currencySymbol }}{{ alacarte.prices[0].price | number:'1.2-2' }}
                      </div>
                      <div class="col-4 amount" *ngIf="!alacarte.prices.length || !alacarte.prices">
                        <p>$ 0</p>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          
        </mat-tab>
      </ng-container>
      </ng-container>
      </mat-tab-group>
      
    <div class="footer-btn">
      <button class="started-btn back-btn" *ngIf="!isPricingPage" mat-button (click)="onAlacarteBack()" >Back</button>
      <button class="started-btn next-btn" *ngIf="!isPricingPage" mat-button  (click)="onSaveAlacarte()">Subscribe as Alacarte</button>
    </div>
</div>

<ng-container *ngIf="!garmentListAvailable">
  <div class="flex-loading">
    <p >Loading...</p>
  </div>
</ng-container>

</div>