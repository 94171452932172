<div class="reg-success-dialog">
    <div class="close-icon">
        <button mat-icon-button class="close-button" (click)="onNoClick()">
            <mat-icon class="icon" color="warn">close</mat-icon>
        </button>
    </div>
    <h1 class="title">thank you for your order</h1>
    <p class="order-no">your order number: 12345</p>
     <img class="bar-code" src="./assets/images/bar_code.png"> 
     <div class="information">
        <div class="package">package selected: annual - unlimited</div>
        <div class="start-service"><a class="cursor-pointer" (click)="onNoClick()">click here to start using the service</a></div>
        <div class="support">support information</div>
        <div class="contact"><span>phone number:</span> 123456789 | <span>email Id:</span> sample@gmail.com</div>
    </div> 
    <img class="logo" src="./assets/images/dryo-logo.png" width="100px">
</div>