import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { config } from 'config';
import { Observable, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { AuthService } from '../Auth/auth.service';
import { AppConfigService } from './app-config.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class GetStartedService {
  baseURL: string;
  subscriptions: Observable<[]>;
  addressTypes: Observable<[]>;
  countryList: Observable<[]>;
  vendorId: any;
  countryId: any;
  storeId:any;
  constructor(
    private http: HttpClient, 
    private Authservice: AuthService,
    private dataService: DataService,
  ) {
    this.baseURL = environment.apiUrl;
    this.countryId = config.countryId;
    this.dataService.getVendorConfig().subscribe(config => {
      if(config) {
        this.vendorId = config.vendorId;
        this.storeId = config.storeId;
      }
    });
  }

  public isServiceableArea(currentLocation) {
    let params = new HttpParams()
      .set('countryId', this.countryId )
      .set('zipCode', currentLocation.zipCode)
      .set('storeId', this.storeId)
      .set('vendorId', this.vendorId);

    return this.http.get(this.baseURL + '/laundry/isServiceableArea', { params: params });
  }

  public serviceableAreaNotify(body) {
    body['vendorId'] = this.vendorId;
    return this.http.post(this.baseURL + '/customer/areaServiceRequest', body);
  }

  public getCountryIp() {
    return this.http.get(this.baseURL + '/', {});
  }

  // get country
  public getCountry(): Observable<[]> {
    if (!this.countryList) {
      this.countryList = this.http.get<any>(this.baseURL + '/laundry/country').pipe(
        map(data => data),
        publishReplay(1), // this tells Rx to cache the latest emitted
        refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
      );
    }
    return this.countryList;
  }

  // get state
  public getState(countryId): Observable<any> {
    let params = new HttpParams()
      .set('countryId', countryId)
    return this.http.get(this.baseURL + '/laundry/state', { params: params });
  }

  //get city
  getCity(stateId): Observable<any> {
    let params = new HttpParams()
      .set('stateId', stateId)
    return this.http.get(this.baseURL + '/laundry/city', { params: params });
  }

  public getAddressTypes(): Observable<[]> {
    if (!this.addressTypes) {
      this.addressTypes = this.http.get<any>(this.baseURL + '/user/addressTypes').pipe(
        map(data => data),
        publishReplay(1), // this tells Rx to cache the latest emitted
        refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
      );
    }
    return this.addressTypes;
  }

  public userExist(data) {
    let params = new HttpParams()
      .set('emailId', data.email)
      .set('countryCode',data.dialCode)
      .set('phoneNumber', data.number)
    return this.http.get(this.baseURL + '/customer/userExist', { params: params });
  }

  public registration(body) {
    body["vendorId"] = this.vendorId;
    body['user'].countryId = this.countryId;
    return this.http.post(this.baseURL + '/customer/registration', body);
  }

  public otpVerification(body) {
    body["vendorId"] = this.vendorId;
    return this.http.post<any>(this.baseURL + '/customer/login', body)
    .pipe(map(user => {
      this.Authservice.setUser(user);
      return user;
    }));;
  }

  public getSubscriptions(storeId): Observable<[]> {
    const requestParams = {
      storeId: this.storeId,
      vendorId: this.vendorId
    };
    // Cache it once if configs value is false
    // if (this.subscriptions) {
      this.subscriptions = this.http.get<any>(this.baseURL + '/plans/store/subscription', { params: requestParams }).pipe(
        map(data => data),
        publishReplay(1), // this tells Rx to cache the latest emitted
        refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
      );
    // }
    return this.subscriptions;
  }

  public saveSubscription(data) {
    return this.http.post<any>(this.baseURL + '/customer/subscription', data);
  }

  public subscriberInvoice(data) {
    return this.http.post<any>(this.baseURL + '/invoice/subscriberInvoice', data);
  }

  public saveCorporateAccount(data) {
    return this.http.post<any>(this.baseURL + '/customer/corporateUserAccount', data);
  }

  public getAlacarte(data) {
    let params = new HttpParams()
      .set('storeId', this.storeId)
      .set('priceListId', data.priceListId)
      .set('vendorId', this.vendorId);
    return this.http.get(this.baseURL + '/garments/garmentsInfo', { params: params });
  }

  public termsNprivacy(zipCode) {
    let params = new HttpParams()
    .set('countryId', this.countryId)
    .set('zipCode', zipCode)
    .set('vendorId', this.vendorId);
    return this.http.get(this.baseURL + '/laundry/termsNprivacy', { params: params });
  }

  public getClientId(vendorId?) {
    let params = new HttpParams()
    .set('vendorId',  vendorId ? vendorId : this.vendorId);
    return this.http.get(this.baseURL + '/vendor/keycloakClientInfo', { params: params });
  }

  public getAllStore(clientId, vendorId?) {
    let params = new HttpParams()
    .set('clientId', clientId)
    .set('vendorId', vendorId ? vendorId : this.vendorId);
    return this.http.get(this.baseURL + '/vendor/store', { params: params });
  }

  public getCompanyAddress() {
    let params = new HttpParams()
      .set('countryId', "101")
      .set('zipcode', "600040")
      .set('emailId', "karthik@dryocleaners.com");
    return this.http.get(this.baseURL + '/customer/companyBranch', { params: params });
  }

  //alacarte flow - pay at pickup
  public getPayAtPickUp(data) {
    let params = new HttpParams()
      .set('userId', data.userId)
      .set('storeId', this.storeId);
      return this.http.get(this.baseURL + '/customer/payAtPickup', { params: params });
  }

   public checkSubscription() {
    let user = this.Authservice.getUser();
    let params = new HttpParams()
    .set('userId', user?.userId)
    .set('vendorId', this.vendorId)
    .set('storeId', this.storeId);
    return this.http.get(this.baseURL + '/customer/subscription', { params: params });
   }
  
}
