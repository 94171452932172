<div class="individual-account subscription material-sub-tabs">
    <mat-tab-group  [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="tabClick($event)">
      <mat-tab *ngFor="let tabConfig of tabConfigurations" [label]="tabConfig.label">
        <div class="col-md-12 pt-30 col-xs-12">
          <div class="carousel-container">
            <div class="arrow-buttons">
              <button (click)="prev(tabConfig)" [disabled]="currentStartIndex[tabConfig.label] === 0" class="arrow-button"
                *ngIf="tabConfig.data.length > 0">
                <mat-icon>arrow_back</mat-icon>
              </button>
  
              <button class="" (click)="next(tabConfig)"
                 [disabled]="isNextDisabled(tabConfig)" class="arrow-button"
                *ngIf="tabConfig.data.length > 0" >
                <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
  
            <div class="row packs">
              <div class="pack" *ngFor="let subscription of visibleSubscriptions(tabConfig); let i = index;"
                [ngClass]="{'active': selectedItem === currentStartIndex[tabConfig.label] + i}"
                (click)="selectedItem = currentStartIndex[tabConfig.label] + i">
                <div class="dotted-circle">
                  <h3 class="pack-title">{{subscription?.name}}</h3>
                  <p>{{subscription?.currencySymbol}} {{subscription?.price}}/month <br>
                    {{subscription?.totalPieces}} pieces
                  </p>
                </div>
                <ul *ngFor="let service of subscription.services;">
                  <li>
                    <a>
                      <span>{{service.service}}</span>
                      <span>{{service.count}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-btn" *ngIf="tabConfig.data?.length > 0">
          <button class="started-btn next-btn" mat-button (click)="onSaveSubscription()">Subscribe</button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  