import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.scss']
})
export class PreferenceComponent implements OnInit {
  @Input('firstTimeUser') firstTimeUser: any;
  @Output() PreferenceEvent: any = new EventEmitter<string>();

  deliveryTyp = 1;
  Times = ['05:00 AM', '06:00 AM', '07:00 AM', '05:00 PM', '06:00 PM', '07:00 PM', '09:00 PM'];
  preferencesList = [];
  yourModelName = 'hr'
  initialServiceData: any;
  currentUser: any;
  initialPreference: { dayString: string; preferencesId: number; day: string; pickupStartTime: string; pickupEndTime: string; deliveryStartTime: string; deliveryEndTime: string; }[];
  showBackButton: any;
  constructor(
    private userService: UserService,
    private toasterService: ToastrService,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getUser();
    this.loadInitialPreference();
    this.getPrefrences();

     // Check the initial URL
     this.toggleBackButtonVisibility();

     // Listen for route changes
     this.router.events.subscribe(() => {
       this.toggleBackButtonVisibility();
     });
  }

  getPrefrences() {
    this.userService.getPreferences(this.currentUser.userId).subscribe(
      (res) => {
        this.preferencesList = res && res.preferences.preferencesList.length ? res.preferences.preferencesList : this.initialPreference;
      },
      (err) => {
      }
    )
  }

  onSavePreference() {
    let params = {
      "userId": this.currentUser.userId,
      "preferencesList": this.preferencesList
    }
    this.userService.updatePreferences(params).subscribe(
      (res) => {
        this.toasterService.success('user preference updated successfully');
        this.PreferenceEvent.emit(1);
      },
      (err) => {
      }
    )
  }

  onBack() {
    this.PreferenceEvent.emit(-1);
  }

  onOffPickup(event, index) {
    this.preferencesList[index].pickupStartTime = '';
    this.preferencesList[index].pickupEndTime = '';
  }

  onOffDelivery(event, index) {
    this.preferencesList[index].deliveryStartTime = '';
    this.preferencesList[index].deliveryEndTime = '';
  }

  public loadInitialPreference() {
    this.initialPreference = [
      {
        "dayString": 'Monday',
        "preferencesId": 0,
        "day": "mon",
        "pickupStartTime": "05:00 AM",
        "pickupEndTime": "05:00 AM",
        "deliveryStartTime": null,
        "deliveryEndTime": null
      },
      {
        "dayString": 'Tuesday',
        "preferencesId": 0,
        "day": "tue",
        "pickupStartTime": "",
        "pickupEndTime": "",
        "deliveryStartTime": "05:00 PM",
        "deliveryEndTime": "06:00 PM"
      },
      {
        "dayString": 'Wednesday',
        "preferencesId": 0,
        "day": "wed",
        "pickupStartTime": null,
        "pickupEndTime": null,
        "deliveryStartTime": null,
        "deliveryEndTime": null
      },
      {
        "dayString": 'Thursday',
        "preferencesId": 0,
        "day": "thu",
        "pickupStartTime": null,
        "pickupEndTime": null,
        "deliveryStartTime": null,
        "deliveryEndTime": null
      },
      {
        "dayString": 'Friday',
        "preferencesId": 0,
        "day": "fri",
        "pickupStartTime": null,
        "pickupEndTime": null,
        "deliveryStartTime": null,
        "deliveryEndTime": null
      },
      {
        "dayString": 'Saturday',
        "preferencesId": 0,
        "day": "sat",
        "pickupStartTime": null,
        "pickupEndTime": null,
        "deliveryStartTime": null,
        "deliveryEndTime": null
      },
      {
        "dayString": 'Sunday',
        "preferencesId": 0,
        "day": "sun",
        "pickupStartTime": null,
        "pickupEndTime": null,
        "deliveryStartTime": null,
        "deliveryEndTime": null
      }

    ]
  }

  onTogglePickup(index: number): void {
    const preference = this.preferencesList[index];
    preference.pickupEnabled = !preference.pickupEnabled;
    
    if (!preference.pickupEnabled) {
      preference.pickupStartTime = '';
      preference.pickupEndTime = '';
    }
  }

  onToggleDelivery(index: number): void {
    const preference = this.preferencesList[index];
    preference.deliveryEnabled = !preference.deliveryEnabled;
    
    if (!preference.deliveryEnabled) {
      preference.deliveryStartTime = '';
      preference.deliveryEndTime = '';
    }
  }

  
  toggleBackButtonVisibility(): void {
    // Check if the current URL contains 'pricing'
    this.showBackButton = this.router.url.includes('/preference');
  }
}
