import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { OFFLINE_CONFIGS } from 'src/assets/json/theme-offline-constants';
import { DataService } from './data.service';
@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    private vendorProfileConfig: any;


    constructor(private _http: HttpClient, private dataService: DataService,
    ) { }

    // Function to dynamically set CSS variables
    private setThemeColors(primaryColor: string, secondaryColor: string) {
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);

        // Set derived colors
        document.documentElement.style.setProperty('--secondary-color-light', '#fff');
        document.documentElement.style.setProperty('--scrollbar-bg', primaryColor);
        document.documentElement.style.setProperty('--garment-category-bg', primaryColor);
        document.documentElement.style.setProperty('--header-link-color', primaryColor);
        document.documentElement.style.setProperty('--footer-link-color', primaryColor);
        document.documentElement.style.setProperty('--tab-active-bg', primaryColor);
        document.documentElement.style.setProperty('--tab-border-color', primaryColor);
        document.documentElement.style.setProperty('--btn-primary-bg', primaryColor);
        document.documentElement.style.setProperty('--btn-primary-color', secondaryColor);
        document.documentElement.style.setProperty('--circle-background', primaryColor);
    }

    private applyTheme(theme: any) {
        const primaryColor = theme.colors.find((color: any) => color.name === 'primaryColor')?.colorCode;
        const secondaryColor = theme.colors.find((color: any) => color.name === 'secondaryColor')?.colorCode;
        this.setThemeColors(primaryColor, secondaryColor);
    }

    private loadOfflineConfig() {
        const hostname = window.location.hostname;
        if (OFFLINE_CONFIGS[hostname]) {
           this.vendorProfileConfig = OFFLINE_CONFIGS[hostname]?.profile;
           this.applyTheme(OFFLINE_CONFIGS[hostname].theme);
          this.dataService.setVendorConfig(this.vendorProfileConfig);
        } else {
            console.warn('No offline config found for this host:', hostname);
        }
    }

    public loadConfig() {  
        const hostName = window.location.hostname;
    
        return this._http.get(`${environment.apiUrl}/vendor/profile?hostName=${hostName}`).toPromise()
            .then((additionalConfig: any) => {
                this.vendorProfileConfig = additionalConfig.profile;
                const vendorId = additionalConfig.profile.vendorId;
                if(hostName == 'premiercleanersga.com') {
                    this.vendorProfileConfig['storeId'] = "146";
                } else {
                    this.vendorProfileConfig['storeId'] = "173";
                }
                // Emit combined config or update the specific configuration state
                this.dataService.setVendorConfig(this.vendorProfileConfig);

                // Call theme API directly after retrieving vendorId
                return this._http.get(`${environment.apiUrl}/theme/web?vendorId=${vendorId}`).toPromise();
            })
            .then((themeConfig: any) => {
                // Find primary and secondary colors
                const primaryColor = themeConfig.theme[0]?.colors.find((color: any) => color.name === 'primaryColor')?.colorCode;
                const secondaryColor = themeConfig.theme[0]?.colors.find((color: any) => color.name === 'secondaryColor')?.colorCode;
    
                // Set the theme colors
                this.setThemeColors(primaryColor, secondaryColor);
            })
            .catch((err: any) => {
                console.error('Error while getting config', err);
                this.loadOfflineConfig();
            });
    }
    

}