import { KeyValue } from '@angular/common';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { GetStartedService } from 'src/app/core/services/get-started.service';

@Component({
  selector: 'app-alacarte',
  templateUrl: './alacarte.component.html',
  styleUrls: ['./alacarte.component.scss']
})
export class AlacarteComponent implements OnInit {
  @Output() alacarteNextEvent: any = new EventEmitter<string>();
  alarcateLists: any = [];
  guestUser: any;
  showMore: boolean = false;
  maxItems: number = 21;
  isPricingPage: boolean = false;
  serviceVisibility: any = [];
  garmentListAvailable = false;
  constructor(private getStartedService: GetStartedService, 
    private authService: AuthService,  private router: Router,
    private toasterService: ToastrService) { }

  ngOnInit(): void {
    this.getAlacarteList();
    this.isPricingPage = this.router.url.includes('pricing');
    this.setMaxItemsBasedOnScreenHeight();
  }

  // get alacarte
  getAlacarteList() {
    const requestParams = {
      priceListId: '0',
    };
    this.getStartedService.getAlacarte(requestParams)
      .subscribe(
        (data: any) => {
          if (data) {
            this.alarcateLists = data.data;
            this.serviceVisibility = data.serviceVisibility;
            this.garmentListAvailable = true;
          }
        },
        (error) => {
        });
  }

  onAlacarteBack() {
    this.alacarteNextEvent.emit(-1);
  }

  onSaveAlacarte() {
    this.guestUser = this.authService.getGuestUser();
    let requestParams = {
      "userId": this.guestUser.userId,
    }
    this.getStartedService.getPayAtPickUp(requestParams)
      .subscribe(
        (data:any) => {
          this.toasterService.success(data.message);
          this.router.navigate(['/login']);
        },
        (error) => {
    });
  }
  sortByIndex = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
    return parseInt(a.key, 10) - parseInt(b.key, 10);
  };
  toggleShowMore() {
    this.showMore = !this.showMore;
  }
 // Set maxItems based on screen height
 setMaxItemsBasedOnScreenHeight(): void {
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  if (screenWidth <= 600) {
    // Mobile screen: show only 10 items
    this.maxItems = 10;
  }
  else if (screenHeight > 1200) {
    // Big monitor: show more items (e.g., 100)
    this.maxItems = 100;
  } else if (screenHeight > 800) {
    // Medium screen: show moderate items (e.g., 50)
    this.maxItems = 42;
  } else {
    // Small screens: default to 18 items
    this.maxItems = 21;
  }
}

  // Listen to window resize event to adjust maxItems dynamically
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.setMaxItemsBasedOnScreenHeight();
  }

  isServiceActive(serviceName: string): boolean {
    // Find the service with the given name
    const service = this.serviceVisibility.find(service => service.serviceName === serviceName);

    // Check if the service exists and isWebVisible is 1
    return service?.isWebVisible === 1 ? true : false;
}
}


