import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { DataService } from 'src/app/core/services/data.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { GetStartedService } from 'src/app/core/services/get-started.service';
import { OrderHelperService } from 'src/app/core/services/order-helper.service';
import { OrderService } from 'src/app/core/services/order.service';
import { PaymentService } from 'src/app/core/services/payment.service';
import { UserService } from 'src/app/core/services/user.service';
@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent implements OnInit {

  createOrderScreen = true;
  garmentSelectionScreen = false;
  isBrainTreePaymentScreen = false;

  servicesGarmentsList = {};
  panelOpenState = false;
  orderSummary = false;
  deliveryDate = new Date();
  pickupDate = new Date();
  ListOfAddress: any;
  currentUser: any;
  pickupDefaultAddress: any = {};
  deliveryDefaultAddress: any = {};
  defaultAddress: any = [];
  minDate: Date;
  maxDate: Date;
  orderNotes: any;
  deliveryServiceTime: any = ""
  pickupServiceTime: any = ""
  serviceableDateList = [];
  orderId: any;
  editOrderData: any = {};
  isEditOrder = false;
  createOrderComponent: boolean = true;
  currentTab: string;
  addGarment = {};
  finalOrderDetailsData: any = [];
  pickupServiceableDateList: any = [];
  deliveryServiceableDateList: any = [];
  orderFlowName = 'create order';
  currentOrder: any;
  garmentList = {};
  userSubscriptionInfo: any;
  paymentPayload: any;
  isBrainTreePayment: any = false;
  paylater: boolean = false;
  minDeliveryDate = new Date();
  garmentsCount: any = 0;
  serviceVisibility:any = [];
  inStorePickupDate:any;
  inStoreDeliveryServiceTime: string = 'start time - end time';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dialogService: DialogService,
    private orderService: OrderService,
    private orderHelperService: OrderHelperService,
    private datePipe: DatePipe,
    private getStartedService: GetStartedService,
    private paymentService: PaymentService,
    private toasterService: ToastrService,
    private dataService: DataService,
    private userService: UserService) { }

  isGarmentList() {
    return Object.keys(this.servicesGarmentsList);
  }
  getGarmentsForCreateOrder() {
    this.currentUser =  this.authService.getUser();
    this.orderService.getGarmentForCreateOrder(this.currentUser).subscribe(
      (res: any) => {
        Object.keys(res.data).map((key: any) => {
          const serviceName = key.replace(/(?<=[a-z])([A-Z])|\b\w/g, (match, p1) => {
            return p1 ? ` ${p1}` : match.toUpperCase();
          }).replace(/&/g, ' & ').toLowerCase();
    
          this.servicesGarmentsList[serviceName] = res.data[key];
          this.servicesGarmentsList[serviceName].map(list => {
            list['count'] = 0;
            list['skip'] = false;
            list['skipAll'] = false;
          });
        });
    
        this.garmentList = { ...this.servicesGarmentsList };
        this.serviceVisibility = res?.serviceVisibility;
        if (this.editOrderData && Object.keys(this.editOrderData).length > 0) {
          this.setSelectedGarmentByEditflow();
        }
      },
      (err) => { }
    );
  }

  setMenuActive() {
    const currentRoute = this.router.url;

    if (currentRoute.includes('/my-orders/order/') || currentRoute.includes('/create-order')) {
      this.dataService.setActiveMenu('create-order');
    } else if (currentRoute === '/my-orders') {
      this.dataService.setActiveMenu('my-orders');
    }
  }
  ngOnInit(): void {
    this.orderHelperService.setOrder({});
    this.setMenuActive();
    this.getGarmentsForCreateOrder();
    this.checkSubscription();
    this.scrollTop();
    this.currentUser = this.authService.getUser();
    this.route.paramMap.subscribe((data) => {
      this.orderId = this.orderId ? this.orderId : data['params']['orderId'];
      let workFlow = data['params']['workflow'];
      if (workFlow == 'order') {
        //edit order
        this.orderFlowName = 'update order';
        this.isEditOrder = true;
        this.getOrder(this.orderId);
      } else {
        //init create order - cearing added garment which is added in the edit flow
        this.orderHelperService.initAddGarment();
      }
    });

    //data from address list
    this.ListOfAddress = window.history.state['data'];
    if (!this.ListOfAddress) {
      this.getAddresList();
    } else {
      //set pickup and delivery address
      this.filterAddressType();
    }
    let currentYear = new Date();
    this.minDate = new Date(currentYear.getFullYear(), currentYear.getMonth(), currentYear.getDate());
    this.maxDate = new Date(currentYear.getFullYear(), currentYear.getMonth(), currentYear.getDate() + 5);
     this.pickupDateChange( this.minDate);
    this.getGarmentCare();
   
  }

  //get order by id
  public getOrder(orderId) {
    let userId = this.currentUser.userId;
    this.orderService.getOrder(userId, orderId).subscribe(
      (res) => {
        this.editOrderData = {...res.order};
        this.pickupDate = new Date(this.editOrderData.pickupDate);
        this.deliveryDate = new Date(this.editOrderData.deliveryDate);
        this.pickupServiceTime = this.editOrderData.pickupStartTime + '-' + this.editOrderData.pickupEndTime;
        this.deliveryServiceTime = this.editOrderData.deliveryStartTime + '-' + this.editOrderData.deliveryEndTime;
         //edit order collect form data
          let order = this.prepareCreateOrderData();
          order.orderId = this.orderId;
          this.orderHelperService.setOrder(order);
      },
      (err) => {
        console.error('Error fetching order:', err);
      }
    );
  }

  //get address
  getAddresList() {
    this.userService.getAddressList(this.currentUser.userId)
      .subscribe(
        (res: any) => {
          this.ListOfAddress = [...res.addressList];
          this.filterAddressType();
        },
        (error) => {
    });
  }

  //set pickup and delivery address
  filterAddressType() {

    if (this.isEditOrder) {  //edit order
      this.pickupDefaultAddress = this.ListOfAddress.find(pickup => pickup.id == this.editOrderData.pickupAddressId);
      this.deliveryDefaultAddress = this.ListOfAddress.find(pickup => pickup.id == this.editOrderData.deliveryAddressId);
    } else {
      this.pickupDefaultAddress = this.ListOfAddress.find(pickup => pickup.pickupDefault == "true");
      this.deliveryDefaultAddress = this.ListOfAddress.find(pickup => pickup.deliveryDefault == "true");
    }
    this.defaultAddress = this.ListOfAddress.find(pickup => pickup.addressDefault == "true");
    this.getServiceableArea();
  }
  
  getStoreByVendorId():Promise<void> {
    return this.orderService.getStore()
      .pipe(map(
        (data: any) => {
          return data?.stores[0] ?? [];
        },
      )).toPromise()
  }

  // get serviceable area
  async getServiceableArea() {
    let storeDetails:any =  await this.getStoreByVendorId();
    let serviceableDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    let params = {
      // zipCode: this.pickupDefaultAddress ? this.pickupDefaultAddress.zipCode : this.defaultAddress.zipCode,
      serviceableDate: serviceableDate,
      countryId: storeDetails.address?.countryId,
      zipCode: storeDetails.address?.zipCode
    }
    this.orderService.serviceableDays(params).subscribe(
      (res) => {
        let filteredDate = res.serviceableDateList.map(val => val.availableDate);
        let serviceTime = res.serviceableDateList[0].serviceAvailability[0];
        if (!this.isEditOrder) {  //edit order
          let firstDate = new Date(filteredDate[0]);
          let lastDate = new Date(filteredDate[filteredDate.length - 1]);
          this.minDate = new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
          this.maxDate = new Date(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate());
          this.pickupServiceTime = serviceTime.startTime + '-' + serviceTime.endTime;
          this.deliveryServiceTime = serviceTime.startTime + '-' + serviceTime.endTime;
        }
        this.serviceableDateList = JSON.parse(JSON.stringify(res.serviceableDateList));
        this.pickupDateChange({ value: new Date() });
        this.deliveryDateChange({ value: new Date() });
      });
  }


  selectAddress(type) {
    if (this.isEditOrder) {
      this.router.navigate(['/select-address', 'order', type,], { state: { data: this.ListOfAddress, editOrderData: this.editOrderData } });
    } else {
      this.router.navigate(['/select-address', 'create', type], { state: { data: this.ListOfAddress } });
    }
  }

  addGarmentItem() {
    if (this.orderId && !this.isEditOrder) {
      //back button
      this.createOrderComponent = false;

    } else if (this.isEditOrder) {
      //order edit
      this.createOrderComponent = false;
    } else {
      //order create initial
      this.orderCreate();
    }

    this.createOrderScreen = false;
    this.garmentSelectionScreen = true;
    this.isBrainTreePaymentScreen = false;
  }

  //create order without garment
  createOrder(type:string) {
    if(this.garmentsCount > 0){
      let addedGarment = this.orderHelperService.getAddedGarment();
      if (addedGarment && Object.keys(addedGarment).length > 0)  {
        this.orderCreate(true); //
      } else {
        const screenWidth = window.innerWidth;
        if(screenWidth < 500){
          document.body.scrollTop = 0;
          window.scroll({ top: 0, behavior: "smooth" });
        }
        this.quickOrder(type); 
      }
    } else {
      this.toasterService.error('Atleast one garment required')
    }
  }

  getQuickOrderPayload(type) {
    let order:any = this.prepareCreateOrderData();
    let others;
    if(type == 'delivery') {
      others  = {
      "priceListId":0,
      "deliveryType":0,
      "isQuickOrder":true,
      "quickOrderGarmentsCount":this.garmentsCount,
      "appType":"VENTAB"
    }
    order['others'] = others;
    } else {
      //in-store pickup
      let deliveryTime = this.inStoreDeliveryServiceTime?.split('-');
      order.deliveryAddressId = '';
      order.deliveryDate = this.datePipe.transform(this.inStorePickupDate, 'yyyy-MM-dd');
      order.deliveryStartTime = deliveryTime[0]?.trim();
      order.deliveryEndTime = deliveryTime[1]?.trim();
      order.pickupAddressId = '';
      order.pickupDate = '';
      order.pickupStartTime = '';
      order.pickupEndTime = '';
      others  = {
        "priceListId":0,
        "deliveryType":0,
        "isQuickOrder":true,
        "quickOrderGarmentsCount":this.garmentsCount,
        "appType":"VENTAB"
      }
      order['others'] = others;
    }
    return order;
  }

  quickOrder(type:any) {
    let order = this.getQuickOrderPayload(type);
    this.orderService.createOrder(order).subscribe(
      (res) => {
        order.orderId = res.orderId;
        this.toasterService.success("Order created successfully")
        this.orderHelperService.setOrder({});
        sessionStorage.removeItem('myOrder'); // edit order
        this.router.navigate(['my-orders']);
      },
      (err) => {
        console.log('quick order failed');
      }
    )
  }

  //create order without garment
  public orderCreate(createOrderWithoutGarment?) {
    let order = this.prepareCreateOrderData();
    this.orderService.createOrder(order).subscribe(
      (res) => {
        order.orderId = res.orderId;
        this.orderId = res.orderId;
        if (createOrderWithoutGarment) {
          this.createUpdateOrder(order)
        } else {
          this.orderHelperService.setOrder(order);
          this.createOrderComponent = false;
        }
      },
      (err) => {

      }
    )
  }

  public onSpotInitiate(currentOrder:any) {
    let user = this.authService.getUser();
    var data = {
      "userId": user.userId,
      "storeId": user.storeId,
      "vendorId": user.vendorId,
      "orderId": currentOrder.orderId,
      "currencyId": currentOrder?.currencyId ?? 2,
      "chrgAmt": currentOrder.chrgAmt,
      "discount": currentOrder?.discount ?? 0.0,
      "settlementType": "AUTHCAPT",
      'comment': this.orderNotes ?? '',
      "taxAmount": currentOrder?.taxAmount ?? 0.0,
    };

    this.paymentService.spotInitiate(data).subscribe(
      (res:any) =>{
         this.paymentPayload = res;
         this.paymentPayload['orderId'] = currentOrder.orderId;
         this.paymentPayload['totalAmount'] = currentOrder.chrgAmt;
         this.createOrderScreen = false;
         this.garmentSelectionScreen = false;
         this.isBrainTreePaymentScreen = true;
      },
      (err)=> {
        console.log('spot initiate failed', err);
      }
    )
  }

  //create / update order
  createUpdateOrder(orderData?) {
    this.finalOrderDetailsData = [];
    this.currentUser = this.authService.getUser();
    let order: any = orderData ? orderData : this.orderHelperService.getOrder();
    let orderDetails: any = this.orderHelperService.getAddedGarment();
      let garmentGroups = Object.keys(orderDetails);
      for (let garmentGroup of garmentGroups) {
        let garmentSubGroup = Object.keys(orderDetails[garmentGroup]);
        for (let garmentSub of garmentSubGroup) {
          orderDetails[garmentGroup][garmentSub].map((list, index) => {
            list["amount"] = list.amount;
            list["counter"] = index;
            list["deliveryDate"] = order.deliveryDate;
            list["deliveryTime"] = order.deliveryTime;
            list['orderDetailId'] = list.orderDetailId;
            list['garment'] = list.garment;
            delete list['barcode'];
            this.finalOrderDetailsData.push(list);
          });
        }
      }
    order['orderId'] = this.orderId;
    order['orderDetails'] = this.finalOrderDetailsData;
    order["vendorId"] = this.currentUser.vendorId;
    order.storeId = this.currentUser.storeId;
    let message = this.isEditOrder ? 'updated' : 'created';
    this.orderService.updateOrder(order).subscribe(
      (res) => {
        this.currentOrder = res;
        //alacarte user -payment
        if(this.userSubscriptionInfo.alacarteUser && !this.paylater) {
              this.onSpotInitiate(order);
        } else {
          //subscription user
        let info = {
          message: `order ${message} successfully and the order number is ${res.orderNumber}` // yet to change msg based on work flow
        }
        this.dialogService.successMsg(info).afterClosed()
          .subscribe(res => {
            this.orderHelperService.setOrder({});
            sessionStorage.removeItem('myOrder'); // edit order
            this.router.navigate(['my-orders']);
          });
      }},
      (err) => {
      });
  }

  //create order data form
  prepareCreateOrderData() {
    let pickupTime = this.pickupServiceTime?.split('-');
    let deliveryTime = this.deliveryServiceTime?.split('-');
    let order = this.orderHelperService.getOrder();
    order.deliveryAddressId = this.deliveryDefaultAddress ? this.deliveryDefaultAddress.id : this.defaultAddress.id;
    order.deliveryDate = this.datePipe.transform(this.deliveryDate, 'yyyy-MM-dd');
    order.deliveryStartTime = deliveryTime[0]?.trim();
    order.deliveryEndTime = deliveryTime[1]?.trim();
    order.pickupAddressId = this.pickupDefaultAddress ? this.pickupDefaultAddress.id : this.defaultAddress.id;
    order.pickupDate = this.datePipe.transform(this.pickupDate, 'yyyy-MM-dd');
    order.pickupStartTime = pickupTime[0]?.trim();
    order.pickupEndTime = pickupTime[1]?.trim();
    order.deliveryReceiptId = 0;
    order.express = false;
    order.notifyMethodId = 0;
    order.orderAmount = 0;
    order.orderDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    order.orderDetails = [];
    order.orderId = "";
    order.orderNotes = this.orderNotes;
    order.storeDropOffDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    order.storeOrder = 0;
    order.storeReadyByTime = "10:00";
    order.storeRedayByDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    order.userId = this.currentUser.userId;
    order.vendorId = this.currentUser.vendorId;
    order.orderNotes = '';
    order.storeReadyByDate = '';
    order.storeId = this.currentUser.storeId;
    return order;
  }

  //get defualt care
  getGarmentCare() {
    let userId = this.currentUser && this.currentUser.userId ? this.currentUser.userId : '';
    this.orderService.getGarmentCare(userId).subscribe(
      (res) => {
        this.orderHelperService.setDefaultCare(res.response);
      });
  }


  //pickup time
  pickupTime(time) {
    this.pickupServiceTime = time.startTime + " - " + time.endTime;
  }

  //delivery time
  deliveryTime(time) {
    this.deliveryServiceTime = time.startTime + " - " + time.endTime;
  }
 
  inStoreDeliveryTime(time) {
    this.inStoreDeliveryServiceTime = time.startTime + " - " + time.endTime;
  }

  scrollTop() {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
  }

  selectGarmentBack(event) {
    this.createOrderScreen = true;
    this.garmentSelectionScreen = false;
    this.isBrainTreePaymentScreen = false;
  }

  //set / add garment in edit orderservicesGarmentsList
  setSelectedGarmentByEditflow() {
    this.orderHelperService.initAddGarment();
    this.orderNotes = this.editOrderData.orderNotes;

    this.editOrderData.orderDetails?.forEach(list => {
      let barcode = list.garment['garmentTagId'];
      let service = Object.keys(list.garment.serviceInstructions);
      let serviceName = service && service[0]?.toLowerCase();

        let filteredData = this.garmentList[serviceName].filter(val => val.garmentId == list.garment.garmentId);
        let garmentName = filteredData[0].garmentName.toLowerCase();

        if (filteredData) {
          let garmentDetails = {
            amount: list.amount,
            counter: 0,
            currencyId: 2,
            deliveryDate: "",
            descriptionAdditional: "",
            garment: list.garment,
            orderDetailId: list.orderDetailId,
          }
          this.orderHelperService.setAddGarment(serviceName, garmentName, garmentDetails);

          if (list.stains || list.damages) {
            let stainDamageData = {
              orderDetailId: list.orderDetailId,
              "garment": list.garment,
              'stains': list.stains ? list.stains : [],
              'damages': list.damages ? list.damages : [],
            }
            this.orderHelperService.setAddGarmentValue(serviceName, garmentName, stainDamageData);
          }
        }
    });

  }


  //service time based on pickup date
  pickupDateChange(e) {
    const m: Moment = e.value;
    if (m) {
      let date = this.datePipe.transform(m, 'yyyy-MM-dd');
      let pickupServiceList = this.serviceableDateList.filter(list => list.availableDate == date);
      this.pickupServiceableDateList = [...pickupServiceList];
    }
    if (this.pickupDate) {
      const nextDay = new Date(this.pickupDate);
      nextDay.setDate(nextDay.getDate() + 1); // Set the minimum delivery date to the next day
      this.minDeliveryDate = nextDay;
      this.deliveryDate = this.minDeliveryDate;

      // Reset deliveryDate if it's before the new minDeliveryDate
      if (this.deliveryDate && this.deliveryDate < this.minDeliveryDate) {
        this.deliveryDate = null;
      }
    }
    let order:any = this.orderHelperService.getOrder();
    order['pickupDate'] = this.pickupDate;
    this.orderHelperService.setOrder(order)
  }

  //delivery time based on delivery date
  deliveryDateChange(e) {
    const m: Moment = e.value;
    if (m) {
      let date = this.datePipe.transform(m, 'yyyy-MM-dd');
      let deliveryServiceList = this.serviceableDateList.filter(list => list.availableDate == date);
      this.deliveryServiceableDateList = [...deliveryServiceList];
    }
    let order:any = this.orderHelperService.getOrder();
    order['deliveryDate'] = this.deliveryDate;
    this.orderHelperService.setOrder(order)
  }

  checkSubscription() { 
    this.getStartedService.checkSubscription().subscribe(res=> {
       console.log('check subscription', res);
       this.userSubscriptionInfo = res;
    }, (err)=> {
      console.log('check subscription failed', err);
    })
  }

  // payment success
  onPaymentStatus(event) {
    this.orderHelperService.setOrder({});
    if(event) {
      this.toasterService.success('Payment Success');
    } else {
      this.toasterService.error('Payment failed');
    }
    sessionStorage.removeItem('myOrder'); 
    this.router.navigate(['my-orders']);
  }

  onPayLater() {
    this.paylater = true;
    this.createUpdateOrder();
  }


   // Increase garments count
   increaseGarments(): void {
    this.garmentsCount++;
  }

  // Decrease garments count
  decreaseGarments(): void {
    if (this.garmentsCount >=1) {
      this.garmentsCount--;
    }
  }

  // in store Confirm order handler
  inStoreConfirmOrder(): void {
    console.log('Order confirmed for:', this.garmentsCount, 'garments');
  }
}

