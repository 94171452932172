<div class="basic-details">
  <p class="header-text "> Just as your clothes are precious to you, you are invaluable to us. Help us serve you better by providing your essential details so we can build a stronger connection through our laundry services. </p>
  <div class="content">
      <form  [formGroup]="basicForm" class="basic-form"> 
        <div class="row">
          <div class="col-md-6 ">
            <mat-form-field dense appearance="legacy" class="field-6">
              <input formControlName="firstName"  placeholder="first name" matInput >
            </mat-form-field>
          </div>
          <div class="col-md-6 ">
            <mat-form-field dense appearance="legacy" class="field-6">
              <input formControlName="lastName"  placeholder="last name" matInput >
            </mat-form-field>
          </div>
          <div class="col-md-6 ">
            <mat-form-field dense appearance="legacy" class="field-6">
              <input formControlName="email"  placeholder="email address"  type="email" matInput>
            </mat-form-field>
          </div>
          <div class="col-md-6 basic-screen mat-country-selection">
            <mat-form-field dense appearance="legacy" class="field-6">
              <mat-label>phone number</mat-label>
              <ngx-mat-intl-tel-input
              formControlName="phoneNumber"
              [preferredCountries]="['us', 'in']"
              [enableSearch]="true"
              name="phoneNumber"
              #phone
              (countryChanged)="CountryChangedEvent($event)">
            </ngx-mat-intl-tel-input>
          </mat-form-field> 
          </div>
        </div>
      </form>
  </div>
  <div class="basic-started footer-btn">
    <button *ngIf="authorization" class="started-btn back-btn" (click)="basicBack()" mat-button >back</button> 
    <button class="started-btn next-btn" mat-button (click)="saveBasic()"> next</button>
  </div>
</div>