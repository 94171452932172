import { Component, EventEmitter, OnInit, Output, ViewChildren } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { GetStartedService } from 'src/app/core/services/get-started.service';

@Component({
  selector: 'app-corporate-account',
  templateUrl: './corporate-account.component.html',
  styleUrls: ['./corporate-account.component.scss']
})
export class CorporateAccountComponent implements OnInit {
  corporateAccount = true;
  @ViewChildren('phone') regNumber:any ;
  @Output() corpNextEvent: any = new EventEmitter<string>();
  guestUser: any;
  dialCode: any;
  companyBranchies = [];
  showSubmitButton: boolean = false;

  constructor(
     private getStartedService: GetStartedService, private fb: FormBuilder,
     private authService: AuthService, private toasterService: ToastrService,
     private router: Router
    ) { }
  corporateForm = this.fb.group(
    {
      'email': ['', Validators.required],
      "phoneNumber":['', Validators.required],
      "address": [''] 
    }
  )
  ngOnInit(): void {
    this.guestUser = this.authService.getGuestUser();
     // Check the initial URL
     this.toggleBackButtonVisibility();

     // Listen for route changes
     this.router.events.subscribe(() => {
       this.toggleBackButtonVisibility();
     });
     this.getCompanyAddress();
  }

  onCorpBack() {
    this.corpNextEvent.emit(-1);
  }
  CountryChangedEvent(event){
    this.dialCode =  event.dialCode;
  }

  getCompanyAddress() {
    this.getStartedService.getCompanyAddress()
    .subscribe(
      (data:any) => {
        this.companyBranchies = data.companyBranchList;
      },
      (error) => {
      });
  }
  // save corporate account
  onSaveCorporateAccount() {
    if(this.corporateForm.invalid){
      return
    }
    let formData =  this.corporateForm.value;
    let requestParams = {
      "userId": this.guestUser.userId,
      "countryCode": this.dialCode,
      "employerPhone": formData.phoneNumber.substring(this.dialCode.length + 1, formData.phoneNumber.length),
      "employerAddressId": "1",
      "employerEmail": formData.email
    };
    this.getStartedService.saveCorporateAccount(requestParams)
      .subscribe(
        (data) => {
          this.toasterService.success('corporate details added successfully');
        },
        (error) => {
          this.toasterService.error('Error');
        });
  }

  toggleBackButtonVisibility(): void {
    // Check if the current URL contains 'pricing'
    this.showSubmitButton = this.router.url.includes('/pricing');
  }
}


