import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { filter} from 'rxjs/operators';
import { AppService } from 'src/app/core/services/app.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { MenuItemInfo } from 'src/app/core/model/menu-item-info.model';
import { BehaviorSubject } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { AuthService } from 'src/app/core/Auth/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showMenu = false;
  checkLogin: any = false;
  isLogin = false;
  // currentUser: User;
  headerMenu: MenuItemInfo[] = [];
  // Replace these with the actual URLs of your app on the respective stores
  playStoreUrl  = 'https://play.google.com/store/apps/details?id=com.cleaners.dryo&pcampaignid=web_share'; 
  appStoreUrl = 'https://apps.apple.com/us/app/premier-cleaners/id6566182626';
  separateDialCode = true;
  // SearchCountryField = SearchCountryField;
  // TooltipLabel = TooltipLabel;
  // CountryISO = CountryISO;
  // preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.Italy];

  dryoLogoIcon:String;
  // defaultSpinnerColor = 'white'; // not working
   defaultSpinnerColor = 'accent'; 
  currentUser: any;
  vendorName: String;
  isVendorVisible: boolean;

  get userTopDetails() {
    return this.topHeaderForm.controls;
  }
  topHeaderForm = new FormGroup({
    userCountry: new FormControl('', []),
  });

  selectedCountry = 'us';
  selectedUserCountry = '';

  constructor(private route: ActivatedRoute,
              private router: Router, private appService: AppService,
              private configService: ConfigService,
              private authService: AuthService,
              private dataService: DataService) {
      this.dataService.loggedIn.subscribe((val)=> {
        let token = this.authService.getToken();
        this.isLogin = token ? true : false;
      })
  }

  ngOnInit() {
    let hostName = window.location.hostname;
    this.isVendorVisible = hostName == 'premiercleanersga.com' ? true : false;
    this.selectedUserCountry = this.selectedCountry.toUpperCase();
    this.dataService.getVendorConfig().subscribe(config => {
      if(config) {
        this.dryoLogoIcon = config.vendorLogo;
      }
    });
    //this.dryoLogoIcon = this.appService.getDryoLogoImagePath();
   this.vendorName = this.appService.getVendorNamePath();
  }

  changeCountryFn(selCountry) {
     this.selectedCountry = (selCountry.iso2).toUpperCase();
     this.selectedUserCountry = this.selectedCountry ;
  }
  showMenuFn() {
    var nav = document.querySelector('nav');
    if(this.showMenu) {
      nav.classList.remove("s-bg");
    } else {
      nav.classList.add("s-bg");
    }
  }
 
  scrollIntoView(anchorHash) {
    setTimeout(() => {
      const anchor = document.getElementById(anchorHash);
      let topOfElement = 0;
      if (anchor) {
        anchor.focus();
        anchor.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        topOfElement = anchor.offsetTop - 65;
        window.scroll({ top: topOfElement, behavior: "smooth" });
      }
      else{
        window.scroll(0,0);
      }
    });
  }
  
  @HostListener('window:scroll', ['$event']) 
  onScroll(event) {
  var mybutton = document.getElementById("myBtn");
  var header = document.querySelector('header');
  var nav = document.querySelector('nav');
  //page-up-button
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    mybutton.style.display = "block";
    header.classList.remove("header");
  } else {
    mybutton.style.display = "none";
    header.classList.add("header");
  }
  if (document.body.scrollTop > 170  || document.documentElement.scrollTop > 170 ) {
    nav.classList.add("s-bg");
  } else if(document.body.scrollWidth <= 527) {
    nav.classList.add("s-bg");
  } else {
    nav.classList.remove("s-bg");
  }
}


  menuNav(url) {
    var header = document.querySelector('header');
    this.router.navigate([url]);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(res => {
      if (this.router.url == "/home" || this.router.url == '/' || this.router.url == "/home#download" ) {
        header.classList.add("header-bt");
      }
      else {
        header.classList.remove("header-bt");
      }
    });
  }

  getSpinnerObservable(): BehaviorSubject<boolean> {
   const spinnerObservable$ = this.configService.getSpinnerObservable();
   return spinnerObservable$;
  }

  getHeaderMenu(): MenuItemInfo[] { // returs an array of HeaderMenu items
    const loading = this.configService.getLoading();
    if ( this.headerMenu.length === 0)
        this.headerMenu = this.configService.getHeaderMenu(); 
    return this.headerMenu;
  }

  redirectLogin() {
    localStorage.clear();
    sessionStorage.clear();
    this.dataService.updateLogin(false);
    this.router.navigate(['/login']);
  }
  signOut(){
    this.currentUser = this.authService.getUser();
    let body = {
      vendorId: this.currentUser?.vendorId,
      "userId": this.currentUser?.userId,
      "deviceId": "web",
      "token": this.authService.getToken(),
    }
    this.authService.logout(body).subscribe(
      (res)=> {
        this.redirectLogin();
      },
      (err)=> {
        this.redirectLogin();
      }
    )
  }

  showSidebar: boolean = false;

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
  }

  hideMenuFn(fragment: string) {
    // Logic for handling menu hiding or fragment navigation
    this.showSidebar = false; // Ensure sidebar closes when a menu item is clicked
      this.scrollIntoView(fragment);
  }

  redirectToStore() {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    if (/android/i.test(userAgent)) {
      // Redirect to Google Play Store
      window.open(this.playStoreUrl, '_blank');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
      // Redirect to Apple App Store
      window.open(this.appStoreUrl, '_blank');
    } else {
      // Fallback or desktop behavior
      window.open(this.appStoreUrl, '_blank');
    }
  }

  reDirectToCreateOrder() {
    this.router.navigate(['/create-order']);
  }
}
