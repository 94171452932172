import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss'],
})
export class MembershipComponent implements OnInit {
  accountType = 'switch to corporate account';
  selectedItem = 1;
  individualText: string = 'switch to alacarte';
  footerButton = false;
  constructor() { }

  ngOnInit(): void {
  }

  onPackNext(event){
    
  }
}
