<div class="corporate-account">
  <div class="col-12">
  <p class="header-text mt-4">
    please provide your employer name and business email address to sign-up using corporate account. If
    you are new to your employer, please note that it may take up to 15 days to upload your information
    into dryo.
  </p>
</div>
  <div class="content col-12">
    <form [formGroup]="corporateForm">
      <div class="row">
        <div class="col-md-6 ">
          <mat-form-field dense appearance="legacy" class="field-6">
            <mat-label>company email</mat-label>
            <input matInput formControlName="email" placeholder="">
          </mat-form-field>
        </div>
        <div class="col-md-6  basic-screen mat-country-selection">
          <mat-form-field dense appearance="legacy" class="field-6">
            <mat-label>phone number</mat-label>
            <ngx-mat-intl-tel-input formControlName="phoneNumber" [preferredCountries]="['us', 'in']"
              [enableSearch]="true" name="phoneNumber" #phone (countryChanged)="CountryChangedEvent($event)">
            </ngx-mat-intl-tel-input>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 ">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label *ngIf="companyBranchies.length">select address</mat-label>
            <mat-label *ngIf="!companyBranchies.length">enter address</mat-label>
            <mat-select *ngIf="companyBranchies.length" name="companyaddress" formControlName="address" disableOptionCentering>
              <mat-option *ngFor="let company of companyBranchies" [value]="company.employerAddressId">
                {{company.branchName}}
              </mat-option>
            </mat-select>
            <input *ngIf="!companyBranchies.length" matInput formControlName="address" placeholder="">
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="footer-btn col-12">
    <button  *ngIf="!showSubmitButton"  (click)="onCorpBack()" class="started-btn back-btn" mat-button>back</button>
    <button  *ngIf="!showSubmitButton"  (click)="onSaveCorporateAccount()" class="started-btn next-btn" mat-button>
      next
    </button>
    <button  *ngIf="showSubmitButton"  (click)="onSaveCorporateAccount()" class="started-btn next-btn" mat-button>
      submit
    </button>
  </div>
</div>