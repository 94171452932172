import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderHelperService } from 'src/app/core/services/order-helper.service';
import { OrderService } from 'src/app/core/services/order.service';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { config } from 'config';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-stain-damage',
  templateUrl: './stain-damage.component.html',
  styleUrls: ['./stain-damage.component.scss']
})
export class StainDamageComponent implements OnInit {
  @ViewChild('imageDrop') imageDrop;
  acceptedImageTypes = { 'image/png': true, 'image/jpeg': true, 'image/gif': true };
  @Input('counter') counter: any;
  @Input('orderId') orderId: any;
  @Input('title') title: any;
  @Input('addedGarment') addedGarment: any;
  @Output() stainDamageBack: any = new EventEmitter<string>();
  @Output() stainDamageSuccess: any = new EventEmitter<string>();

  imageUpload = this.formBuilder.group({
    selectedDress: ['', Validators.required],
    selectedStain: ['', Validators.required],
    imageInput: [''],
    comment: [''],
  });
  damageInfo: boolean;
  stainInfo: boolean;
  stainDamageList = [];
  dresses = ['dress1', 'dress2', 'dress3'];
  stainTypes = ['baby food', 'bbq sauce', 'beer', 'berries', 'blood', 'berger drip', 'butter', 'chocolate'];
  damageTypes = ['tear', 'vent'];
  files = new Array<any>();
  rows: any = [];
  editIndex: any = null;
  isPagination = false;
  currentOrder: any;
  currentUser: any;
  currentFiles: any = [];
  groupStainList: any = [];
  orderWorkFlow: any;
  orderDetailId: any;
  fileImageCount: number;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private orderHelperService: OrderHelperService,
    private orderService: OrderService,
    private authService: AuthService,
    private toasterService: ToastrService
  ) {
  }

  ngOnInit(): void {
    if (this.title == 'damage') {
      this.damageInfo = true;
      this.stainInfo = false;
    } else {
      this.damageInfo = false;
      this.stainInfo = true;
    }
    this.currentUser = this.authService.getUser();

    this.route.paramMap.subscribe((data) => {
      this.orderWorkFlow = data['params']['workflow'];
    });
  }

  allowDrop(e) {
    e.preventDefault();
  }

  //select garment
  onGarmentChange() {
    this.rows = [];
    let selectedGarment = this.imageUpload.controls['selectedDress'].value;
    let filteredGarment = this.addedGarment.find(list => list.garment.garmentTagId == selectedGarment);
    if (filteredGarment.orderDetailId) {
      this.orderDetailId = filteredGarment.orderDetailId;
      this.getStainDamageImages(filteredGarment.orderDetailId);
    }
  }

  //get uploaded images
  getStainDamageImages(orderDetailId) {
    this.groupStainList = [];
    let type = (this.title == 'damage') ? 'damage' : (this.title == 'mark stain') ? 'stain' : 'new';
    let data = {
      orderId: this.orderId,
      orderDetailId: orderDetailId,
      imageType: type,
      storeId: this.currentUser.storeId
    }
    this.orderService.getStainDamageImage(data)
      .subscribe(res => {
        let stainList = res.orderGarmentImageList.map(list => {
          let row = {};
          row['selectedDress'] = this.imageUpload.controls['selectedDress'].value;
          row['selectedStain'] = list.typeOf;
          row['addComment'] = list.comment;
          row['stains'] = [];
          row['stains'].push({imageId: list.orderGarmentImageId, imageURL: list.imageURL});
          row['orderDetailId'] = orderDetailId
          return row;
        });

        //group stains
        for (let list of stainList) {

          if (this.groupStainList && this.groupStainList.length) {
            let filteredData = this.groupStainList.findIndex(item=> item.selectedStain == list.selectedStain);
            if(filteredData !=-1) {
              this.groupStainList[filteredData].stains.push(list.stains[0]);
            } else {
              this.groupStainList.push(list);
            }
          } else {
            this.groupStainList.push(list);
          }
        };
        this.rows = [];
        this.rows= [...this.groupStainList];

      }, (err) => {

      })
  }

  //checkfiles
  checkfiles(targetfiles) {
    this.readfiles(targetfiles);
  }

  //readfiles
  readfiles(targetfiles) {
    let count=1;
    this.files = [];
    this.currentFiles = [];
    if (targetfiles) {
      for (let file of targetfiles) {
        if(count <=5) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            this.files.push({imageURL: e.target.result});
            this.currentFiles.push(file)
          }
          reader.readAsDataURL(file);
        } 
        count++;
      }
    }

    if(targetfiles.length > 5) {
      this.toasterService.info('Maximum five files only allowed');
    }

  }

  //drag and drop
  drop(e) {
    e.preventDefault();
    this.imageUpload.controls.imageInput.reset();
    this.imageDrop.innerHTML = "";
    this.checkfiles(e.dataTransfer.files);
  }

  //image change
  imageChange(event) {
    this.imageDrop.innerHTML = "";
    this.checkfiles(event.target.files);
  }//imageChange

  imageUploadSubmitted() {

  }

  //remove stain
  onRemove(index, event) {
    this.files.splice(index, 1);
    this.currentFiles.splice(index, 1);
    console.log(this.files);
    console.log(this.currentFiles);
    event.stopPropagation();
  }

  // reset
  onReset() {
    this.formReset();
  }

  //add stain
  stainDamageTable(garmentImage, fileImageCount) {

    if (this.imageUpload.valid && this.files && this.files.length > 0) {
      let stainData = this.imageUpload.value;
      let stain = {
        selectedDress: stainData.selectedDress,
        selectedStain: stainData.selectedStain,
        addComment: stainData.comment,
        stains: [],
        garmentImageId: garmentImage.garmentImageId,
        orderDetailId: garmentImage.orderDetailId
      }
      stain.stains.push({imageId: garmentImage.garmentImageId , imageURL: this.files[fileImageCount].imageURL});

      if (this.editIndex == 0 || this.editIndex > 0) {
        this.rows[this.editIndex] = stain;
      } else {
        let index = this.rows.findIndex(val => val.selectedDress == stain.selectedDress && val.selectedStain == stain.selectedStain);
        if (index != -1) {
          this.rows[index].stains.push({imageId: garmentImage.garmentImageId , imageURL: this.files[fileImageCount].imageURL});
        } else {
          this.rows.push(stain);
        }
      }
      this.editIndex = null;
    }
    this.isPagination = this.stainDamageList.length >= 5;
    if(fileImageCount == this.files.length-1) {
      this.formReset();
    }
  }


  //form reset
  formReset() {
   // this.imageUpload.controls['selectedDress'].setValue('');
    this.imageUpload.controls['selectedStain'].setValue('');
    this.imageUpload.controls['imageInput'].setValue('');
    this.imageUpload.controls['comment'].setValue('');
    this.files = [];
  }

  // remove stain in table
  rowStainRemove(row, subIndex) {
      this.stainDelete(row, subIndex);
      this.stainDamageList[row].stains.splice(subIndex, 1);
      this.rows = [...this.stainDamageList];
  }

  //edit stain
  // stainEdit(index) {
  //   this.editIndex = index;
  //   this.imageUpload.patchValue(this.rows[index]);
  //   let newFile = JSON.parse(JSON.stringify(this.rows[index].stains));
  //   this.files = newFile;
  // }

  // delete stain
  stainDelete(index, subIndex?) {
    //delete stain or damages
  //  if(this.orderWorkFlow == 'edit') {
      let imageIds;
      if(subIndex ==undefined || subIndex == null) { //single image delete
        imageIds = this.rows[index].stains.length > 0 ? this.rows[index].stains.map(list=> list.imageId).filter(item => item !=undefined) : this.rows[index].stains.imageId;
      } else { // multiple image delete
        imageIds = this.rows[index].stains[subIndex].imageId;
      }
      let garment = {
        'orderId' : this.orderId,
        'userId' : this.currentUser.userId,
        'imageIds': imageIds,
        'orderDetailId': this.rows[index].orderDetailId,
        storeId: this.currentUser.storeId,
      }
       this.orderService.onDeleteGarmentImage(garment).subscribe(
         (res) => {
           if(subIndex == undefined || subIndex == null) {
             this.rows.splice(index, 1);
           } else {
             if(this.rows[index].stains && this.rows[index].stains.length == 1) {
              this.rows.splice(index, 1);
            } else {
               this.rows[index].stains.splice(subIndex, 1);
             }
           }
           this.formReset();
         },
         (err) => {
           console.log(err);
         }
       )

    //} else {
     // this.stainDamageList.splice(index, 1);
     // this.rows = [...this.stainDamageList]
 //   }
  }

  // on row edit 
  onEditAction() {
    let edit = false;
    if (this.editIndex == 0 || this.editIndex > 0) {
      edit = true
    }
    return edit;
  }


  stainDamageback() {
    this.stainDamageBack.emit(1);
  }

  //add stain / damage
  addImage() {
    this.imageUpload.markAllAsTouched();
    let filteredGarment = this.addedGarment.find(item => item.garment.garmentTagId == this.imageUpload.value.selectedDress);
    if (filteredGarment && filteredGarment.orderDetailId) {
      //direct file upload with existing orderDetail id
      this.onFileUpload(filteredGarment.orderDetailId, filteredGarment);
    } else {
      //get order detail id for stain / damage upload case - 
      this.getOrderDetailId(filteredGarment);
    }
  }

  //get order detail id
  getOrderDetailId(care) {
    //call order details to get orderDetails id
    let garmentData = this.careDetail(care);
    this.orderService.garmentOrderDetails(garmentData).subscribe(
      (res) => {
        //passing orderDetails id
        garmentData['garment']['garmentTagId'] = this.imageUpload.value.selectedDress;
        garmentData['garment']['garmentLabelId'] = res.garmentLabelId;
        garmentData['orderDetailId'] = res.orderDetailId;
        this.stainDamageSuccess.emit(garmentData);
        this.addedGarmentUpdate(garmentData); //current garment update
        this.onFileUpload(res.orderDetailId, garmentData);
      }
    )
  } 

  //prepare care detail
  careDetail(selectedGarment) {
    let currentOrder: any = this.orderHelperService.getOrder();
    return {
      orderId: this.orderId,
      storeId: this.currentUser.storeId,
      amount: 0,
      counter: this.counter,
      deliveryDate: currentOrder.deliveryDate,
      deliveryTime: currentOrder.deliveryTime,
      garment: {
        comment: currentOrder.comment,
        description: "this is a description",
        garmentId: selectedGarment?.garment?.garmentId,
        garmentStatusId: selectedGarment?.garment?.garmentStatusId,
        garmentTagId: selectedGarment?.garment?.garmentTagId,
        garmentCategoryId: selectedGarment?.garment?.garmentCategoryId,
        instruction: "this is an instruction",
        price: 0,
      },
      stains: [],
      damages: []
    };
  }

  //save order details and garment image
  onFileUpload(orderDetailId, selectedGarment?) {
    let userInfo = this.authService.getUser();
    this.fileImageCount = 0;
    for(let file of this.currentFiles) {
      let stainDamageForm = this.imageUpload.value;
      let type = (this.title == 'damage') ? 'damage' : (this.title == 'mark stain') ? 'stain' : 'new';
      var formData: any = new FormData();
      formData.append("orderDetailId", orderDetailId);
      formData.append("userId", this.currentUser.userId);
      formData.append("imageType", type);
      formData.append("typeOf", stainDamageForm.selectedStain);
      formData.append("comment", stainDamageForm.comment);
      formData.append("file", file);
      formData.append("vendorId", userInfo.vendorId);
      formData.append("orderId", this.orderId);
      formData.append("storeId", this.currentUser.storeId);
  
      this.orderService.addGarmentImage(formData).subscribe(
        (res) => {
          this.stainDamageTable(res, this.fileImageCount);
          this.fileImageCount++;
        },
        (err) => {
        }
      )
    }
  }

  onSave() {
    this.stainDamageBack.emit(1);
  }

  addedGarmentUpdate(data) {
    this.addedGarment.map(list=> {
      if(list.garment.garmentTagId == data.garment.garmentTagId ) {
        list['garment']['garmentLabelId'] = data.garment.garmentLabelId;
        list['orderDetailId'] = data.orderDetailId;
      }
    });
  }
}
