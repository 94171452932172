import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetStartedService } from 'src/app/core/services/get-started.service';

@Component({
  selector: 'app-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.scss']
})
export class BasicDetailsComponent implements OnInit, AfterViewInit {
  @Output() basicNextEvent = new EventEmitter<string>();
  @ViewChildren('phone') regNumber:any ;
  nextBtnStyle:boolean = true;
  Userinfo = true;
  authorization = false;
  basicForm: FormGroup;

 
  otp: any;
  dialCode: any;
  constructor(private fb: FormBuilder,
     private getStartedService: GetStartedService,
     private toasterService: ToastrService) { }

  ngOnInit(): void {
    this.formInit();
  }
  ngAfterViewInit(){
    // this.regNumber.nativeElement.focus();
   
  }
  formInit() {
     this.basicForm = this.fb.group({
       firstName: ['', Validators.required],
       lastName:[''],
       email:['', [Validators.email]],
       phoneNumber:['', Validators.required],
      //  zipCode: ['', Validators.required]
     })
  }
  get basicDetails() {
    return this.basicForm.controls;
  }
  saveBasic() {
    this.basicForm.markAllAsTouched();
    if(this.basicForm.valid){
      this.nextBtnStyle = false;
      this.Userinfo = false;
      let phone = this.basicForm.get('phoneNumber').value;
      var phoneComponents = {
       // dialCode:phone.substring(1, this.dialCode.length + 1),
        dialCode:this.dialCode,
        number: phone.substring(this.dialCode.length + 1, phone.length)
      };
      let userBasicData:any = {
        firstName: this.basicDetails.firstName.value,
        lastName:this.basicDetails.lastName.value,
        email:this.basicDetails.email.value,
        number:phoneComponents.number,
        dialCode:phoneComponents.dialCode
      }
      this.getStartedService.userExist(userBasicData).subscribe(
        (res:any)=> {
          if(res.userExist == 'no'){
            this.basicNextEvent.emit(userBasicData);
          } else {
            this.toasterService.error(res.message);
          }
        },
        (err)=> {
        }
      )
      if(this.otp > 1) {
      }
    }
  }
  basicBack() {
    this.Userinfo = true;
    this.otp = '';
    this.nextBtnStyle = true;
  }
  CountryChangedEvent(event){
    this.dialCode =  event.dialCode;
  }

}
