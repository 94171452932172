import {Component, HostBinding, HostListener, Input, OnInit} from '@angular/core';
//import {NavItem} from '../nav-item';
import {ActivatedRoute, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean = false;
  selectedItem = 0;
  selectedChildItem = 0;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item;
  @Input() depth: number;
  isMenuOpen: boolean = false;
  isMobile: boolean = window.innerWidth < 768; // Detect initial screen size
  navItems = [
    {
      displayName: 'create order',
      iconName: 'recent_actors',
      route: 'create-order',
      order: 0
    },
    {
      displayName: 'my orders',
      iconName: 'recent_actors',
      route: 'my-orders',
      order: 1
    },
    {
      displayName: 'my settings',
      iconName: 'recent_actors',
      route: '',
      order: 2,
      children: [
        {
          displayName: 'my profile',
          iconName: 'group',
          route: 'my-profile',
          order: 3 
        },
        {
          displayName: 'preference',
          iconName: 'speaker_notes',
          route: 'preference',
          order: 4,
        },
        {
          displayName: 'membership',
          iconName: 'feedback',
          route: 'membership',
          order: 5
        },
        {
          displayName: 'billing methods',
          iconName: 'group',
          route: 'billing-method',
          order: 6
        },
        // {
        //   displayName: 'payment history',
        //   iconName: 'speaker_notes',
        //   route: 'payment-history',
        //   order: 7
        // },
        {
          displayName: 'deactivate account',
          iconName: 'feedback',
          route: 'deactivate-account',
          order: 8
        },
        {
          displayName: 'delete account',
          iconName: 'group',
          route: 'delete-account',
          order: 9
        },
        {
          displayName: 'communication',
          iconName: 'speaker_notes',
          route: 'communication',
          order: 10
        },
        // {
        //   displayName: 'notifications',
        //   iconName: 'feedback',
        //   route: 'notification',
        //   order: 11
        // }
      ]
    }
  ];
  show: any;


  constructor(
              public router: Router,
              private dataService: DataService,
              private route: ActivatedRoute) {
  }

  onItemSelected(item, index) {
    if (this.isMobile) {
      this.isMenuOpen = false; // Close menu for mobile
    }
    this.selectedItem = index;
    this.selectedChildItem = null; // Reset child selection if parent is reselected
    localStorage.setItem('selectedItem', index.toString()); // Store in localStorage

    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
      this.router.navigate(['/my-profile']);
      localStorage.setItem('expandedState', this.expanded.toString()); // Store expanded state in localStorage
    } else {
      this.router.navigate(['/' + item.route ]);
      this.expanded = false;
    }
    this.selectedChildItem = 0;
    
  }

  onChildSelected(item, event, index){
    if (this.isMobile) {
      this.isMenuOpen = false; // Close menu for mobile
    }
    this.selectedChildItem = index;
    localStorage.setItem('selectedChildItem', index.toString()); // Store in localStorage
      if (!item.children || !item.children.length) {
        this.router.navigate(['/' + item.route ]);
    }
  
    event.stopPropagation();
   }

   ngOnInit(): void {
    this.dataService.activeMenu$.subscribe((activeMenu:any) => {
      this.onRefreshToSetMenuActive();
    });
     // Retrieve selected item and child item from localStorage if they exist
     const savedSelectedItem = localStorage.getItem('selectedItem');
     const savedSelectedChildItem = localStorage.getItem('selectedChildItem');
     const savedExpandedState = localStorage.getItem('expandedState');
 
     if (savedSelectedItem !== null) {
       this.selectedItem = parseInt(savedSelectedItem, 10);
     }
 
     if (savedSelectedChildItem !== null) {
       this.selectedChildItem = parseInt(savedSelectedChildItem, 10);
     }
     // Restore the expanded state
     this.expanded = savedExpandedState === 'true';
  }

  onRefreshToSetMenuActive() {
    this.expanded = false;
     // Check the current route and set the active menu item
     const currentRoute = this.router.url;
    if (currentRoute.includes('/my-orders/order/') || currentRoute.includes('/create-order')) {
      // Set "create order" as active
      this.selectedItem = this.navItems.findIndex(item => item.route === 'create-order');
    } else if (currentRoute === '/my-orders') {
      // Set "my orders" as active
      this.selectedItem = this.navItems.findIndex(item => item.route === 'my-orders');
    }
  }
  
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = event.target.innerWidth < 400; // Update isMobile on resize
  }
}