<div class="deactivate-account">
    <h1 class="header-title">deactivate account</h1>
    <div class="header-line"></div>
    <h3 class="notification-text mt-20">you will receive these notifications your email <span> </span></h3>
    <div class="date-picker mt-20">

        <div class="date-picker-container">
            <!-- Start Date -->
            <mat-form-field dense appearance="legacy" class="full-width">
              <mat-label>Start Date</mat-label>
              <input
                matInput
                [(ngModel)]="startDate"
                [matDatepicker]="startPicker"
                placeholder="Select start date"
                [matDatepickerFilter]="startDateFilter"
                (focus)="startPicker.open()"
              />
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
          
            <!-- End Date -->
            <mat-form-field dense appearance="legacy" class="full-width">
              <mat-label>End Date</mat-label>
              <input
                matInput
                [(ngModel)]="endDate"
                [matDatepicker]="endPicker"
                placeholder="Select end date"
                [matDatepickerFilter]="endDateFilter"
                (focus)="endPicker.open()"
              />
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
          </div>
          
    </div>
    <button class="confirm-btn next-btn mt-20" mat-button (click)="onDeactivate()"> deactivate account</button>
</div>