import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  private vendorProfileConfigSubject = new BehaviorSubject<any>(null);
  private myMessage = new Subject<any> ();
  public isLoading = new BehaviorSubject<boolean>(false);
  loading = this.isLoading.asObservable();
  private isLogin = new BehaviorSubject<boolean>(false);
  loggedIn =  this.isLogin.asObservable();

  private activeMenuSubject = new BehaviorSubject<string>(''); // Default value
  activeMenu$ = this.activeMenuSubject.asObservable();

  constructor() { }

  getMessage(): Observable<any> {
    return this.myMessage.asObservable();
  }
  updateMessage(message: any) {
    this.myMessage.next(message);
  }
  updateLoading(loading){
    this.isLoading.next(loading); 
  }
  updateLogin(val){
    this.isLogin.next(val); 
  }

  setVendorConfig(val) {
    this.vendorProfileConfigSubject.next(val);
  }

  getVendorConfig(): Observable<any> {
    return this.vendorProfileConfigSubject.asObservable();
  }

  setActiveMenu(menu: string): void {
    this.activeMenuSubject.next(menu);
  }
}
