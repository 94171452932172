<section class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="flex-center col-sm-12" *ngIf="!(getSpinnerObservable() | async) && 
      (getFooterMenu() | orderBy : 'displayOrder' : false) as orderedFooterMenu">
        <div class="">
          <a class="logo navbar-brand p-0" routerLink="/home"><img src="{{dryoLogoIcon}}"></a>
        </div>
        <!-- <div class="about-us">
          <h1 class="header-title">
            {{orderedFooterMenu[0].menuItem}}</h1>
          <ul>
            <li>our culture</li>
            <li>leadership</li>
            <li>partners</li>
            <li>careers</li>
          </ul>
        </div> -->
        <div class="quick-links">
          <h1 class="header-title">quick links</h1>
          <ul>  
            <li><a routerLink="/my-profile">my account</a></li>
            <li><a routerLink="/locations">locations</a></li>
            <li><a routerLink="/pricing">pricing</a></li>
            <li (click)="redirectToStore()"><a>mobile app</a></li>
          </ul>
        </div>
        <div class="help">
          <h1 class="header-title">Resources</h1>
          <ul>     
            <li><a routerLink="/faq">Faq</a></li>
            <li><a routerLink="/privacy-policy">Privacy policy</a></li>
            <li><a routerLink="/terms-of-use">terms of service</a></li>
            <li><a routerLink="/investor-relations">investor relations</a></li>
          </ul>
        </div>
        <div class="contact">
          <h1 class="header-title">contact us</h1>
          <ul>  
            <li><a routerLink="/technical-support">technical support</a></li>
            <li>order support</li>
            <li class="contact-address">(706)-216-5245 <br> 66, S 400 Center Ln, Suite 185, <br>Dawsonville, GA 30534
              </li>
          </ul>
        </div>
       
      </div>
    </div>

    <div class="row text-center">
      <div class='col-sm-12 smedia'>
       <a target='_blank' href="{{socialMedia.href}}" 
         *ngFor='let socialMedia of getSocialMediaLinks() | orderBy: "displayOrder" : false'>        
          <img src='{{socialMedia.thumbnail}}' />
        </a>
      </div>
    </div>
    <div class="row">
      <div class="copy-rights col-sm-12">
        <h1>{{vendorName}} © copyright 2024 <span>|</span> all rights reserved</h1>
        <img (click)="topFunction()" class="icon-uo-arrow" id="myBtn" src="{{upArrowIcon}}" />
      </div>
    </div>
  </div>

</section>