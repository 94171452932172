<div class="confirm-order-container">
  <h5>Order summary</h5>

  <!-- First Row: Pickup and Drop Details -->
  <div class="row details-row">
    <!-- Pickup Details -->
    <div class="details-box pickup-details">
      <h2>Pickup Details</h2>
      <div class="detail-item">
        <span class="label">Address</span>
        <span class="colon">:</span>
        <span class="value">
          <ng-container  *ngIf="pickupDefaultAddress && pickupDefaultAddress?.addressLine1">
              {{pickupDefaultAddress.addressLine1}}, {{pickupDefaultAddress.addressLine2}}
          </ng-container>
          <ng-container  *ngIf="!pickupDefaultAddress && defaultAddress?.addressLine1">
              {{defaultAddress.addressLine1}}, {{defaultAddress.addressLine2}}
          </ng-container>
        </span>
      </div>
      <div class="detail-item">
        <span class="label">Date</span>
        <span class="colon">:</span>
        <span class="value">{{ orderInfo?.pickupDate | date:'shortDate' }}</span>
      </div>
      <div class="detail-item">
        <span class="label">Time</span>
        <span class="colon">:</span>
        <span class="value">{{ orderInfo?.pickupStartTime }} - {{orderInfo?.pickupEndTime}}</span>
      </div>
    </div>

    <!-- Drop Details -->
    <div class="details-box drop-details">
      <h2>Drop Details</h2>
      <div class="detail-item">
        <span class="label">Address</span>
        <span class="colon">:</span>
        <span class="value">
          <ng-container *ngIf="deliveryDefaultAddress && deliveryDefaultAddress?.addressLine1">
              {{deliveryDefaultAddress.addressLine1}}, {{deliveryDefaultAddress.addressLine2}}
          </ng-container>
          <ng-container *ngIf="!deliveryDefaultAddress && defaultAddress?.addressLine1">
              {{defaultAddress.addressLine1}}, {{defaultAddress.addressLine2}}
          </ng-container>
        </span>
      </div>
      <div class="detail-item">
        <span class="label">Date</span>
        <span class="colon">:</span>
        <span class="value">{{ orderInfo?.deliveryDate | date:'shortDate' }}</span>
      </div>
      <div class="detail-item">
        <span class="label">Time</span>
        <span class="colon">:</span>
        <span class="value">{{ orderInfo?.deliveryStartTime}} -  {{orderInfo?.deliveryEndTime }}</span>
      </div>
    </div>
  </div>

  <!-- Second Row: Garment Summary and Payment Details -->
  <div class="row garment-payment-row">
    <!-- Selected Garments -->
    <div class="garment-summary-box">
      <h2>Selected Garments</h2>
      <div class="garment-summary">
        <ng-container *ngFor="let garment of garmentList | keyvalue">
          <ng-container *ngFor="let data of garment.value">
            <div class="garment-item" *ngIf="data.count">
              <div class="garment-name">{{ data.garmentName }} - <span>{{ garment.key }}</span></div>
              <div class="garment-count"> {{ data.count }} x {{ data.prices[0].price | currency }}</div>
              <div class="garment-amount">{{ data.prices[0].currencySymbol }} {{ getAmount(data.count, data.prices[0].price) | number:'1.2-2' }}</div>
            </div>
          </ng-container>
        </ng-container>
        <div class="total-summary">
          <p>Total Items: {{ getTotalCount() }}</p>
        </div>
      </div>
    </div>

    <!-- Payment Details -->
    <div class="payment-summary-box">
      <h2>Payment Details</h2>
      <div class="payment-summary">
        <div class="summary-item">
          <span>Subtotal:</span>
          <span>{{ getTotalPrice() | currency }}</span>
        </div>

        <div class="summary-item" *ngIf="appTypeDiscountAmount">
          <span>app type discount:</span>
          <span>{{ appTypeDiscountAmount | currency }}</span>
        </div>
        <div class="summary-item" *ngIf="subscriptionDiscountAmount">
          <span>customer discount:</span>
          <span>{{ subscriptionDiscountAmount | currency }}</span>
        </div>
        <div class="summary-item" *ngIf="promotionDiscountAmount">
          <span>promotional discount:</span>
          <span>{{ promotionDiscountAmount | currency }}</span>
        </div>

        <div class="summary-item" *ngIf="discountAmount">
          <span>Discount:</span>
          <span>{{ discountAmount | currency }}</span>
        </div>

        <div class="summary-item">
          <span>environmental fees:</span>
          <span>{{ taxAmount | currency }}</span>
        </div>
       
        <div class="summary-item total">
          <strong>Total:</strong>
          <strong>{{ totalAmount | currency }}</strong>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer Buttons -->
  <div class="footer-btn fixed-footer">
    <button (click)="onconfirmOrderBack()" class="back-btn" mat-button>Back</button>
    <button *ngIf="userSubscription?.alacarteUser" (click)="onPayLater()" class="next-btn" mat-button>pay later</button>
    <button *ngIf="userSubscription?.alacarteUser" (click)="onConfirmOrder()" class="next-btn" mat-button> {{ isEditOrder ? 'pay & update Order' : 'pay & confirm order' }}</button>
    <button  *ngIf="userSubscription?.subscribedUser" (click)="onConfirmOrder()" class="next-btn" mat-button>
      {{ isEditOrder ? 'Update Order' : 'Confirm Order' }}
    </button>
  </div>
</div>
