import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-pickup-delivery-address',
  templateUrl: './pickup-delivery-address.component.html',
  styleUrls: ['./pickup-delivery-address.component.scss']
})
export class PickupDeliveryAddressComponent implements OnInit {
  @Input('firstTimeUser') firstTimeUser:any;
  @Output() pickupEvent:any = new EventEmitter<string>();
  @ViewChild("demo3Tab", { static: false }) demo3Tab: MatTabGroup;
  tabActive = 0;
  demo1TabIndex: number;
  constructor() { }

  ngOnInit(): void {
  }
  
  onAddressBack(step){
    //back
    if(step == -1) {
      this.pickupEvent.emit(step);
    } else {
      const tabGroup = this.demo3Tab;
      if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
    
      const tabCount = tabGroup._tabs.length;
      tabGroup.selectedIndex = (tabGroup.selectedIndex + 1) % tabCount;
    }
  }

  tabChanged(event){
    this.tabActive = event.index;
  }
}
