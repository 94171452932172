<div class="">
  <h1 class="header-title">membership</h1>
  <div class="header-line"></div>
  <div class="material-new-tabs mt-4 ">
    <mat-tab-group>

      <!-- Tab for À la carte Account -->
      <mat-tab label="onetime">
        <ng-container>
          <app-alacarte-user></app-alacarte-user>
        </ng-container>
      </mat-tab>

      <!-- Tab for Individual Account -->
      <mat-tab label="subscription">
        <ng-container>
          <app-subscription (packNextEvent)="onPackNext($event)"></app-subscription>
        </ng-container>
      </mat-tab>

      <!-- Tab for Corporate Account -->
      <mat-tab label="business">
        <app-corporate-account></app-corporate-account>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>