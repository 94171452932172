<div class="container terms-use pt-200 ">
    <div class="row">
        <div class="col-sm-12">
            <h1 class="headerTitle">terms of service</h1>
            <!-- <div class="headerLine"></div> -->
        </div>
        <div class="col-sm-12">
            <h2>WE ARE NOT RESPONSIBLE FOR ARTICLES NOT CLAIMED
                UNCLAIMED GARMENTS WILL BE HANDLED IN ACCORDANCE WITH STATE REGULATIONS</h2>
            <div class="content">
                <p>We exercise utmost care in processing articles entrusted to us and use such processes which, in our
                opinion, are best suited to the nature and condition of each individual article. Nevertheless, we cannot
                assume responsibility for inherent weaknesses of or defects in materials that are not readily apparent
                prior to processing. This applies particularly, but not exclusively, to suedes, leathers, silks, satins,
                double-faced fabrics, vinyls, polyurethanes, etc. Responsibility also is disclaimed for trimmings,
                buckles, beads, buttons, bells and sequins.</p>
                
                <p>In laundering we cannot guarantee against color loss and shrinkage; or against damage to weak and tender
                fabrics. Differences in count must be reported, and this ticket presented, within 48 hours. Unless a
                list accompanied the bundle, our count must be accepted. The company's liability with respect to any
                lost or damaged article shall not exceed 10 times our charge for processing it.</p>
            </div>

        </div>
    </div>
</div>