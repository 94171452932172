import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GetStartedService } from 'src/app/core/services/get-started.service';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/core/services/dialog.service';
import { UserService } from 'src/app/core/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { HelperService } from 'src/app/core/services/helper.service';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  addressForm: FormGroup;
  @Input('basicDetails') basicDetails: any
  @Output() addressNextEvent: any = new EventEmitter<string>();
  @ViewChild('addressLine1') addressLine1:any;
  @ViewChild('ngOtpInput') ngOtpInputRef!: any;
  countryList: [];
  stateList: [];
  cityList: [];
  locationTypes = [
  ];
  authorization = false;
  otp: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: true,
    disableAutoFocus: true,
    placeholder: '',
    inputStyles: {
      'width': '65px',
      'height': '70px',
      'border': '1px solid',
      'background': 'transparent',
      'border-radius': '5px',
      'margin-right': '15px',
      'font-size': '30px',
      'font': 'normal normal 500 18px Avenir Next Medium !important',
      'margin-bottom': '10px'
    },
     inputClass: 'otp-input'
  }
  addressInfo: boolean = true;
  userId: any;
  zipCode: any;
  latitude: any;
  longitude: any;
  currentLocation: any;
  selectedLocaion: any;
  selectedCountry: any;
  addressLine1Value: any;
  storeList : any;
  constructor(
    private fb: FormBuilder,
    private getStartedService: GetStartedService,
    private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private helperService: HelperService,
    private DialogService: DialogService) { }


  ngOnInit(): void {
    this.setInputStyles();
    window.addEventListener('resize', this.setInputStyles.bind(this));
    this.getAddressTypes();
    this.getCountry();
    this.addressInitForm();
    this.getClientId();
    //this.getGoogleAddress();
    this.zipCode = window.history.state['zip'];
  }


  getGoogleAddress(){
    const baseUrl = 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCQYO7G1q-FGc16bGWJ52Cinlg9ELMSpd0&address=';

    Promise.all([
      fetch(baseUrl + this.zipCode),
    ]).then((responses) =>{
      // Get a JSON object from each of the responses
      return Promise.all(responses.map((response)=> {
        return response.json();
      }));
    }).then( (data) => {
      // Log the data to the console
      // You would do something with both sets of data here
      let addressComponents = data[0].results[0].address_components;
        let components:any = {};
        addressComponents.forEach((addressComponent) => {
          addressComponent.types.forEach((type) => {
            components[type] = addressComponent.long_name;
          });
        });
       this.setFormValues(components);
    }).catch((error)=> {
      // if there's an error, log it
    });
  }
  getCountry() {
    this.getStartedService.getCountry()
      .subscribe(
        (data: any) => {
          if (data) {
            this.countryList = data.response;
          }
        },
        (error) => {
        }
      )
  }

  getState():Promise<void> {
    let countryId = this.addressForm.value.country
      return this.getStartedService.getState(countryId).pipe(
        map(
          (data: any) => {
            if (data) {
              this.stateList = data.response;
            }
          },
        )).toPromise()
  }

  getCity():Promise<void> {
    let stateId = this.addressForm.value.state
    return this.getStartedService.getCity(stateId)
      .pipe(map(
        (data: any) => {
          if (data) {
            this.cityList = data.response;
          }
        },
      )).toPromise()
  }


  addressInitForm() {
    this.addressForm = this.fb.group({
      store: ['',Validators.required],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      country: [''],
      findAddress: [''],
      securityCode: ['']
    });
   
  }

  setFormValues(components) {
    if(components) {
      this.addressLine1Value = components.completeAddress;
      this.addressLine1.nativeElement.value = components.completeAddress;
      this.addressForm.controls['zipCode'].setValue(this.zipCode);
      this.addressForm.controls['country'].setValue(components.selectedCountry);
      this.addressForm.controls['state'].setValue(components.state);
      this.addressForm.controls['city'].setValue(components.locality);
      this.addressForm.controls['zipCode'].setValue(components.postal_code);
      if(components.selectedCountry) {
         this.getAndSetStates(components); // fecth the selected states
      }
    }
  }

  async getAndSetStates(components)  {
     await this.getState();
    let filterSelectedState = this.stateList?.filter((val:any) => {
        if (val.name.toLowerCase() == components?.administrative_area_level_1.toLowerCase()) {
          return val;
        }
    }).map((item:any) => item.rowId);
    this.addressForm.controls['state'].setValue(filterSelectedState ? filterSelectedState[0] : 0);
    if(components.selectedCountry && filterSelectedState && filterSelectedState.length > 0) {
      this.getAndSetCities(components); // fecth the selected states
   }
  };

  async getAndSetCities(components)  {
    await this.getCity();
   let filterSelecteCity = this.cityList?.filter((val:any) => {
       if (val.name.toLowerCase() == components?.locality.toLowerCase()) {
         return val;
       }
   }).map((item:any) => item.rowId);
   this.addressForm.controls['city'].setValue(filterSelecteCity ? filterSelecteCity[0] : 0);
 };

  getAddressTypes() {
    this.getStartedService.getAddressTypes()
      .subscribe(
        (data: any) => {
          if (data) {
            this.locationTypes = data.addressTypeList;
          } else {
          }
        },
        (error) => {
        });
  }
  get addressDetails() {
    return this.addressForm.controls;
  }

  onSubmit() {
    sessionStorage.clear();
    this.addressForm.markAllAsTouched();
    if (this.addressForm.valid) {
      const addressData = {
        "user": {
          "firstName": this.basicDetails.firstName,
          "lastName": this.basicDetails.lastName,
          "phoneNumber": this.basicDetails.number,
          "countryCode": this.basicDetails.dialCode,
          "emailId": this.basicDetails.email,
          "userType": "customer",
          "zipCode": this.addressDetails.zipCode.value,
          "countryId": ''
        },
        "address": {
          "addressId": "",
          "addressTypeId": "1",
          "addressLine1": this.addressLine1Value,
          "addressLine2": this.addressForm.get('addressLine2').value,
          "cityId": this.addressForm.get('city').value,
          "stateId": this.addressForm.get('state').value,
          "countryId": this.addressForm.get('country').value,
          "zipCode": this.addressDetails.zipCode.value,
          "unitNo": "",
          "buildingName": "",
          "suite": "",
          "companyName": "",
          "locationDescription": ""
        },
        "vendorId": "",
        "storeId": this.addressForm.get('store').value
    
      };
      this.getStartedService.registration(addressData).subscribe(
        (res: any) => {
          if (res.responseCode == '200') {
            this.userId = res.userId;
            let userObj = {
              "userId": res.userId
            }
            this.authService.setGuestUser(userObj);
            this.authorization = true;
            this.addressInfo = false;
          } else {
            this.toastr.error(res.message);
          }
        },
        (err) => {
          this.toastr.error(err.message);
        }
      )
    }
  }

  addressBack() {
    this.addressNextEvent.emit(-1);
  }

  otpSubmit() {
    let otpObj = {
      "userId": this.userId,
      "loginPin": this.otp,
      "loginUsing": "web",
      "deviceId": "web",
      "vendorId": ""
    }
    this.getStartedService.otpVerification(otpObj).subscribe(
      (data: any) => {
        this.authService.setToken(data);
        this.addressNextEvent.emit(1);
      },
      (err) => {
        this.toastr.error(err.message);
      }
    )
  }

  otpBack() {
    this.authorization = false;
    this.otp = '';
    this.addressInfo = true;
  }

  /*otp start */
  onOtpChange(otp) {
    this.otp = otp;
  }

  showCode() {
    let showcode: any = document.getElementById("showcode").childNodes[0];
    const showHTML = showcode.innerHTML === 'visibility_off' ? 'visibility' : 'visibility_off';
    showcode.innerHTML = showHTML;
    let childrens: any = document.getElementsByTagName('ng-otp-input')[0].childNodes[0].childNodes;
    for (let child of childrens) {
      const type = child.type === 'password' ? 'text' : 'password';
      child.type = type;
    }
  }

  resendOtp() {
    let params = {
      phoneNumber: this.basicDetails.number,
      countryCode: this.basicDetails.dialCode,
      emailId: this.basicDetails.email,
      loginUsing: this.basicDetails.email ? "email" : this.basicDetails.number ? "phone" : ''
    }
    this.authService.login(params)
      .subscribe(
        (res) => {
        },
        (error) => {
          console.error(error);
        });
  }

  

  onLocationSelected(location: Location) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  onAutocompleteSelected(result: PlaceResult, a?) {
    let components:any = this.helperService.onFormatAddress(result, this.countryList);

    console.log("components");
    console.log(components);

    this.setFormValues(components);
  }

  getClientId() {
    this.getStartedService.getClientId().subscribe(
      (res:any) => {
        let clientId = res.clientId;
       this.getAllStore(clientId);
      },
      (err) => {
      }
    )
  }
  getAllStore(clientId) {
    this.getStartedService.getAllStore(clientId).subscribe(
      (res:any) => {
        this.storeList = res.stores;
      },
      (err) => {
      }
    )
  }

  setInputStyles() {
    this.config.inputStyles.width = window.innerWidth < 768 ? '40px' : '65px';
    this.config.inputStyles.height = window.innerWidth < 768 ? '40px' : '70px';
    this.config.inputStyles['margin-right'] = window.innerWidth < 768 ? '10px' : '15px';
  }

  // Handle paste event
onOtpPaste(event: ClipboardEvent) {
  const pastedText = event.clipboardData?.getData('text');
  if (pastedText && pastedText.length === this.config.length) {
    event.preventDefault(); // Prevent the default paste behavior

    // Set the OTP value programmatically
    this.ngOtpInputRef.setValue(pastedText);
  }
}

setFocusToFirstInput() {
  // Attempt to focus the first input directly
  const otpInputs = document.querySelectorAll('.otp-input');
  if (otpInputs.length > 0) {
    (otpInputs[0] as HTMLElement).focus();
  }
}

ngAfterViewInit() {
  // Delay focus to ensure the inputs are fully initialized
  setTimeout(() => {
    this.setFocusToFirstInput();
  }, 0);
}
}
