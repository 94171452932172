<section class="home-page">
  <section class="banner" id="banner" role="main">
    <div class="container-fluid landing-page-dlogo">
      <div class="row ">
        <div class="col-sm-6 position-relative text-center">
          <img class="banner-image" src="{{dShapeIcon}}" alt="Background Image">
          <img class="mobile-image position-absolute" src="{{mobilePhone}}" alt="Mobile Icon">
        </div>
        
        
        <div class="banner-text col-sm-6">
          <div>
            <h1>
              <span class="dry-cleaning">dry cleaning & </span>
              <span class="laundry-sub">laundry subscription.</span>
            </h1>
            <h2 class="sign-up">sign up and free your laundry day.</h2>
            <div class="zip-code">
              <button class="singup-btn next-btn" mat-button routerLink="/get-started"> Don't have an account? Sign up</button>
            </div>
            <!-- <div class="row">
              <p class="get-app">Get the app.</p>
              <div class="col-12">
                <ul class="app-download d-flex">
                  <li class="">
                    <a [href]="appStoreUrl" target="_blank" rel="noopener noreferrer"><img class="appicon" src="{{iosAppIcon}}" alt="iOS App Icon"></a>
                  </li>
                  <li class="">
                    <a [href]="playStoreUrl" target="_blank" rel="noopener noreferrer"><img class="google-play" src="{{googleAppIcon}}" alt="Google Play Icon"></a>
                  </li>
                </ul>
              </div>
             
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <aside class="qr-section">
      <div *ngIf="qrCodeUrl" class="qr-code-container">
        <img [src]="qrCodeUrl" alt="QR Code" class="qr-code">
      </div>
      <p>Get App for your phone</p>
  </aside>
</section>
  <section id="how_works" class="how-we-work">
    <div class="text-center header-title">
      <span class="how-we">how we work.</span>
    </div>

    <div class="how-we-work-underline"></div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-3 order">
          <div class="work-card">
            <img src="{{orderIcon}}">
            <h2>order</h2>
            <p>place your<br>
              order quickly
              and easily <br> for our premium <br>
              laundry services.
            </p>
          </div>
        </div>
        <div class="col-sm-3 collect">
          <div class="work-card">
            <img src="{{collectIcon}}">
            <h2>collect</h2>
            <p>our convenient doorstep service <br>
              picks up your favourite clothes  <br>
              and bring them safely to <br>
              our facility.
            </p>
          </div>
        </div>
        <div class="col-sm-3 clean">
          <div class="work-card">
            <img src="{{cleanIcon}}">
            <h2>clean</h2>
            <p> we use a gentle yet powerful<br>
              cleaning process that leaves <br>
              your clothes fresh,soft, <br>
              and enhances fabric health.
            </p>
          </div>
        </div>
        <div class="col-sm-3 deliver">
          <div class="work-card">
            <img src="{{deliverIcon}}">
            <h2>deliver</h2>
            <p>we bring freshly cleaned<br>
               clothes right  to your  <br>
               doorstep, ready for your next <br>
               outfit and a refreshed wardrobe.
            </p>

          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="video" >
    <img src="{{videoIcon}}">
  </section>
  <section id="download"  class="tap-clean-driver">
    <div class="icon-phone">
      <!-- <img class="icon-phone2" src="{{phone2Icon}}"> -->
    </div>
    <div class="text-center tap-clean-title"><span class="tap">tap.</span><span class="clean">clean.</span><span
        class="deliver">deliver.</span></div>
    <div class="line"></div>
    <p class="laundry-text">laundry order at your <span>fingertips</span>.<br>
      enjoy the convenience of ordering laundry just with a tap of your smartphone.</p>
    <div class="app-link">get text / email <span>app link</span></div>
    <div class="download">
      <div class="phone-email">
        <div class="email">
          <mat-form-field color="accent" appearance="standard" class="full-width ">
            <input matInput placeholder="email address">
            <mat-icon matSuffix>arrow_forward</mat-icon>
          </mat-form-field>
        </div>
        <div class="phone">
          <div class="basic-screen mat-country-selection">
            <mat-form-field color="accent" appearance="standard" class="field-6">
              <ngx-mat-intl-tel-input [preferredCountries]="['us', 'in']" [enablePlaceholder]="true"
                [enableSearch]="true" name="phoneNumber" #phone (countryChanged)="countryChangedEvent($event)">
              </ngx-mat-intl-tel-input>
              <mat-icon matSuffix>arrow_forward</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </section>

