import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'config';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../Auth/auth.service';
import { AppConfigService } from './app-config.service';
import { DataService } from './data.service';
@Injectable({
  providedIn: 'root'
})
export class OrderService {
  baseURL: string;
  vendorId: any;
  config: any = {};
  garmentCare: any;

  constructor(private http: HttpClient, private authService: AuthService, private dataService: DataService) {
    this.baseURL = environment.apiUrl;
    this.config = config;
     this.dataService.getVendorConfig().subscribe((config)=> {
      this.vendorId = config.vendorId;
    });
  }



  //get serviceable days
  public serviceableDays(data:any) {
    let user = this.authService.getUser();
    let params = new HttpParams()
      .set('countryId', data.countryId)
      .set('zipCode', data.zipCode)
      .set('serviceableDate', data.serviceableDate)
      .set('vendorId', user.vendorId)
      .set('storeId', user.storeId);
    return this.http.get<any>(this.baseURL + '/laundry/serviceableDays', { params: params });
  }

  //get garment info/category
  public getGarmentInfo() {
    let params = new HttpParams()
      .set('countryId', this.config.countryId)
      .set('vendorId', this.vendorId);
    return this.http.get<any>(this.baseURL + '/laundry/garmentsInfo', { params: params });
  }

  //get garment care
  public getGarmentCare(userId) {
    if (!this.garmentCare) {
      let params = new HttpParams()
        .set('userId', userId);
      this.garmentCare = this.http.get<any>(this.baseURL + '/user/garmentsCareType', { params: params }).pipe(
        map(data => data),
        publishReplay(1), // this tells Rx to cache the latest emitted
        refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
      );
    }
    return this.garmentCare;
  }

  //care update
  public updateGarmentCare(body) {
    body['vendorId'] = this.vendorId;
    return this.http.put<any>(this.baseURL + '/user/garmentCare', body);
  }

  //create order
  public createOrder(body) {
    body.vendorId = this.vendorId;
    return this.http.post<any>(this.baseURL + '/order/order', body);
  }

  //update order
  public updateOrder(body) {
    return this.http.post<any>(this.baseURL + '/order/updateOrder', body);
  }

  //get order details id
  public garmentOrderDetails(body) {
    body['vendorId'] = this.vendorId;
    return this.http.post<any>(this.baseURL + '/order/orderDetails', body);
  }

  //image upload
  public addGarmentImage(body) {
    return this.http.post<any>(this.baseURL + '/order/addGarmentImage', body);
  }

  //delete garment image
  public onDeleteGarmentImage(garment) {
    let params = new HttpParams()
    .set('vendorId', this.vendorId)
    .set('storeId', garment.storeId)
    .set('orderId', garment.orderId)
    .set('userId', garment.userId)
    .set('imageIds', garment.imageIds)
    .set('orderDetailId', garment.orderDetailId);
    return this.http.delete<any>(this.baseURL + '/order/garmentImage', { params: params });
  }

  //get orders list
  public getOrdersList(pageNo:any, pageSize:any) {
    let user = this.authService.getUser();
    let params = new HttpParams()
      .set('userId', user.userId)
      .set('storeId', user.storeId)
      .set('vendorId', user.vendorId)
      .set('pageNo', pageNo)
      .set('pageSize', pageSize)
      .set('sortDir', 'desc');

    return this.http.get<any>(this.baseURL + '/order/getOrders', { params: params });
  }

  //get order 
  public getOrder(userId, orderId) {
    let user = this.authService.getUser();
    let params = new HttpParams()
      .set('userId', userId)
      .set('orderId', orderId)
      .set('vendorId', this.vendorId)
      .set('storeId', user.storeId);
    return this.http.get<any>(this.baseURL + '/order/getOrder', { params: params });
  }

  //delete order 
  public deleteOdrer(userId, orderId) {
    let user = this.authService.getUser();
    let params = new HttpParams()
      .set('userId', userId)
      .set('orderId', orderId)
      .set('storeId', user.storeId);
    return this.http.delete<any>(this.baseURL + '/order/deleteOrder', { params: params });
  }

  //get garment care detail
  getGarmentCareBarcode(barcode, orderId) {
    let params = new HttpParams()
      .set('tagId', barcode)
      .set('orderId', orderId)
      .set('vendorId', this.vendorId);
    return this.http.get<any>(this.baseURL + '/order/garmentCare', { params: params });
  }

  // get stain and damage image
  getStainDamageImage(data:any) {
    let params = new HttpParams()
    .set('orderId', data.orderId)
    .set('storeId', data.storeId)
    .set('vendorId', this.vendorId)
    .set('orderDetailId', data.orderDetailId)
    .set('imageType', data.imageType);
  return this.http.get<any>(this.baseURL + '/order/garmentImage', { params: params });
  }

  public getGarmentForCreateOrder(data) {
    let params = new HttpParams()
      .set('storeId', data.storeId)
      .set('priceListId', "0")
      .set('vendorId', this.vendorId);
    return this.http.get(this.baseURL + '/garments/garmentsInfo', { params: params });
  }

  public deleteGament(orderDetailId, orderId) {
    let user = this.authService.getUser();
    let params = new HttpParams()
       .set('orderDetailIds', orderDetailId)
       .set('orderId', orderId)
      .set('userId', user.userId)
      .set('storeId', user.storeId)
      .set('comment', '');
    return this.http.delete(this.baseURL + '/order/orderDetails', { params: params });
  } 

  public getStore() {
    let user = this.authService.getUser();
    let params = new HttpParams()
      .set('vendorId',user.vendorId);
    return this.http.get<any>(this.baseURL + '/store/?', {
      params: params
    });
  }

  //to get the order discount , total
  public orderInvoice(body:any) {
    let user = this.authService.getUser();
    body['vendorId']= user?.vendorId;
    body['storeId']= user?.storeId;
    body['userId']= user.userId;
      return this.http.post<any>(this.baseURL + '/invoice/orderInvoice', body);
  }
}
