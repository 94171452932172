<div class="container material-new-tabs" style="padding-top:150px">
  <mat-tab-group>

    <!-- Tab for À la carte Account -->
    <mat-tab label="onetime">
      <ng-container>
        <app-alacarte></app-alacarte>
      </ng-container>
    </mat-tab>

    <!-- Tab for Individual Account -->
    <mat-tab label="subscription">
      <ng-container>
        <app-individual-account [footerButton]="footerButton"></app-individual-account>
      </ng-container>
    </mat-tab>

    <!-- Tab for Corporate Account -->
    <mat-tab label="business">
      <app-corporate-account></app-corporate-account>
    </mat-tab>
  </mat-tab-group>
</div>