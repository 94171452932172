import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  currentLocation: any;
  selectedLocaion: any;
  countryList: any;
  selectedCountry: any;

  constructor() { }

  //google places address
  onFormatAddress(result: any, countryList) {
    this.currentLocation = result;
    this.selectedLocaion = result;
    if (countryList && this.currentLocation) {
      let filteredcountry:any = countryList.filter((val:any) => {
        for (let country of this.currentLocation.address_components) {
          if (country.short_name.toLowerCase() == val.code.toLowerCase()) {
            return val;
          }
        }
      });
      if (filteredcountry && filteredcountry.length) {
        this.currentLocation.country_code = filteredcountry[0].rowId;
      }
    }
    if (this.currentLocation && this.currentLocation.country_code) {
      //this.basicDetails.country.patchValue(this.currentLocation.country_code);
    }
    if (this.currentLocation && this.currentLocation.country) {
      this.selectedCountry = this.currentLocation.country;
    }

    let components:any = {};
    const addressList = [];
    result.address_components.forEach((addressComponent) => {
      addressComponent.types.forEach((type) => {
        components[type] = addressComponent.long_name;
        // Store the long name of each type in the list to avoid duplicates
        if(type?.toString().toLowerCase() !='neighborhood') {
         addressList.push(addressComponent.long_name);
        }
      });
    });

    // Create a set to remove duplicates, then convert it back to an array
    const uniqueText = Array.from(new Set(addressList));

    // Join the unique elements back into a single string with commas
    const cleanedText = uniqueText.join(", ");

    components['completeAddress'] = cleanedText; 
    components['selectedCountry'] = this.currentLocation?.country_code ? this.currentLocation.country_code : 0;


    // for (const component of result.address_components as google.maps.GeocoderAddressComponent[]) {
    //   // @ts-ignore remove once typings fixed
    //   const componentType = component.types[0];
  
    //   switch (componentType) {
    //     case "street_number": {
    //       components['address1'] = `${component.long_name}` ?? '';
    //       break;
    //     }
  
    //     case "route": {
    //       components['address1'] +=  ` ${component.long_name}` ?? '';
    //       break;
    //     }

    //     case "sublocality_level_3": {
    //       components['address1'] += `${component.long_name ?? ""}` ?? '';
    //       break;
    //     }

    //     case "sublocality_level_2": {
    //       components['address1'] += `${component.long_name ?? ""}` ?? '';
    //       break;
    //     }

    //     case "locality":
    //       components['address2'] = component.long_name ?? '';
    //       break;

    //     case "administrative_area_level_2":
    //       components['address2'] += component.long_name ?? '';
    //       break;  

    //     case "administrative_area_level_1":
    //       components['address2'] += component.long_name ?? '';
    //       break;  

    //     case "postal_code": {
    //       components['postal_code']  = `${component.long_name}` ?? '';
    //       break;
    //     }
    //     case "country":
    //       components['country'] = component.long_name ?? '';
    //       break;
    //   }
    // }
    // if (components.address1 == "") {
    //   if (components.address2 != "") {
    //     components.address2 = components.address2.substring(0, components.address2.length - 1);
    //     components.address1 = components.address1 + components.address2;
    //   } else {
    //     components.address1 = "";
    //   }
    // } else {
    //   components.address1 = components.address1.substring(0, components.address1.length - 1);
    //   components.address1 = components.address1 + ", ";
    //   if (components.address2 != "") {
    //     components.address2 = components.address2.substring(0, components.address2.length - 1);
    //     components.address1 = components.address1 + components.address2;
    //   }
    // }
    return components;
  }
}
