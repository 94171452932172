<div class="individual-account material-new-tabs" [ngClass]="{'pt-200 pl-100 pr-100 box-center': firstTimeUser}">
  <mat-tab-group  (selectedTabChange)="tabChanged($event)" #demo3Tab>
    <mat-tab label="pickup address">
      <ng-template mat-tab-label>
        <img class="pick-img" src="./assets/images/order/Icon__pick up.svg" width="80px">
        <span>pickup address</span>
      </ng-template>
      <div class="col-md-12 pt-30 col-xs-12">
        <app-add-address [tabActive]="tabActive" (addAddressEvent)="onAddressBack($event)" [firstTimeUser]="firstTimeUser"></app-add-address>
      </div>
    </mat-tab>
    <mat-tab label="delivery address">
      <ng-template mat-tab-label>
        <img class="pick-img" src="./assets/images/order/Icon__delivery icon.svg" width="80px">
        <span>delivery address</span>
      </ng-template>
      <div class="col-md-12 pt-30 col-xs-12">
        <app-add-address [tabActive]="tabActive" (addAddressEvent)="onAddressBack($event)" [firstTimeUser]="firstTimeUser"></app-add-address>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>