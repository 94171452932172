<header class="fixed-top header header-bt">
  <nav class="navbar navbar-expand-sm navbar-dark fixed-top">
    <div class="container-fluid">
      <!-- <div class="logo">
        <a class="navbar-brand p-0 logo" (click)="hideMenuFn('banner')" routerLink="/home">
          <img src="{{dryoLogoIcon}}">
        </a>
      </div>
      <div class="vendor-name" *ngIf="isVendorVisible">
        <img  src="{{vendorName}}">
      </div> -->
      <div class="header">
        <div class="logo">
          <a class="navbar-brand p-0" (click)="hideMenuFn('banner')" routerLink="/home">
            <img src="{{dryoLogoIcon}}" alt="Dryo Logo" class="logo-img">
          </a>
        </div>
        <div class="vendor-name" *ngIf="isVendorVisible">
          <img src="{{vendorName}}" alt="Vendor Name" class="vendor-img">
        </div>
      </div>
      <button
        class="navbar-toggler" (click)="toggleSidebar()" 
        type="button"><span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse show">
        <div class="collapse-content">
          <div [ngClass]="{'sidebar': true, 'show': showSidebar}">
             <!-- Close Icon -->
             <button class="close-btn" (click)="toggleSidebar()">×</button>
            <ul class="navbar-nav" *ngIf="!(getSpinnerObservable() | async) && 
     (getHeaderMenu() | orderBy : 'displayOrder' : false) as orderedHeaderMenu">
              <li class="nav-item" (click)="toggleSidebar(); hideMenuFn('how_works'); " routerLink="/home">
                <a fragment="how_works" class="nav-link">
                  {{orderedHeaderMenu[0].menuItem}}</a>
                <!-- how we work -->
              </li>
              <li class="nav-item" routerLink="/get-started" (click)="toggleSidebar()">
                <a fragment="banner" class="nav-link">
                  {{orderedHeaderMenu[1].menuItem}}</a>
                <!-- get started -->
              </li>
              <li class="nav-item" (click)="hideMenuFn('download')" routerLink="/home">
                <a fragment="download" class="nav-link">
                  {{orderedHeaderMenu[2].menuItem}}</a>
                <!-- download -->
              </li>
              <li class="nav-item" (click)="toggleSidebar(); hideMenuFn('');" routerLink="/pricing">
                <a class="nav-link">
                  {{orderedHeaderMenu[3].menuItem}}</a>
                <!-- pricing -->
              </li>
              <!-- login -->
              <li *ngIf="!isLogin" class="nav-item " (click)="toggleSidebar()">
                <a class="nav-link text-center login-link" routerLink="/login">
                  {{orderedHeaderMenu[4].menuItem}}</a>
              </li>
              <!-- download app -->
              <li *ngIf="!isLogin" class="nav-item login-button" (click)="toggleSidebar(); redirectToStore()">
                <a class="nav-link text-center login-link">
                  {{orderedHeaderMenu[5].menuItem}}</a>
              </li>
              <li class="nav-item" *ngIf="isLogin" (click)="toggleSidebar(); reDirectToCreateOrder()" >
                <a class="nav-link">
                  home</a>
              </li>
              <li *ngIf="isLogin">
                <a mat-button [matMenuTriggerFor]="menu1"><img style="width: 30px;"
                    src="../../../assets/images/garment/avatar.png" width="45px" height="auto"></a>
                <mat-menu #menu1="matMenu" class="user-profile">
                  <button mat-menu-item>
                    <img src="../../../assets/images/garment/avatar.png" width="100px" height="100px">

                  </button>
                  <button mat-menu-item (click)="toggleSidebar()" routerLink="/create-order">create order</button>
                  <button mat-menu-item (click)="toggleSidebar()" routerLink="/my-profile">my settings</button>
                  <button mat-menu-item (click)="signOut(); toggleSidebar()">sign out</button>
                </mat-menu>
              </li>
              
              <li *ngIf="isLogin" class="notification-popup nav-item mt-3">
                <a class="" mat-button>
                  <mat-icon class="material-icons " matBadge="0" matBadgeColor="warn">notifications</mat-icon>
                </a>
              </li>
              <!-- <li>
                <form #f="ngForm" [formGroup]="topHeaderForm" class="mt-3">
                  <mat-form-field appearance="legacy" class="header-country mat-country-selection">
                    <ngx-mat-intl-tel-input formControlName="userCountry" [preferredCountries]="['us']"
                      [onlyCountries]="['us','in', 'it']" [enablePlaceholder]="false" [enableSearch]="false"
                      name="userCountry" #phone>
                    </ngx-mat-intl-tel-input>
                  </mat-form-field>
                </form>
              </li> -->
              
            </ul>
          </div>
        </div>
      </div>

      <div class="overlay" [ngClass]="{'show': showSidebar}" (click)="toggleSidebar()"></div>
    </div>
  </nav>
</header>