import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/Auth/auth.guard';
import { AddAddressComponent } from '../shared/add-address/add-address.component';
import { GarmentCareComponent } from '../shared/garment-care/garment-care.component';
import { BillingMethodComponent } from './billing-method/billing-method.component';
import { CommunicationComponent } from './communication/communication.component';
import { AddGarmentComponent } from './create-order/add-garment/add-garment.component';
import { AddressListComponent } from './create-order/address-list/address-list.component';
import { CreateOrderComponent } from './create-order/create-order.component';
import { GarmentSelectionComponent } from './create-order/garment-selection/garment-selection.component';
import { StainDamageComponent } from './create-order/stain-damage/stain-damage.component';
import { DeactivateAccountComponent } from './deactivate-account/deactivate-account.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { MembershipComponent } from './membership/membership.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { NotificationComponent } from './notification/notification.component';
import { OnBoardUserComponent } from './on-board-user.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { PreferenceComponent } from './preference/preference.component';


const routes: Routes = [
  {
    path: '', component: OnBoardUserComponent, children: [
      {
        path: 'my-profile',
        component: MyProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'preference',
        component: PreferenceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'membership',
        component: MembershipComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'billing-method',
        component: BillingMethodComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'payment-history',
        component: PaymentHistoryComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'deactivate-account',
        component: DeactivateAccountComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'delete-account',
        component: DeleteAccountComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'communication',
        component: CommunicationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'notification',
        component: NotificationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'create-order',
        component: CreateOrderComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'my-orders/:workflow/:orderId',
        component: CreateOrderComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'my-orders',
        component: OrderHistoryComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'select-garment',
        component: GarmentSelectionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'select-garment/:workflow/:orderId',
        component: GarmentSelectionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'select-address/:workflow/:type',
        component: AddressListComponent,
        canActivate: [AuthGuard]
      },
      {
        path:'garment-care',
        component:GarmentCareComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'stain-damage/:id',
        component: StainDamageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'add-garment',
        component: AddGarmentComponent,
        canActivate: [AuthGuard]
      },
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboarduserRoutingModule { }
