import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PaymentService } from 'src/app/core/services/payment.service';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
  providers: [
    DatePipe,
  ],
})
export class PaymentHistoryComponent implements OnInit {
  public page: any= {
    // The number of elements in the page
    size : 0,
    // The total number of elements
    totalElements: 0,
    // The total number of pages
    totalPages: 0,
    // The current page number
    pageNumber: 0
  }
  loading: boolean = true;
  rows = [];
  columns = [{ prop: 'paymentDate' }, { name: 'paymentAmount' }, { name: 'action' }];
  isPagination: boolean;
  constructor(private paymentService: PaymentService, private datePipe: DatePipe) {
    this.page.pageNumber = 0;
    this.page.size = 10;
   }

  ngOnInit(): void {
    this.setPage({ offset: 0 });
    this.isPagination = this.rows.length >= 5;
  }
  
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.paymentService.getPaymentHistory().subscribe(
      (res:any) => {
     this.rows = res.payments;
     this.loading = false;
    },(err)=> {
      
    });
  }

  getDate(date){
    let a =  new Date(date);
    return this.datePipe.transform(a,'M/d/yy, h:mm a');
  }
}
