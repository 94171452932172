<div class="alacarteAccount material-sub-tabs alacarte-user">
  <div class="individual-account" *ngIf="garmentListAvailable">
    <mat-tab-group>
      <ng-container *ngFor="let alacarteValues of alarcateLists | keyvalue : sortByIndex">
        <ng-container *ngIf="isServiceActive(alacarteValues.key)">
      <mat-tab  [label]="alacarteValues.key">
        <div class="col-md-12 pt-30 col-xs-12">
          <div class="row">
            <ng-container *ngFor="let alacarte of alacarteValues.value; let i = index">
              <!-- *ngIf="showMore || i < maxItems" -->
              <ng-container *ngIf="alacarte.prices![0]?.isWebVisible == 1">
              <div class="col-md-4 col-sm-6 col-12" >
                  <div class="row alacarte-item">
                    <div class="col-9 garment">
                      {{ alacarte.garmentName }}
                    </div>
                    <div class="col-3 amount" *ngIf="alacarte.prices.length && alacarte.prices[0]">
                      {{ alacarte.prices[0].currencySymbol }}{{ alacarte.prices[0].price | number:'1.2-2' }}
                    </div>
                    <div class="col-3 amount" *ngIf="!alacarte.prices.length || !alacarte.prices">
                      <p>$ 0</p>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- Insert a break after every two items on small screens and every three items on larger screens -->
              <!-- <div class="w-100 d-none d-md-block" *ngIf="(i + 1) % 3 === 0"></div> Break after 3 items on large screens -->
              <!-- <div class="w-100 d-sm-none d-md-none" *ngIf="(i + 1) % 2 === 0"></div> Break after 2 items on small screens -->
            </ng-container>
        
            <!-- Show more/less button -->
            <!-- <div class="show-more" *ngIf="alacarteValues.value.length > maxItems">
              <a mat-button (click)="toggleShowMore()">
                {{ showMore ? 'Show Less' : 'Show More' }}
              </a>
            </div> -->
          </div>
        </div>
        <div class="footer-btn">
          <button class="started-btn next-btn" mat-button (click)="onSaveAlacarte()">subscribe as alacarte</button>
        </div>
      </mat-tab>
    </ng-container>
    </ng-container>
    
    </mat-tab-group>
  </div>

  <ng-container *ngIf="!garmentListAvailable">
    <div class="flex-loading">
      <p>Loading...</p>
    </div>
  </ng-container>

</div>