import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { DataService } from 'src/app/core/services/data.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { OrderHelperService } from 'src/app/core/services/order-helper.service';
import { OrderService } from 'src/app/core/services/order.service';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
  selectedOrder = '0';
  orderList: any = [];
  isPagination: boolean = true;
  @ViewChild("dt2") tableData: any;
  currentUser: any;
  activeOrders: any = [];
  completedOrders: any = [];
  activeOrder = true;
  selectedGroup: any;
  rows: number = 10;
  orderAllRecords: number = 0;
  totalRecords = 0;
  first;
  loading: boolean = true;
  orderTypes = [
    { name: 'Active orders', value: '0' },
    { name: 'Completed orders', value: '1' },
  ];

  isMobileView: boolean = window.innerWidth <= 768;
  currentPage: any;

  constructor(
    private authService: AuthService,
    private orderService: OrderService,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    private router: Router,
    private dataService: DataService
  ) { }

  scrollTop() {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
  }

  setMenuActive() {
    const currentRoute = this.router.url;

    if (currentRoute.includes('/my-orders/order/')) {
      this.dataService.setActiveMenu('create-order');
    } else if (currentRoute === '/my-orders') {
      this.dataService.setActiveMenu('my-orders');
    }
  }

  ngOnInit(): void {
    this.setMenuActive();
    this.scrollTop();
    this.isPagination = true;
    this.currentUser = this.authService.getUser();
    this.getOrders();
  }

  public getOrders(event?) {
    this.loading = true;
    const page = event ? (event?.first / event?.rows) + 1 : 1;
    const pageSize = event?.rows ?? this.rows;
    this.first = event ? (event?.first / event?.rows) + 1 : 1;
    this.orderService.getOrdersList(page, pageSize).subscribe(
      (res) => {
        this.loading = false;
        this.orderAllRecords = res.pagination?.totalRecords || 0;
        this.totalRecords = res.pagination?.totalRecords;
        let formattedOrderDetails = this.groupGarmentsBasedOnService(res);
        this.activeOrders = formattedOrderDetails.orders;
        this.activeOrders.map(order => {
          let pickupDate = order?.pickupDate ? new Date(order?.pickupDate).toUTCString() : new Date();
          let deliveryDate = new Date(order?.deliveryDate).toUTCString();
          order.pickupDate = this.datePipe.transform(pickupDate, 'yyyy-MM-dd');
          order.deliveryDate = this.datePipe.transform(deliveryDate, 'yyyy-MM-dd');
        });

      },
      (err) => {
        console.error('Error fetching orders:', err);
        this.loading = false;
      }
    );
  }

  public myOrder(rowData) {
    this.router.navigate(['/my-orders', "order", rowData.orderId]);
  }

  public cancelOrder(rowData) {
    let userId = this.currentUser.userId;
    let info = {
      message: 'Do you want to cancel order?'
    }
    this.dialogService.onConfirmationDialog(info.message)
      .afterClosed().subscribe(res => {
        if (res) {
          this.orderService.deleteOdrer(userId, rowData.orderId).subscribe(
            (res) => {
              let data = {
                message: "order cancelled successfully"
              }
              this.dialogService.successMsg(data);
              this.getOrders({ first: 0, rows: this.rows });
            },
            (err) => {
              console.error('Error cancelling order:', err);
            }
          );
        }
      });
  }

  garmentSelect() {
    this.activeOrder = this.selectedGroup == 0 ? true : false;
  }

  convertTimestamp(timestamp: number): string {
    return this.datePipe.transform(new Date(timestamp), 'yyyy-MM-dd');
  }

  onPage(event) {
    this.getOrders(event);
  }

  /* group the garment based on service type(total of price, quantity ) */
  groupGarmentsBasedOnService(response) {
    for (let order of response.orders) {
      const orderDetails = order.orderDetails || [];

      if (orderDetails && orderDetails.length > 0) {
        // Initialize the grouped data structure
        const groupedOrderDetails = {};

        // Iterate through order details
        orderDetails.forEach(detail => {
          const { garment, amount } = detail;
          const serviceInstructions = garment.serviceInstructions;

          // Iterate through each service instruction
          for (const [service, id] of Object.entries(serviceInstructions)) {
            if (!groupedOrderDetails[service]) {
              groupedOrderDetails[service] = {
                serviceInstructionId: id,
                serviceInstruction: service,
                garments: {}
              };
            }

            // Create a unique key for the garment type and ID
            const garmentKey = `${garment.garmentId}-${garment.garmentType}`;

            // Check if the garment already exists in the group
            if (!groupedOrderDetails[service].garments[garmentKey]) {
              groupedOrderDetails[service].garments[garmentKey] = {
                garmentId: garment.garmentId,
                garmentType: garment.garmentType,
                price: garment.price,
                quantity: 0,
                total: 0
              };
            }

            // Update the quantity and total
            groupedOrderDetails[service].garments[garmentKey].quantity += 1;
            groupedOrderDetails[service].garments[garmentKey].total += amount;
          }
        });
        // Format the result
        const formattedOrderDetails = Object.values(groupedOrderDetails).map((serviceGroup: any) => {
          return {
            ...serviceGroup,
            garments: Object.values(serviceGroup.garments)
          };
        });

        order.groupedOrderDetails = formattedOrderDetails;
      } else {
        order.groupedOrderDetails = [];
      }
    }
    return response;
  }


  getTotalQuantity(order: any) {
    let totalQuantity = 0;

    if (order.others?.isQuickOrder) {
      return order.others.quickOrderGarmentsCount
    } else {
      order.groupedOrderDetails.forEach(service => {
        service.garments.forEach(garment => {
          totalQuantity += garment.quantity;
        });
      });
      return totalQuantity;
    }
  }

  getTotalPrice(order: any) {
    let totalAmount = 0;

    order?.groupedOrderDetails.forEach(service => {
      service.garments.forEach(garment => {
        totalAmount += garment.total;
      });
    });

    return totalAmount?.toFixed(2);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobileView = (window.innerWidth <= 768);
  }

  onPageChange(event: any) {
    this.currentPage = event.page;
    this.rows = event.rows;
    this.first = event.first;
    let data = {
      first: this.first,
      rows: this.rows
    }
    this.getOrders(data);
  }

  getPaymentStatus(order: any): string {
    if (order?.payments?.isFullyPaid) {
      return 'Fully Paid';
    } else if (order?.payments?.isPartialyPaid) {
      return 'Partially Paid';
    } else {
      return 'Not Paid';
    }
  }
}