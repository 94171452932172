<div class="container-fluid " [ngClass]="{'display-none': !loginPage}">
  <div class="row login-page" [ngClass]="{'display-none': !loginForm}">
    <div class="col-md-12 text-center">
      <div class="main-circle">
        <div class="close-icon" *ngIf="otpContent">
          <button mat-icon-button class="close-button" (click)="onOTPClose()">
            <mat-icon class="icon" color="">close</mat-icon>
          </button>
        </div>
        <div class="row" [ngClass]="{'display-none': !loginContent}">
          <div class="col-sm-9 margin-center">
            <div class="row">
            <form #f="ngForm" [formGroup]="topHeaderForm " class="col-sm-12 login-form">
              <mat-form-field  color="accent" dense appearance="standard" class="field-6 mat-country-selection custom-input">
                <ngx-mat-intl-tel-input 
                  inputPlaceholder="phone number"
                  formControlName="phone"
                  [preferredCountries]="['us','it','in']"
                  [enablePlaceholder]="true" 
                  [enableSearch]="true" 
                  name="phone" #phone
                  (keydown.enter)="onLogin()"
                  (countryChanged)="CountryChangedEvent($event)">
                </ngx-mat-intl-tel-input>
                <mat-error *ngIf="f.form.controls['phone']?.errors?.required">phone is required</mat-error>
                <mat-error *ngIf="f.form.controls['phone']?.errors?.validatePhoneNumber">invalid phone number</mat-error>
              </mat-form-field>
              
              <!-- <div class="or">or</div>
              <mat-form-field color="accent" dense appearance="standard" class="field-6 email-address">
                <input formControlName="email" type="email"
                  matInput placeholder="email address">
                <mat-error *ngIf="f.form.controls['email']?.errors?.required">email is required</mat-error>
                <mat-error *ngIf="f.form.controls['email']?.errors?.email">email must be a valid email address</mat-error>
              </mat-form-field> -->
              <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div> 
            </form>
            <div class="col-sm-12 login-group">
              <button (click)="onLogin()" class="login-btn back-btn" mat-button>Login</button>
              <button class="singup-btn next-btn" mat-button routerLink="/get-started"> sign up</button>
            </div>
            <div class="col-12  mt-5 ">
              <a class="change-phone" (click)="onNumberChange()">change your phone number / email address</a>
            </div>
            </div>
          </div>  
        </div>
         <ng-container *ngIf="otpContent">
          <app-otp-authorization [loginResponse]="loginResponse" (otpEvent)="onOTP($event)"></app-otp-authorization>
         </ng-container>
      </div>
     
    </div>
  </div>
  <ng-container *ngIf="changeNumber">
    <app-change-number-email (changeNumberEvent)="changeNumberEvent($event)"> </app-change-number-email>
  </ng-container>
</div>

