import {Directive, ElementRef, OnInit, Output, EventEmitter} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute} from '@angular/router';
import { DataService } from '../services/data.service';

declare var google: any;

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[google-place]'
})
export class GooglePlacesDirective implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  private element: HTMLInputElement;
  messageSer: any;
  geocoder: any;
  autocomplete: any;
  currentType: any;
  private latlng: any;
  locationObje: any = {};

  constructor(elRef: ElementRef, private dataSer: DataService, private router: Router, private route: ActivatedRoute) {
    this.element = elRef.nativeElement;
  }

  addressComp(addressComponent) {
    this.locationObje = {};
    if(addressComponent === 'apiErr'){
      this.locationObje = {postal_code: 'userInput'}
      this.onSelect.emit(this.locationObje);
    }
    else{
      for (const address of addressComponent) {
        for (const type of address.types) {
          this.locationObje[type] = address.long_name;
          if (type === 'postal_code' || type === 'country') {
            this.locationObje[type] = address.short_name;
          }
        }
      }
      this.onSelect.emit(this.locationObje);
    }
    
  }

  getFormattedAddress(place) {
    if(place && place.address_components ){
      if(place.formatted_address){
        place.address_components.formatted_address = place.formatted_address;
      }
      return this.addressComp(place.address_components);
    }
    else{
    this.geocoder = new google.maps.Geocoder();
    if (place.name && !place.geometry) {
      this.geocoder.geocode({address: place.name}, (results, status) => {
        if (this.currentType === 'zip-check') {
          if(results && results[0] && results[0].address_components){
            return this.addressComp(results[0].address_components);
          }            
        }
        if (status === google.maps.GeocoderStatus.OK) {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          this.latlng = new google.maps.LatLng(lat, lng);
        }
      });
    }
  }
  }

  ngOnInit() {
    let options = {
      types: ['address'],
      //componentRestrictions: {country: 'in'}
    };
    this.messageSer = this.dataSer.getMessage()
      .subscribe((placeParameters) => {
        if (placeParameters && placeParameters.use && placeParameters.use === 'zip-check') {
          this.currentType = placeParameters.use;
          this.autocomplete = '';
          options = {
            types: ['(regions)'], // (cities)
           // componentRestrictions: {country: 'in'}
          };
          if(placeParameters && placeParameters.country){
            options = {
              types: ['(regions)'],
              //componentRestrictions: {country: placeParameters.country}
            };
          }
        } else {
          this.currentType = placeParameters.use;
          this.autocomplete = '';
          if (placeParameters && placeParameters.country) {
            const countryList = placeParameters.country.toLowerCase();
            options.types = ['geocode'];
            //options.componentRestrictions.country = countryList;
          }
        }
        if(google){
          this.autocomplete = new google.maps.places.Autocomplete(this.element, options);
          google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
            this.getFormattedAddress(this.autocomplete.getPlace());
          });
        }
        else{
          this.addressComp("apiErr");
        }
        
      });
  }
}
