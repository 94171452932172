import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval } from 'rxjs';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { GetStartedService } from 'src/app/core/services/get-started.service';
import { PaymentService } from 'src/app/core/services/payment.service';

@Component({
  selector: 'app-individual-account',
  templateUrl: './individual-account.component.html',
  styleUrls: ['./individual-account.component.scss']
})
export class IndividualAccountComponent implements OnInit {
  @Input('footerButton') footerButton:any ; 
  @Output() packNextEvent:any = new EventEmitter<string>();
  @Output() invoiceEvent:any = new EventEmitter<string>();
  individualAccount = true;
  selectedItem = 0;
  allSubscription: any;
  subscriptionInterval: any;
  subscriptionList: any;
  selectedSubscription: any;
  currentUser: any;
  selectedplan: any;

  currentStartIndex: { [key: string]: number } = {};

  tabConfigurations = [
    { label: 'quarterly', interval: 3,  data: [] },
    { label: 'half-yearly', interval: 6,  data: [] },
    { label: 'yearly', interval: 12, data: [] }
  ];
  selectedTabIndex: any = 0;
  
  constructor(
    public getStartedService: GetStartedService, 
    private authService: AuthService,
    private PaymentService: PaymentService) { }

  ngOnInit(): void {
    this.getSubscriptionsList();
     // Initialize tabConfigurations and currentStartIndex for each tab
     this.tabConfigurations.forEach(tab => {
      this.currentStartIndex[tab.label] = 0;
    });
  }

  // individual sub
  getSubscriptionsList() {
    let currentUser:any = this.authService.getUser();
    this.getStartedService.getSubscriptions(currentUser?.storeId)
      .subscribe(
        (data:any) => {
          if (data && data.plans) {
            this.allSubscription = data.plans;
            this.tabConfigurations.map(tab=> {
               tab.data = this.getIntervalValue(tab);
            })
          } else {
          }
        },
        (error) => {
    });
  }

  getIntervalValue(tab) {
    let filterdResults = this.allSubscription.filter( (item) =>  {
      return item.intervalCount === tab.interval;
    });
    return filterdResults;
  }
  
  //individual account
  onSaveSubscription() {
    this.currentUser = this.authService.getUser();
    this.selectedplan =  this.tabConfigurations[this.selectedTabIndex].data.filter((plan,index)=> index == this.selectedItem);
    const requestParams = {
      userId: this.currentUser.userId,
      planTypeId: 1 , //subscription
      subscriptionId: this.selectedplan[0].id
    };
    
    this.getStartedService.saveSubscription(requestParams)
    .subscribe(
      (data) => {
        this.subscriberInvoice()
        },
      (error) => {
      });
  }

  // invoice
  subscriberInvoice() {
    let data = {
      "userId": this.currentUser.userId,
      "invoiceAmount": this.selectedplan[0].price,
      "planId": this.selectedplan[0].id,
      "storeId": this.currentUser.storeId
    }
    this.getStartedService.subscriberInvoice(data)
    .subscribe(
      (data) => {
        this.invoiceEvent.emit(data);
        },
      (error) => {
      });
  }

  onPackBack(){
   this.packNextEvent.emit(-1);
  }

  tabClick(event: any) {
    this.selectedTabIndex = event.index;
  }

  visibleSubscriptions(tabConfig: any) {
    const startIndex = this.currentStartIndex[tabConfig.label];
    const countToShow = this.getCountToShow(); // Use helper method for dynamic count
    const endIndex = Math.min(startIndex + countToShow, tabConfig.data.length);
    return tabConfig.data.slice(startIndex, endIndex);
  }
  
  prev(tabConfig: any) {
    const countToMove = this.getCountToShow();
    if (this.currentStartIndex[tabConfig.label] > 0) {
      this.currentStartIndex[tabConfig.label] -= countToMove; 
    }
  }
  
  next(tabConfig: any) {
    const countToMove = this.getCountToShow();
    const currentIndex = this.currentStartIndex[tabConfig.label];
    if (currentIndex + countToMove < tabConfig.data.length) {
      this.currentStartIndex[tabConfig.label] += countToMove; 
    } else {
      // Move to show the last items in case they are not covered in the exact countToMove steps
      this.currentStartIndex[tabConfig.label] = tabConfig.data.length - countToMove;
    }
  }
  
  // Helper method to determine count based on screen width
  getCountToShow(): number {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      return 1; // Mobile
    } else if (screenWidth < 1200) {
      return 2; // Tablet
    } else {
      return 4; // Laptop/Desktop
    }
  }
  
  

  isNextDisabled(tabConfig: any): boolean {
    const countToShow = this.getCountToShow(); // Use helper method for dynamic count
    return this.currentStartIndex[tabConfig.label] + countToShow >= tabConfig.data.length;
  }
  
  
}
