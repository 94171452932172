import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConfigService } from './core/services/config.service';
import { DataService } from './core/services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
    // Replace these with the actual URLs of your app on the respective stores
  playStoreUrl  = 'https://play.google.com/store/apps/details?id=com.cleaners.dryo&pcampaignid=web_share'; 
  appleStoreUrl = 'https://apps.apple.com/us/app/premier-cleaners/id6566182626';
  currentUrl: any;
  spinnerStyle: string = "";
  private uiInfoSubscription: Subscription;
  loading: boolean = false;
  styles: { name: string; value: string; }[];

  isAppInstalled: boolean = false;
  showInstallBar: boolean = false;
  isAndroid: boolean = false;
  isIOS: boolean = false;

  private renderer: Renderer2;

  constructor(
    private configService: ConfigService,
    private router: Router,
    private DataService: DataService,
    rendererFactory: RendererFactory2,
    private spinner: NgxSpinnerService) {
      this.renderer = rendererFactory.createRenderer(null, null);
    this.DataService.loading.subscribe((val) => {
      this.loading = val;
      if(this.loading == true) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    })
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        this.borderTopLine();
    });
  }

  public applyThemeBasedOnHostName() {
    //setting theme class
    const hostname = window.location.hostname;
    let themeClass = '';

    // Determine the class name based on the hostname
    switch (hostname) {
      case 'premiercleanersga.com':
        themeClass = 'premier-theme';
        this.spinnerStyle = '#abf57c';
        break;
      // Add more cases for other hostnames if needed
      case 'dryo.app':
        themeClass = 'dryo-theme'; // fallback class
        this.spinnerStyle = '#80d2e5';
        break;
      default:
        themeClass = 'premier-theme'; 
        break;  
    }

    // Set the class name on the <html> element
    const htmlElement = document.documentElement;
    this.renderer.setAttribute(htmlElement, 'class', themeClass);
  }

  detectPlatform() {
    const userAgent = navigator.userAgent || navigator.vendor;

    this.isAndroid = /android/i.test(userAgent);
    this.isIOS = /iPad|iPhone|iPod/.test(userAgent) && !('MSStream' in window);
  }
  
  installApp() {
    if (this.isAndroid) {
      window.location.href =  this.playStoreUrl;
    } else if (this.isIOS) {
      window.location.href = this.appleStoreUrl;
    }
  }

  openApp() {
    const appScheme = this.isAndroid ? 'com.cleaners.premier://open' : 'com.cleaners.premier';

    window.location.href = appScheme;

    // Fallback to app store if the app isn't installed
    setTimeout(() => {
      if (this.isAndroid) {
        window.location.href = this.playStoreUrl;
      } else if (this.isIOS) {
        window.location.href = this.appleStoreUrl;
      }
    }, 2000);
  }

  checkIfAppInstalled(): Promise<boolean> {
    return new Promise((resolve) => {
      const appUrl = this.isAndroid ? 'com.cleaners.premier://open' : 'com.cleaners.premier';
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = appUrl;
  
      let appDetected = false;
  
      // Check if the app opens within a short timeout
      const timer = setTimeout(() => {
        resolve(false); // App is not installed
        document.body.removeChild(iframe);
      }, 1500);
  
      // Try attaching the iframe to attempt opening the app
      iframe.onload = () => {
        clearTimeout(timer);
        appDetected = true;
        resolve(true); // App is installed
        document.body.removeChild(iframe);
      };
  
      // Add iframe to the DOM
      document.body.appendChild(iframe);
  
      // Ensure cleanup even if no load event triggers
      window.addEventListener('blur', () => {
        if (!appDetected) {
          clearTimeout(timer);
          resolve(true); // Assume app was opened if focus leaves
          document.body.removeChild(iframe);
        }
      });
    });
  }
  

  closeBar() {
    this.showInstallBar = false;
  }
  checkMobileView(): void {
    this.showInstallBar = window.innerWidth <= 468; // Adjust the breakpoint as needed
  }

 async ngOnInit() {
    this.checkMobileView();
    this.detectPlatform();

    // Logic to determine if the app is installed (example placeholder)
   // this.isAppInstalled = await this.checkIfAppInstalled();

    // Show the install bar only for mobile platforms
    if (this.isAndroid || this.isIOS) {
      this.showInstallBar = true;
    }

    this.applyThemeBasedOnHostName();
    // this.loading = true;
    this.uiInfoSubscription = this.configService.subscribeToVendorUIInfo();
  }

  ngAfterViewInit() {
    this.borderTopLine();
  }

  ngOnDestroy() {
    this.uiInfoSubscription.unsubscribe();
  }


  borderTopLine() {
    var header = document.querySelector('header');
    if(header) {
      if (this.router.url == "/home" || this.router.url == "/" || this.router.url == "/home#download") {
        header.classList.add("header-bt");
      }
      else {
        header.classList.remove("header-bt");
      }
    }
    }

}
