import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-user-setup',
  templateUrl: './user-setup.component.html',
  styleUrls: ['./user-setup.component.scss']
})
export class UserSetupComponent implements OnInit {
  firstTimeUser: any = true;
  @Output() ProfileEvent: any = new EventEmitter<string>();
  firstUserStep = 0;
  loginPage: boolean;
  profilePage: boolean;
  constructor() { }

  ngOnInit(): void {
  }
  
  //prefrence flow
  onMove(step) {
    this.firstTimeUser = true;
    this.firstUserStep += step;
    this.scollTop();
  }

  scollTop() {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
  }


}
