import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { IndividualAccountComponent } from './individual-account/individual-account.component';
import { AlacarteComponent } from '../on-board-user/alacarte/alacarte.component';
import { CorporateAccountComponent } from '../guest/get-started/corporate-account/corporate-account.component';
import { MyProfileComponent } from '../on-board-user/my-profile/my-profile.component';
import { AddAddressComponent } from './add-address/add-address.component';
import { PreferenceComponent } from '../on-board-user/preference/preference.component';
import { GarmentCareComponent } from './garment-care/garment-care.component';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { InlineSVGModule } from 'ng-inline-svg';
 import { PrimengModule } from './primeng.module';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
const components = [
  IndividualAccountComponent, 
  AlacarteComponent, 
  CorporateAccountComponent,
  MyProfileComponent,
  AddAddressComponent,
  PreferenceComponent,
  GarmentCareComponent
]
@NgModule({
  declarations: [components, GarmentCareComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatGoogleMapsAutocompleteModule,
    PrimengModule,
    InlineSVGModule.forRoot(),
    NgxMatIntlTelInputModule,
  ],
  exports:[components, MatGoogleMapsAutocompleteModule]
})
export class SharedModule { }
