import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderService } from 'src/app/core/services/order.service';

@Component({
  selector: 'app-remove-garment',
  templateUrl: './remove-garment.component.html',
  styleUrls: ['./remove-garment.component.scss']
})
export class RemoveGarmentComponent implements OnInit {
  delayTimer: any;
  @ViewChild("dt") tableData: any;
  cities: any = [];
  selectedGarment: any = '';
  addedGarments: any = [];
  orderId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RemoveGarmentComponent>,
    private orderService:OrderService
  ) {
    this.addedGarments = data.garments;
    this.orderId = data.orderId;
  }

  ngOnInit(): void {

  }

  onRemoveGarment() {
    if (this.selectedGarment.length >= 1) {
      for (let garment of this.selectedGarment) {
        let index = this.addedGarments.findIndex(item => item.orderDetailId == garment.orderDetailId);
        this.addedGarments.splice(index, 1);
      }
      let selectedOrderDetailIds = this.selectedGarment.map(val=> val.orderDetailId);
      this.orderService.deleteGament(selectedOrderDetailIds, this.orderId).subscribe(
        (res)=>{
          let garmentCount = JSON.parse(JSON.stringify(this.addedGarments));
          this.dialogRef.close({count: garmentCount.length});
        },
        (err)=>{
          console.log('Api error')
        }
      )
    } 
  }

}
