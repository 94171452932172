<div class="billing-methods">
  <h1 class="header-title">billing methods</h1>
  <div class="header-line"></div>
  <div class="row">
    <div class="col-sm-12">
      <p class="save-method">0 saved methods</p>
      <p class="desc">you may add or manage saved payments below. at least one payment method is <br>
        required. an existing auto pay or default payment method cannot be removed from <br>
        your account until a new one has been selected.</p>
      <button class="confirm-btn next-btn mt-4" mat-button (click)="addPayment()"> add payment method</button>
      <!-- <ul class="card-list">
        <li class="card">
          <ul>
            <li><img src="./assets/images/visa1.PNG" /><span class="default"> default</span></li>
            <li class="card-name">
              <a>john joe checking *****1234</a>
            </li>
            <li>
              <a>change</a>
              <a>remove</a>
            </li>
          </ul>
        </li>
        <li class="card">
          <ul>
            <li><img src="./assets/images/visa1.PNG" /></li>
            <li class="card-name"><a>visa *****5887</a></li>
            <li>
              <a>change</a>
              <a>remove</a>
              <a>set as default</a>
            </li>
          </ul>
        </li>
      </ul> -->
    </div>
  </div>
</div>