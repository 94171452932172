import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm/confirm-dialog.component';

@Component({
  selector: 'app-has-barcode',
  templateUrl: './has-barcode.component.html',
  styleUrls: ['./has-barcode.component.scss']
})
export class HasBarcodeComponent implements OnInit {
  barCode:any;
  openBarcode = false;
  barcodeForm = this.fb.group({
    barCode: ['', Validators.required]
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<HasBarcodeComponent>,
    private elementRef: ElementRef,
    private router: Router,
    private fb: FormBuilder
    ) { }


  ngOnInit(): void {
  }

  iconClose() {
    this.dialogRef.close({data:"close"});
  }

  onNoClick() {
    this.dialogRef.close({data:"no"});
  }

  onYesClick() {
      this.openBarcode = true;
  }

  onSubmit() {
    this.barcodeForm.markAllAsTouched();
    if(this.barcodeForm.valid) {
      this.dialogRef.close({data:this.barcodeForm.value});
    }
  }

  onSkip() {
    this.dialogRef.close({data:"skip"});
  }

  onSkipAll() {
    this.dialogRef.close({data:"skipall"});
  }
  
}
