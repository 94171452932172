import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { config } from 'config';
import { AuthService } from '../Auth/auth.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const CACHE_SIZE = 1;
@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseURL: string;
  user: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.baseURL = environment.apiUrl;
  }

  // get profile
  public getUserProfile(userId?) {
    let user = this.authService.getUser();
    let params = {userId :userId, vendorId: user.vendorId }
    return this.http.get<any>(this.baseURL + '/customer/' + userId, {params : params});
  }

  // update profile
  public saveUserProfile(data?) {
    let user = this.authService.getUser();
     data['vendor']['storeId']  = user.storeId, 
     data['vendor']['vendorId'] =  user.vendorId 
    return this.http.put<any>(this.baseURL + '/customer/profile', data);
  }

  public getPreferences(userId) {
    let params = new HttpParams()
      .set('userId', userId);
    return this.http.get<any>(this.baseURL + '/order/preferences', { params: params });
  }

  // update preferences
  public updatePreferences(data?) {
    return this.http.put<any>(this.baseURL + '/order/preferences', data);
  }

  //add & update
  public addAddress(body) {
    return this.http.post<any>(this.baseURL + '/user/updateAddress', body);
  }

  //get address list
  getAddressList(userId) {
    let params = new HttpParams()
      .set('userId', userId);
    return this.http.get<any>(this.baseURL + '/user/address', { params: params }).pipe(
      map(response => response)
    );
  }

  //delete addrss
  public deleteAddress(body: any) {
    let params = new HttpParams()
      .set('userId', body.userId);
    return this.http.delete<any>(this.baseURL + '/user/address/' + body.addressId, { params: params });
  }

  // address default
  public addressDefault(body) {
    return this.http.post<any>(this.baseURL + '/user/addressDefault', body);
  }

  // deactivate account
  public deactivateAccount(body) {
    return this.http.post<any>(this.baseURL + '/customer/deactivateAccount1', body);
  }

  //get confirmation code
  public getConfirmationCode(userId) {
    let params = new HttpParams()
      .set('userId', userId);
    return this.http.get<any>(this.baseURL + '/customer/getConfirmationCode', { params: params });
  }

  //delete account
  public deleteAccount(body) {
    return this.http.post<any>(this.baseURL + '/customer/deleteAccount', body);
  }

}
