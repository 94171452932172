import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  email: any;
  phoneNumber: any;
  addressLine1: any;

  constructor() { }

  ngOnInit(): void {
    let userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
    this.email = userProfile?.emailId;
    this.phoneNumber =  userProfile?.phoneNumber;
    this.addressLine1 = userProfile.address![0]?.addressLine1;
  }

}
