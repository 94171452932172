import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
// import { ErrorDialogService } from '../error-dialog/errordialog.service';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';

@Injectable() export class AuthInterceptor implements HttpInterceptor {

    private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private requests: HttpRequest<any>[] = [];
  uploadFlag: any;
  constructor(
   private dataService: DataService,
    private injector: Injector,
    public router: Router,
  ) { }
  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.dataService.isLoading.next(this.requests.length > 0);
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    this.dataService.isLoading.next(true);
    const token: any = sessionStorage.getItem('token')
      ? sessionStorage.getItem('token') : null;
    if (token != "undefined" && token != null) {
     let UserToken:any = JSON.parse(token);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${UserToken}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.removeRequest(request);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.removeRequest(request);
        let data = {
          reason: error && error.error.message ? error.error.reason : error.error.message,
          status: error.status
        };
       this.dataService.isLoading.next(false);
        return throwError(error);
      }));
  }
}
