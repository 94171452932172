<div class="delete-account otp-authorization">
     <h1 class="header-title">enter authorization code</h1>
    <div class="text-center">
        <ng-otp-input (paste)="onOtpPaste($event)" #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
    </div>
    <div class="showCodeOtp p-3 mb-3">
        <button id="showcode" class="show-code auth-code" (click)="showCode()">
            <mat-icon matSuffix class="eye-icon">visibility_off</mat-icon>
            show code
        </button>
        <button class="resend-otp auth-resend" (click)="resendOtp()">resend otp</button>
    </div>
    <button class="login-btn back-btn otp-verify-btn" mat-button (click)="onSubmit()"> submit</button>
    <p *ngIf="errorMessage">{{errorMessage}}</p>
</div>

