import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { config } from 'config';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { OrderHelperService } from 'src/app/core/services/order-helper.service';
import { OrderService } from 'src/app/core/services/order.service';

@Component({
  selector: 'app-add-garment',
  templateUrl: './add-garment.component.html',
  styleUrls: ['./add-garment.component.scss']
})
export class AddGarmentComponent implements OnInit {
  @ViewChild('imageDrop') imageDrop;
  acceptedImageTypes = { 'image/png': true, 'image/jpeg': true, 'image/gif': true };
  @Input('counter') counter: any;
  @Input('orderId') orderId: any;
  @Input('garmentCategoryId') garmentCategoryId: any;
  @Output() garmentEvent: any = new EventEmitter<string>();
  @Output() garmentDetailId: any = new EventEmitter<string>();
  files: any = [];
  currentUser: any;
  currentFiles: any = [];
  
  constructor(
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private authService: AuthService,
    private orderHelperService: OrderHelperService
    ) { }
 
    imageUpload = this.formBuilder.group({
    selectedDress: [''],
    selectedStain: [''],
    imageInput: [''],
    comment: [''],
  });

  ngOnInit(): void {
    this.currentUser = this.authService.getUser();
  }

  allowDrop(e) {
    e.preventDefault();
  }

  //checkfiles
  checkfiles(targetfiles) {
    this.readfiles(targetfiles);
  }

  //readfiles
  readfiles(targetfiles) {
    this.files = [];
    if (targetfiles) {
      for (let file of targetfiles) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.files.push(e.target.result);
          this.currentFiles.push(targetfiles[0])
        }
        reader.readAsDataURL(file);
      }
    }
  }

  drop(e) {
    e.preventDefault();
    this.imageUpload.controls.imageInput.reset();
    this.imageDrop.innerHTML = "";
    this.checkfiles(e.dataTransfer.files);
  }

  imageChange(event) {
    this.imageDrop.innerHTML = "";
    this.checkfiles(event.target.files);
  }//imageChange

  imageUploadSubmitted() {
  }

  //get order detail id and garment image
  onSave() {
    this.orderHelperService.setAddGarmentCounter();
    //call order details to get orderDetails id
    let selectedCare:any = this.orderHelperService.selectedCare(); 
    let currentOrder:any = this.orderHelperService.getOrder();
    let garmentData = {
      orderId: this.orderId,
      amount: 0,
      counter: this.counter,
      deliveryDate: currentOrder.deliveryDate,
      deliveryTime: currentOrder.deliveryTime,
      garment: {
        comment: currentOrder.comment,
        description: "this is a description",
        machineCycle: "permanent presss",
        bleach: selectedCare.bleach.name,
        washTemperature: selectedCare.wetCleaning.name,
        ironTemperature: selectedCare.iron.name,
        dryClean: selectedCare.dryCleaning.name,
        starch: selectedCare.startch.name,
        dryerHeat: "",
        garmentId: "",
        garmentStatusId: "",
        garmentTagId: "", // garment id
        garmentCategoryId: this.garmentCategoryId,
        instruction: "this is an instruction",
        price: 0,
      }
    };
    this.orderService.garmentOrderDetails(garmentData).subscribe(
      (res) => {
        //passing orderDetails id
        this.addGarmentImage(res, garmentData);
      }
    )
   
  }

  addGarmentImage(res, garmentData) {
    let userInfo = this.authService.getUser();
    let stainDamageForm =  this.imageUpload.value;
    let type = "new"
    var formData: any = new FormData();
    formData.append("orderDetailId", res.orderDetailId);
    formData.append("userId", this.currentUser.userId);
    formData.append("imageType",'garment' );
    formData.append("typeOf", 'new');
    formData.append("comment", stainDamageForm.comment);
    formData.append("file",this.currentFiles[0]);
    formData.append("vendorId", userInfo.vendorId);
    formData.append("orderId", this.orderId);
    
    this.orderService.addGarmentImage(formData).subscribe(
      (data)=> {
        this.garmentEvent.emit(1);

       //let garmentCounter = this.orderHelperService.getAddGarmentCounter();
         //garment Detail id
         garmentData['barcode'] = '';
         garmentData['garmentLabelId'] = res.garmentLabelId;
         garmentData['orderDetailId'] = res.orderDetailId;
         this.garmentDetailId.emit(garmentData);
      },
      (err)=> {
      }
    )
  }
  
  onBack() {
    this.garmentEvent.emit(-1);
  }
}
