import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-technical-support',
  templateUrl: './technical-support.component.html',
  styleUrls: ['./technical-support.component.scss']
})
export class TechnicalSupportComponent implements OnInit {
  supportEmail: string;

  constructor(private dataService: DataService) { 
    this.dataService.getVendorConfig().subscribe(config => {
      if(config) {
        this.supportEmail = `support@${config.vendorHost}`;
      }
    });
  }

  ngOnInit(): void {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
  }

}
