import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AppImages {

    appIcons: Map<String, String>;

    static HOW_WE_WORK_ORDER: string = 'howWeWork_Order';
    private static HOW_WE_WORK_ORDER_ICON_PATH: string = 'assets/images/home/Icon__order_icon.svg';

    static HOW_WE_WORK_COLLECT: string = 'howWeWork_Collect';
    private static HOW_WE_WORK_COLLECT_ICON_PATH: string = 'assets/images/home/Icon__collect_icon.svg';

    static HOW_WE_WORK_CLEAN: string = 'howWeWork_Clean';
    private static HOW_WE_WORK_CLEAN_ICON_PATH: string = 'assets/images/home/Icon__clean_icon.svg';

    static HOW_WE_WORK_DELIVER: string = 'howWeWork_Deliver';
    private static HOW_WE_WORK_DELIVER_ICON_PATH: string = 'assets/images/home/Icon__hanger_deliver_icon.svg';
    
    private static DRYO_LOGO_ICON_PATH: string = 'assets/icon/vendor_logo.svg';
    static DRYO_LOGO: string = 'dryoLogo';

    static D_SHAPE: string = 'dShape';
    private static D_SHAPE_ICON_PATH: string = 'assets/images/home/banner-image.svg';

    static UP_ARROW: string = 'upArrow';
    private static UP_ARROW_ICON_PATH: string = 'assets/images/home/Icon__Up arrow.svg';    

    static IOS_APP: string = 'iosApp';
    private static IOS_APP_ICON_PATH: string = 'assets/icon/appicon.png';    

    static GOOGLE_APP: string = 'googleApp';
    private static GOOGLE_APP_ICON_PATH: string = 'assets/icon/playicon.png';    

    static PHONE_2: string = 'phone2';
    private static PHONE_2_ICON_PATH: string = 'assets/images/home/phone 2.png';    
   
    static VIDEO: string = 'video';
    private static VIDEO_ICON_PATH: string = 'assets/images/home/video-icon.svg';   

    static MOBILE_PHONE: string = 'phone';
    private static MOBILE_PHONE_PATH: string = 'assets/images/home/mobile_phone.png'; 
    
    static VENDOR_NAME: string = 'vendorname';
    private static VENDOR_NAME_PATH: string = 'assets/icon/vendor_name_1.jpg';

    constructor() {
        this.appIcons = new Map();
        this.appIcons.set(AppImages.HOW_WE_WORK_ORDER, AppImages.HOW_WE_WORK_ORDER_ICON_PATH);
        this.appIcons.set(AppImages.HOW_WE_WORK_COLLECT, AppImages.HOW_WE_WORK_COLLECT_ICON_PATH);
        this.appIcons.set(AppImages.HOW_WE_WORK_CLEAN, AppImages.HOW_WE_WORK_CLEAN_ICON_PATH);
        this.appIcons.set(AppImages.HOW_WE_WORK_DELIVER, AppImages.HOW_WE_WORK_DELIVER_ICON_PATH);
        this.appIcons.set(AppImages.DRYO_LOGO, AppImages.DRYO_LOGO_ICON_PATH);
        this.appIcons.set(AppImages.D_SHAPE, AppImages.D_SHAPE_ICON_PATH);
        this.appIcons.set(AppImages.UP_ARROW, AppImages.UP_ARROW_ICON_PATH);
        this.appIcons.set(AppImages.IOS_APP, AppImages.IOS_APP_ICON_PATH);
        this.appIcons.set(AppImages.GOOGLE_APP, AppImages.GOOGLE_APP_ICON_PATH);
        this.appIcons.set(AppImages.PHONE_2, AppImages.PHONE_2_ICON_PATH);
        this.appIcons.set(AppImages.VIDEO, AppImages.VIDEO_ICON_PATH);
        this.appIcons.set(AppImages.MOBILE_PHONE, AppImages.MOBILE_PHONE_PATH);
        this.appIcons.set(AppImages.VENDOR_NAME_PATH,AppImages.VENDOR_NAME_PATH);
    }

    getApplicationImagesMap() : Map<String, String>{
        return this.appIcons;
    }

    getDryoLogoImagePath() : String {
        return AppImages.DRYO_LOGO_ICON_PATH;
    }

    getVendorNamePath() : String {
        return AppImages.VENDOR_NAME_PATH;
    }

}