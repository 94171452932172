import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-billing-method',
  templateUrl: './billing-method.component.html',
  styleUrls: ['./billing-method.component.scss']
})
export class BillingMethodComponent implements OnInit {

  constructor(private  router: Router) { }

  ngOnInit(): void {
  }
  
  addPayment(){
   
  }
}
