<ng-container *ngIf="preferencesList.length">
  <div class="preference" [ngClass]="{'pt-200 pl-100 pr-100 box-center': firstTimeUser}">
    <h1 class="header-title">preference</h1>
    <div class="header-line"></div>
    <div>
      <div class="col-sm-12 pickup">
        <div class="heading">
          <div class="image-title">
            <img src="./assets/images/order/Icon__pick up.svg" width="60px">
            <span class="title">pickup</span>
          </div>
        </div>
        <ul class="days">
          
          <li *ngFor="let preference of preferencesList; let i=index" [ngClass]="{'active': preference.pickupStartTime && preference.pickupEndTime}">
            <a class="day">{{preference.dayString}}</a>
            <div class="row-flex">
              <label class="switch-yes">
                <ng-container *ngIf="preference.pickupStartTime">
                  <input class="switch-input" checked type="checkbox" #check (change)="onOffPickup(check, i)" />
                </ng-container>
                <ng-container *ngIf="!preference.pickupStartTime">
                  <input class="switch-input" type="checkbox" #check (change)="onOffPickup(check, i)" />
                </ng-container>
                <span class="switch-label" data-on="on" data-off="off"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <div class="time-design">
              <select class="form-control" name="pickupStartTime" [(ngModel)]="preference.pickupStartTime">
                <option></option>
                <option *ngFor="let time of Times" value="{{time}}">{{time}}</option>
              </select>
              
              <span>to</span>
              <select class="form-control" name="pickupEndTime" [(ngModel)]="preference.pickupEndTime">
                <option></option>
                <option *ngFor="let time of Times" [value]="time">{{time}}</option>
              </select>
            </div>
          </li>
        </ul>
        <hr class="counter-line">
      </div>
      <div class="col-sm-12 delivery">
        <div class="heading">
          <div class="image-title">
            <img src="./assets/images/order/Icon__delivery icon.svg" width="60px">
            <span class="title">delivery</span>
          </div>
        </div>
        <ul class="days">
          
          <li *ngFor="let preference of preferencesList; let j=index" [ngClass]="{'active': preference.deliveryStartTime && preference.deliveryEndTime}">
            <a class="day">{{preference.dayString}}</a>
            <div class="row-flex">
              <label class="switch-yes">
                <ng-container *ngIf="preference.deliveryStartTime">
                  <input class="switch-input" checked #check (change)="onOffDelivery(check, j)" type="checkbox" />
                </ng-container>

                <ng-container *ngIf="!preference.deliveryStartTime">
                  <input class="switch-input" #check (change)="onOffDelivery(check, j)" type="checkbox" />
                </ng-container>

                <span class="switch-label" data-on="on" data-off="off"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <div class="time-design">
              <select class="form-control" name="deliveryStartTime" [(ngModel)]="preference.deliveryStartTime">
                <option></option>
                <option *ngFor="let time of Times" value="{{time}}">{{time}}</option>
              </select>
              <span>to</span>
              <select class="form-control" name="deliveryEndTime" [(ngModel)]="preference.deliveryEndTime">
                <option></option>
                <option *ngFor="let time of Times" value="{{time}}">{{time}}</option>
              </select>
            </div>
          </li>
        </ul>
        <div class="col-sm-12 footer-btn">
          <button *ngIf="!showBackButton" class="started-btn back-btn" mat-button (click)="onBack()">back</button>
          <button class="started-btn next-btn" mat-button (click)="onSavePreference()">save</button>
        </div>
      </div>
      </div>
  </div>
</ng-container>