import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/on-board-user/dialog/confirm/confirm-dialog.component';
import { CreateOrderDialogComponent } from 'src/app/on-board-user/dialog/create-order/create-order-dialog.component';
import { HasBarcodeComponent } from 'src/app/on-board-user/dialog/has-barcode/has-barcode.component';
import { NotifyDialogComponent } from 'src/app/on-board-user/dialog/notify/notify-dialog.component';
import { RemoveGarmentComponent } from 'src/app/on-board-user/dialog/remove-garment/remove-garment.component';
import { TermsPrivacyDialogComponent } from 'src/app/on-board-user/dialog/terms-privacy/terms-privacy-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog : MatDialog ) { }

  successMsg(data:any) {
   return this.dialog.open(NotifyDialogComponent, {
      width: 'auto',
      panelClass:'success-dialog-container',
      disableClose:true,
      data: data
    });
  }

   signupCreateOrderDialog(data:any){
    return this.dialog.open(CreateOrderDialogComponent, {
      width:'auto',
      panelClass:'create-order-dialog-container',
      disableClose:true,
      data: data
    });
  }

   termsConditionDialog(msg:any) {
    return this.dialog.open(TermsPrivacyDialogComponent, {
      width:'800px',
      panelClass:'terms-dialog-container',
      disableClose:false,
      data: {
        message : msg
      },
    });
   }

   onConfirmationDialog(msg:any) {
    return this.dialog.open(ConfirmDialogComponent, {
       panelClass:'success-dialog-container',
       disableClose:false,
       data: {
         message : msg
       },
     });
   }

   hasGarmentDialog(msg:any) {
    return this.dialog.open(HasBarcodeComponent, {
      width:'',
      panelClass:'has-barcode-dialog-container',
      disableClose:false,
      data: {
        message : msg
      },
    });
   }

   removeGarmentDialog(data:any ) {
    return this.dialog.open(RemoveGarmentComponent, {
      width:'',
      panelClass:'has-barcode-dialog-container',
      disableClose:false,
      data: data
    });
   }
}
