import { Injectable, OnInit } from "@angular/core";
import { AppImages } from "src/app/core/model/app-images.model";

@Injectable({
    providedIn: 'root'
})
export class AppService implements OnInit {    

    private appImages: Map<String, String>;
    
    constructor(private appImagesModel: AppImages) {
        
        this.appImages = this.appImagesModel.getApplicationImagesMap();
    }

    ngOnInit() {

    }

    getDryoLogoImagePath() {
        return  this.appImagesModel.getDryoLogoImagePath();
    }

    getImagePath(nameOfTheIcon: string) {
        return this.appImages.get(nameOfTheIcon);
    }

    getVendorNamePath() {
        return this.appImagesModel.getVendorNamePath();
    }
  
}