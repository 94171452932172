<div class="navbar navbar-dark navbar-expand-sm s-bg">
  <button 
    class="navbar-toggler" (click)="toggleMenu()" 
    type="button"><span class="navbar-toggler-icon"></span>

  </button>

  <div class="collapse navbar-collapse"  [ngClass]="{ show: isMenuOpen }" >
    <div class="collapse-content">
      <ng-container *ngFor="let item of navItems; let i = index">
        <a (click)="onItemSelected(item, i)" [ngClass]="{'active': selectedItem === i}"
          class="menu-list-item">
          {{ item.displayName }}
          <span fxFlex *ngIf="item.children && item.children.length">
            <span fxFlex></span>
            <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
              expand_more
            </mat-icon>
          </span>
          <div *ngIf="expanded">
            <div class="expanded">
              <ng-container *ngFor="let child of item.children; let j = index">
                <div class="child-list">
                  <a [ngClass]="{'active': selectedItem === i && selectedChildItem === j}"
                    (click)="onChildSelected(child, $event, j)">
                    {{ child.displayName }}
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
        </a>
      </ng-container>
    </div>
  </div>
</div>