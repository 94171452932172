import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('phone') regNumber: any;
  separateDialCode = true;
  loginForm = true;
  changeNumber = false;
  changeNumberForm: FormGroup;
  loginContent = true;
  otpContent = false;
  firstTimeUser = false;
  firstUserStep = 0;
  selectedCountry = 'us';
  selectedUserCountry = '';

  atLeastOneFieldRequired(group: FormGroup) {
    const { phone, email } = group.value;
    return phone || email ? null : { atLeastOneRequired: true };
  }

  topHeaderForm = this.fb.group({
    phone: ['', []],
    email: ['', [Validators.email]],
  }, { validators: this.atLeastOneFieldRequired });

  // UI Colors
  private uiColorMap: Map<string, string>;
  dialCode: any;
  emailAddress: any;
  profilePage: boolean;
  loginPage = true;
  loginResponse: any;
  errorMessage: string | null = null;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private configService: ConfigService,
    private router: Router,
    private fb: FormBuilder,
    private authservice: AuthService,
    private toasterService: ToastrService,
    private dataService: DataService) {
  }

  ngAfterViewInit() {
    this.scollTop();
    this.regNumber.first.elRef.nativeElement.children[0].children[2].placeholder = "registered number";
    this.regNumber.first.elRef.nativeElement.children[0].children[2].classList.remove('mat-input-element');
    this.regNumber.first.elRef.nativeElement.children[0].children[2].focus();
  }


  ngOnInit() {
    sessionStorage.clear();
    this.scollTop();
    this.selectedUserCountry = this.selectedCountry.toUpperCase();
    this.uiColorMap = this.configService.getUIColorInfoMap();
  }

  changeCountryFn(selCountry) {
    this.selectedCountry = (selCountry.iso2).toUpperCase();
    this.selectedUserCountry = this.selectedCountry;
  }

  // on Login
  onLogin() {
   if (this.topHeaderForm.invalid) {
      this.errorMessage = 'Please provide a phone number.';
      return;
    }
    this.topHeaderForm.markAllAsTouched();
    let LoginForm = this.topHeaderForm.value;
    let phone = this.topHeaderForm.get('phone').value;
    let email = this.topHeaderForm.get('email').value;
    if(phone || email){
      let phoneComponents: any = {
        number:''
      };
      if ((LoginForm.phone)) {
        phoneComponents = {
          number: phone.substring(this.dialCode.length + 1, phone.length)
        };
      }
      let params = {
        phoneNumber: phoneComponents.number,
        countryCode: this.dialCode,
        emailId: email,
        loginUsing: email ? "email" : phone ? "phone" : ''
      }
      this.authservice.login(params)
        .subscribe(
          (data) => {
            if (data) {
              this.loginResponse = params;
              this.otpContent = true;
              this.loginContent = false;

            }
          },
          (error) => {
            this.toasterService.error('The user does not exist in our records. Please register to create an account or contact the admin team for further assistance.');
      });
  }
  }

  onNumberChange() {
    this.loginForm = false;
    this.changeNumber = true;
  }

  CountryChangedEvent(event) {
    this.dialCode = event.dialCode;
  }

  onOTPClose() {
    this.otpContent = false;
    this.loginContent = true;
    this.errorMessage = null;
  }

  onOTP(event) {
    if (event.userStatus == 'firstTimeLogin') {
      this.profilePage = true;
      this.loginPage = false;
      this.firstTimeUser = true;
      this.firstUserStep = 0;
      this.router.navigate(['/user-setup']);
    } else if (event.userStatus == 'registered') {
      this.router.navigate(['/my-profile']);
    } else {
      this.router.navigate(['/create-order']);
    }
  }

  scollTop() {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
  }

  /* first time user */
  onProfile(step) {
    this.firstUserStep += step;
    if (this.firstUserStep < 0) {
      this.loginPage = true;
      this.profilePage = false;
    }
    this.scollTop();
  }

  //prefrence flow
  onMove(step) {
    this.firstUserStep += step;
    this.scollTop();
  }

  //number change
  changeNumberEvent(event) {
    this.loginForm = true;
    this.changeNumber = false;
  }

  ngOnDestroy() {
  }
}
