import { Injectable, Injector } from '@angular/core';
import { Router } from "@angular/router";
import * as _ from "lodash";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { config } from 'config';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseURL;
  public KEY =
    "91ee127d414dd053180b*!#%^0cdcbe4UINM3RV4f74fcd75c40693f2fgh987bv45";
    vendorId:any;

  constructor(private router: Router, private dataService: DataService,
    private http: HttpClient, private injector: Injector) {
    this.baseURL = config.apiUrl;
    this.dataService.getVendorConfig().subscribe(config => {
      if(config) {
        this.vendorId = config.vendorId;
      }
    });
  }

  public login(loginData: any) {
    let params = new HttpParams()
      .set('vendorId', this.vendorId)
      .set('phoneNumber', loginData.phoneNumber)
      .set('countryCode', loginData.countryCode)
      .set('emailId', loginData.emailId);
    return this.http.get<any>(this.baseURL + '/customer/loginRequest', {
      params: params
    })
      .pipe(map(user => {

        sessionStorage.setItem("userId", JSON.stringify(user.userId));
        return user;
      }));
  }

  public validateOtp(body) {
    body['vendorId'] = this.vendorId; 
    return this.http.post<any>(this.baseURL + '/customer/login', body)
      .pipe(map(user => {
        this.setUser(user);
        return user;
      }));
  }

  public logout(body) {
    return this.http.put(this.baseURL + '/customer/logout', body);
  }

  public setUser(user) {
    let currentUser = {
      "userId": user.userId,
      "storeId": user.storeId,
      "vendorId": user.vendorId,
    }
    sessionStorage.setItem("currentUser", JSON.stringify(currentUser));
    this.setToken(user);
  }

  public getUser() {
    let userInfo = sessionStorage.getItem("currentUser");
    let currentUser = JSON.parse(userInfo);
    return currentUser;
  }

  public setToken(user) {
    sessionStorage.setItem('token', JSON.stringify(user.token));
  }

  public getToken() {
    let token = sessionStorage.getItem('token');
    if (token) {
      let UserToken = JSON.parse(token);
      return UserToken;
    }
  }

  public setGuestUser(value): any {
    let guestUser = {
      userId: value.userId
    }
    sessionStorage.setItem('guestUser', JSON.stringify(guestUser));
  }

  public getGuestUser(): any {
    let guest = sessionStorage.getItem('guestUser');
    let guestUser = ''
    if (guest) {
      guestUser = JSON.parse(guest);
    }
    return guestUser;
  }

  public isLoggedIn(): boolean {
    let token: any = sessionStorage.getItem("token");
    if (token) {
      return true;
    } else {
       this.router.navigate(['/login']);
      // return true;
    }
  }

  public setRegAddress(address) {
    sessionStorage.setItem('regAddress', JSON.stringify(address));
  }
  
  public getRegAddress() {
    let address = sessionStorage.getItem('regAddress');
    let parsedAddress = JSON.parse(address);
    return parsedAddress;
  }
  
}