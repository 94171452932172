import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatNativeDateModule } from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonToggleModule} from '@angular/material/button-toggle'
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCardModule} from '@angular/material/card';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

const material = [
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatSliderModule,
    MatCheckboxModule,
    MatTabsModule,
    MatDatepickerModule,
    MatStepperModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatDialogModule, 
    MatButtonToggleModule,
    MatMenuModule,
    MatBadgeModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatCardModule] ;

@NgModule({
    imports: [material],
    exports: [material]
    // providers: [{ 
      // provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'} 
    // }]
 })
 export class MaterialModule { }
    