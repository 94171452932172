
<div style="height:100%">
    <app-header></app-header>
    <!-- <div class="spinner__loading" *ngIf='loading'><mat-spinner [color]='spinnerStyle'></mat-spinner></div>  -->
    <!-- <div *ngIf='showSpinner() | async'><mat-spinner [color]='spinnerColor'></mat-spinner></div>   -->
    <ngx-spinner 
      bdColor = "rgba(0, 0, 0, 0.5)" 
      size = "medium" [color]="spinnerStyle"
       type = "ball-clip-rotate" [fullScreen] = "true">
      <p style="color: white" > loading... </p>
    </ngx-spinner>
    
    <router-outlet>
      
    </router-outlet> 
    <app-footer>
     
    </app-footer>
</div>

<div *ngIf="showInstallBar" class="install-bar">
  <div class="install-content">
    <span>Get the best experience with our app!</span>
    <button *ngIf="!isAppInstalled" class="next-btn" (click)="installApp()">Download App</button>
    <button *ngIf="isAppInstalled" class="next-btn" (click)="openApp()">Open App</button>
  </div>
  <button class="close-btn" (click)="closeBar()">✖</button>
</div>