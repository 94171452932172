import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor() { }
  headerTitle ='top questions';
  selectedIndex = 0;

  ngOnInit(): void {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
  }
  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  changeQuestion(title, index){
    this.headerTitle = title;
    this.selectedIndex = index;
  }
}
