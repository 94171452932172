<div class="change-number">
    <div>
      <h1 class="header-title">Change phone number / email address</h1>
      <div class="line-center">
        <span class="header-line"></span>
      </div>
    </div>
    <div class="col-sm-12">
      <form [formGroup]="changeNumberForm" class="row">
        <div class="col-sm-6">
          <mat-form-field dense appearance="legacy" class="field-6">
            <input type="text" formControlName="oldMobileNmber" matInput placeholder="old mobile number">
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field dense appearance="legacy" class="field-6">
            <input type="text" formControlName="oldEmailAddress" matInput placeholder="old email address">
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field dense appearance="legacy" class="field-6">
            <input type="text" formControlName="newMobileNumber" matInput placeholder="new mobile number">
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field dense appearance="legacy" class="field-6">
            <input type="text" formControlName="newEmailAddress" matInput placeholder="new email address">
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field dense appearance="legacy" class="field-6">
            <input type="text" formControlName="pinNumber" matInput placeholder="pin number">
          </mat-form-field>
        </div>
        <div class="col-sm-12">
          <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
        </div>
      </form>

      <div class="footer-btn">
        <button class="back-btn" (click)="onCancel()" mat-button>cancel</button>
        <button class="next-btn" (click)="onConfirm()" mat-button>confirm</button>
      </div>
    </div>
  </div>
