import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { GetStartedService } from 'src/app/core/services/get-started.service';
import { HelperService } from 'src/app/core/services/helper.service';
import { OrderService } from 'src/app/core/services/order.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit, AfterViewInit {
  @Input('firstTimeUser') firstTimeUser: any;
  @Input('tabActive') tabActive: any;
  @Input('editAddress') editAddress:any; // from address list
  @ViewChild('addressLine1') addressLine1:any;
  @Output() addAddressEvent: any = new EventEmitter<string>();
  addressForm: FormGroup;
  regFlow: false;
  locationTypes: any;
  countryList: any;
  stateList: any;
  cityList: any;
  currentUser: any;
  latitude: any;
  longitude: any;
  currentLocation: any;
  selectedLocaion: any;
  selectedCountry: any;
  updateAddress: any;
  addressLine1Value: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private AuthService: AuthService,
    private getStartedService: GetStartedService,
    private orderService: OrderService,
    private helperService: HelperService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.addressInitForm();
    this.getAddressTypes();
    this.getCountry();
    this.regFlow = window.history.state['regFlow'];
    let userAddress = window.history.state['userDetails']; // billing addess
    this.updateAddress = userAddress && userAddress.address[0];
    if(userAddress) {
      this.currentUser = this.AuthService.getGuestUser();
    } else {
      this.currentUser = this.AuthService.getUser();
    }
    if(this.updateAddress) { 
      this.addressForm.patchValue(this.updateAddress);
      this.addressForm.controls['addressType'].setValue(this.updateAddress.addressTypeId);
      this.addressLine1.nativeElement.value = this.updateAddress.addressLine1;
    } else if(this.editAddress) {
      this.addressForm.patchValue(this.editAddress);
      this.addressForm.controls['addressType'].setValue(this.editAddress.addressTypeId);
    }
  }

  ngAfterViewInit() {
    if(this.updateAddress) { 
      this.addressLine1.nativeElement.value = this.updateAddress.addressLine1 ? this.updateAddress.addressLine1 : '';
    } else if(this.editAddress) {
      this.addressLine1.nativeElement.value = this.editAddress.addressLine1 ? this.editAddress.addressLine1 : '';
    }
  }

  addressInitForm() {
    this.addressForm = this.fb.group({
      addressType: [''],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      country: [''],
      findAddress: [''],
      securityCode: ['']
    })
  }

  onBack() {
    // loggedIn first 
    if (this.firstTimeUser) {
      this.addAddressEvent.emit(-1);
    } else {
      this.onDefault();
    }
  }
  onSave() {
    let editAddressId;
    editAddressId = this.editAddress ? this.editAddress.id : this.updateAddress ? this.updateAddress.id : '';
    let addressId =  editAddressId ? editAddressId : ''; 
    let params = {
      "addressId": addressId,
      "userId": this.currentUser.userId,
      "addressTypeId": this.addressForm.value.addressType,
      "addressLine1": this.addressLine1Value,
      "addressLine2": this.addressForm.value.addressLine2,
      "cityId": this.addressForm.value.city,
      "stateId": this.addressForm.value.state,
      "countryId": this.addressForm.value.country,
      "zipCode": this.addressForm.value.zipCode,
      "unitNo": "",
      "buildingName": "",
      "suite": "5",
      "companyName": "dryo",
      "locationDescription": this.addressForm.value.findAddress
    }
    if(this.tabActive === 0) {
      params['pickupDefault'] = true;
    } else if(this.tabActive === 1) {
      params['deliveryDefault'] = true;
    }
    this.userService.addAddress(params).subscribe(
      (res) => {
        // loggedIn first
        if (this.firstTimeUser && this.tabActive == 1) {
          this.router.navigate(['create-order']);
        } else {
          this.onDefault();
        }
      },
      (err) => {  
      });
  }

  onDefault() {
    // register flow
    if (this.regFlow) {
      this.router.navigate(['get-started'], { state: { paymentScreen: true } });
    } else {
      //back to address list
      this.addAddressEvent.emit(true);
    }
  }

  getAddressTypes() {
    this.getStartedService.getAddressTypes()
      .subscribe(
        (data: any) => {
          if (data) {
            this.locationTypes = data.addressTypeList;
          } else {
          }
        },
        (error) => {
        });
  }


  getCountry() {
    this.getStartedService.getCountry()
      .subscribe(
        (data: any) => {
          if (data) {
            this.countryList = data.response;
          }
        },
        (error) => {
        }
      )
  }

  getState(country?): Promise<void> {
    let countryId = country ? country : this.addressForm.value.country
   return this.getStartedService.getState(countryId).pipe(
      map(
        (data: any) => {
          if (data) {
            this.stateList = data.response;
          }
        },
      )).toPromise()
  }

  getCity(state?): Promise<void> {
    let stateId = state ?  state : this.addressForm.value.state
    return this.getStartedService.getCity(stateId)
      .pipe(map(
        (data: any) => {
          if (data) {
            this.cityList = data.response;
          }
        },
      )).toPromise()
  }

  onLocationSelected(location: any) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  async getAndSetCities(components)  {
    await this.getCity();
   let filterSelecteCity = this.cityList?.filter((val:any) => {
       if (val.name.toLowerCase() == components?.locality.toLowerCase()) {
         return val;
       }
   }).map((item:any) => item.rowId);
   this.addressForm.controls['city'].setValue(filterSelecteCity ? filterSelecteCity[0] : 0);
 };

 
  async getAndSetStates(components)  {
    await this.getState();
   let filterSelectedState = this.stateList?.filter((val:any) => {
       if (val.name.toLowerCase() == components?.administrative_area_level_1.toLowerCase()) {
         return val;
       }
   }).map((item:any) => item.rowId);
   this.addressForm.controls['state'].setValue(filterSelectedState ? filterSelectedState[0] : 0);
   if(components.selectedCountry && filterSelectedState && filterSelectedState.length > 0) {
     this.getAndSetCities(components); // fecth the selected states
  }
 };

  onAutocompleteSelected(result: any, a?) {
    let components:any = this.helperService.onFormatAddress(result, this.countryList);
    if(components) {
      this.addressLine1Value = components.completeAddress;
      this.addressLine1.nativeElement.value = components.completeAddress;
      this.addressForm.controls['country'].setValue(components.selectedCountry);
      this.addressForm.controls['state'].setValue(components.state);
      this.addressForm.controls['city'].setValue(components.locality);
      this.addressForm.controls['zipCode'].setValue(components.postal_code);
    }
    if(components.selectedCountry) {
      this.getAndSetStates(components); // fecth the selected states
   }
  }
}
