
<ng-container *ngIf="createOrderScreen">
  <div class="create-order material-new-tabs ">
    <mat-tab-group>
      

      <mat-tab label="Delivery">
        <div class="delivery-tab mt-20">
          <div  class="col-sm-12">
            <div class="row info">
              <div class="col-sm-6 pickup">
                <div class="heading">
                  <div class="image-title">
                    <img src="./assets/images/order/Icon__pick up.svg" width="60px">
                    <span class="title">pickup</span>
                  </div>
                  <button (click)="selectAddress('pickup')">
                    <span class="material-icons material-icons-outlined edit-location">
                      edit_location
                    </span>
                  </button>
                </div>
                <div class="address" *ngIf="pickupDefaultAddress && pickupDefaultAddress.addressLine1">
                  <ul>
                    <li>{{pickupDefaultAddress.addressLine1}}, {{pickupDefaultAddress.addressLine2}}</li>
                    <!-- <li>{{pickupDefaultAddress.city}}, {{pickupDefaultAddress.state}}</li> -->
                    <!-- <li>{{pickupDefaultAddress.country}} - {{pickupDefaultAddress.zipCode}}</li> -->
                  </ul>
                </div>
                <div class="address" *ngIf="!pickupDefaultAddress && defaultAddress.addressLine1">
                  <ul>
                    <li>{{defaultAddress.addressLine1}}, {{defaultAddress.addressLine2}}</li>
                    <!-- <li>{{defaultAddress.city}}, {{defaultAddress.state}}</li> -->
                    <!-- <li>{{defaultAddress.country}} - {{defaultAddress.zipCode}}</li> -->
                  </ul>
                </div>
                <div class="dateTime">
                  <div class="time">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <input (focus)="picker.open()" [(ngModel)]="pickupDate" [min]="minDate" (dateChange)="pickupDateChange($event)"
                      [max]="maxDate" [matDatepicker]="picker">
                  </div>
                  <div class="date">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                      <span matPrefix> <img src="./assets/images/order/Icon__clock.svg"></span>
                      <span>{{pickupServiceTime}}</span>
                    </button>

                    <mat-menu #menu="matMenu">
                      <ng-container *ngFor="let serviceableDateTime of pickupServiceableDateList">
                        <button (click)="pickupTime(serviceableTime)" mat-menu-item
                          *ngFor="let serviceableTime of serviceableDateTime.serviceAvailability">
                          {{serviceableTime.startTime}} - {{serviceableTime.endTime}}
                        </button>
                      </ng-container>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 delivery">
                <div class="heading">
                  <div class="image-title">
                    <img src="./assets/images/order/Icon__delivery icon.svg" width="60px">
                    <span class="title">delivery</span>
                  </div>
                  <button (click)="selectAddress('delivery')">
                    <span class="material-icons material-icons-outlined edit-location">
                      edit_location
                    </span>

                  </button>
                </div>
                <div class="address" *ngIf="deliveryDefaultAddress && deliveryDefaultAddress.addressLine1">
                  <ul>
                    <li>{{deliveryDefaultAddress.addressLine1}}, {{deliveryDefaultAddress.addressLine2}}</li>
                    <!-- <li>{{deliveryDefaultAddress.city}}, {{deliveryDefaultAddress.state}}</li> -->
                    <!-- <li>{{deliveryDefaultAddress.country}} - {{deliveryDefaultAddress.zipCode}}</li> -->
                  </ul>
                </div>
                <div class="address" *ngIf="!deliveryDefaultAddress && defaultAddress.addressLine1">
                  <ul>
                    <li>{{defaultAddress.addressLine1}}, {{defaultAddress.addressLine2}}</li>
                    <!-- <li>{{defaultAddress.city}}, {{defaultAddress.state}}</li> -->
                    <!-- <li>{{defaultAddress.country}} - {{defaultAddress.zipCode}}</li> -->
                  </ul>
                </div>
                <div class="dateTime">
                  <div class="time">
                    <mat-datepicker-toggle matSuffix [for]="picker1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                    <input (focus)="picker1.open()" [min]="minDeliveryDate" [(ngModel)]="deliveryDate" (dateChange)="deliveryDateChange($event)"
                      [max]="maxDate" [matDatepicker]="picker1">
                  </div>
                  <div class="date">

                    <button mat-icon-button [matMenuTriggerFor]="menu1">
                      <span matPrefix> <img src="./assets/images/order/Icon__clock.svg"></span>
                      <span>{{deliveryServiceTime}}</span>
                    </button>

                    <mat-menu #menu1="matMenu">
                      <ng-container *ngFor="let serviceableDateTime of deliveryServiceableDateList">
                        <button (click)="deliveryTime(serviceableTime)" mat-menu-item
                          *ngFor="let serviceableTime of serviceableDateTime.serviceAvailability">
                          {{serviceableTime.startTime}} - {{serviceableTime.endTime}}
                        </button>
                      </ng-container>
                    </mat-menu>

                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-20">
              <div class="col-sm-6">
                <div class="garments-counter">
                  <label for="garments">number of garments</label>
                  <div class="counter">
                    <button mat-icon-button class="rounded-button" (click)="decreaseGarments()">&#x2212;</button>
                    <input
                      id="garments"
                      [(ngModel)]="garmentsCount"
                      type="number"
                      class="garments-input"
                      min="0"
                      placeholder="number of garments"
                    />
                    <button  mat-icon-button class="rounded-button" (click)="increaseGarments()">&#x2b;</button>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 mt-20">
                <button class="single-btn" (click)="addGarmentItem()" mat-button> &#x2b; add garment details</button>
              </div>
            </div>
            
            <div class="row mt-20">
              <div class="col-sm-12">
                <textarea  [(ngModel)]="orderNotes" class="custom-textbox special-note"
                  placeholder="special note"></textarea>
              </div>
            </div>
            <div class="row ">
              <!-- <div class="col-sm-6">
                <div *ngIf="garmentsCount == 0" class="add-more-item" (click)="addGarmentItem()">
                  <div class="add-image">
                    <img src="./assets/images/order/Icon__Add items.svg" width="46px">
                  </div>
                  <div class="add-text"><span *ngIf="!isEditOrder">click here to add</span> <span *ngIf="isEditOrder">view</span>
                    garment</div>
                </div>
              </div> -->
              <div class="col-sm-12">
                <div class="button-group pt-4">
                 
                  <button *ngIf="!isEditOrder" class="create-order-btn new-btn" (click)="createOrder('delivery')" mat-button>create 
                    order</button>
                  <button *ngIf="isEditOrder" class="create-order-btn new-btn" (click)="createUpdateOrder()" mat-button>update
                    order</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </mat-tab>
      <mat-tab label="In-Store">
        <div class="pickup-tab ">
          <div class="date-time-container">
              <div class="row info">
                <div class="col-sm-6">
                  <mat-form-field dense  appearance="legacy" class="form-field half-width">
                    <mat-label>Date</mat-label>
                    <input [(ngModel)]="inStorePickupDate" [min]="minDate" (focus)="picker3.open()"  matInput [matDatepicker]="picker3" />
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class=" col-sm-6 dateTime">
                  <div class="date">
                    <button mat-icon-button [matMenuTriggerFor]="menu3">
                      <span matPrefix> <img src="./assets/images/order/Icon__clock.svg"></span>
                      <span>{{inStoreDeliveryServiceTime}}</span>
                    </button>
                    <mat-menu #menu3="matMenu">
                      <ng-container *ngFor="let serviceableDateTime of deliveryServiceableDateList">
                        <button (click)="inStoreDeliveryTime(serviceableTime)" mat-menu-item
                          *ngFor="let serviceableTime of serviceableDateTime.serviceAvailability">
                          {{serviceableTime.startTime}} - {{serviceableTime.endTime}}
                        </button>
                      </ng-container>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <div class="row mt-10">
                <div class="col-sm-6">
                 
                  <div class="garments-counter">
                    <label for="garments">number of garments</label>
                    <div class="counter">
                      <button mat-icon-button class="rounded-button" (click)="decreaseGarments()">&#x2212;</button>
                      <input
                        id="garments"
                        [(ngModel)]="garmentsCount"
                        type="number"
                        class="garments-input"
                        min="0"
                        placeholder="number of garments"
                      />
                      <button mat-icon-button class="rounded-button" (click)="increaseGarments()">&#x2b;</button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 mt-20">
                  <button class="single-btn" (click)="addGarmentItem()" mat-button> &#x2b; add garment details</button>
                </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 mt-20">
                   <textarea [(ngModel)]="orderNotes" class="custom-textbox special-note"
                   placeholder="special note"></textarea>
                  </div>
                </div>
          </div>
          <div class="button-group mt-20">
             
              <button class="create-order-btn new-btn" (click)="createOrder('in-store-pickup')" mat-button>create order</button>
          </div>
        </div>

      </mat-tab>
    </mat-tab-group>
  </div>
</ng-container>


<ng-container *ngIf="garmentSelectionScreen">
  <!-- <ng-container *ngIf="!createOrderComponent  && isGarmentList()"> -->
    <app-garment-selection [orderId]="orderId" [currentTab]="currentTab" [garmentList]="garmentList"
      [editOrderData]="editOrderData" (onCreateUpdateAction)="createUpdateOrder()"
      (selectGarmentBack)="selectGarmentBack($event)" [isEditOrder]="isEditOrder" [pickupAddress]="pickupDefaultAddress"
      [deliveryAddress]="deliveryDefaultAddress" [defaultAddress]="defaultAddress"
      [userSubscriptionInfo]="userSubscriptionInfo" (payLater)="onPayLater()"
      [serviceVisibility]="serviceVisibility"
      >
    </app-garment-selection>
  <!-- </ng-container> -->
</ng-container>

<!--brain tree payment-->
<ng-container *ngIf="isBrainTreePaymentScreen">
  <app-braintree-payment [paymentPayload]="paymentPayload" (paymentStatus)="onPaymentStatus($event)">
  </app-braintree-payment>
</ng-container>