import { Component, OnInit, Output, EventEmitter, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-otp-authorization',
  templateUrl: './otp-authorization.component.html',
  styleUrls: ['./otp-authorization.component.scss']
})
export class OtpAuthorizationComponent implements OnInit, AfterViewInit {
  @Output() otpEvent: any = new EventEmitter<any>();
  @Input('loginResponse') loginRequest: any;
  @ViewChild('ngOtpInput') ngOtpInputRef!: any;
  otp: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: true,
    disableAutoFocus: true,
    placeholder: '',
    value: '656565',
    inputStyles: {
      'width': '40px',
      'height': '40px',
      'border': '1px solid ',
      'background': 'transparent',
      'color': '#091a38',
      'border-radius': '5px',
      'margin-right': '10px',
      'font-size': '20px',
      'font': 'normal normal 500 18px Avenir Next Medium !important',
      'margin-bottom':'10px',
    },
    inputClass: 'otp-input'  // Optional: Custom styling class for OTP inputs
  }
  otpParams: any = {
    "userId": "",
    "loginPin": "",
    "loginUsing": "phone",
    "deviceId": "web"
  }
  profilePage: boolean;
  firstTimeUser: boolean;
  firstUserStep: number;
  loginPage: boolean;
  currentUser: any;
  errorMessage: any;
  constructor(
    private dataService: DataService,
    private router: Router,
    private authService: AuthService,
    private toasterService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.setInputStyles();
    window.addEventListener('resize', this.setInputStyles.bind(this));
  }
  onOtpChange(otp) {
    this.otp = otp;
  }
  showCode() {
    let showcode: any = document.getElementById("showcode").childNodes[0];
    const showHTML = showcode.innerHTML === 'visibility_off' ? 'visibility' : 'visibility_off';
    showcode.innerHTML = showHTML;

    let childrens: any = document.getElementsByTagName('ng-otp-input')[0].childNodes[0].childNodes;
    for (let child of childrens) {
      const type = child.type === 'password' ? 'text' : 'password';
      child.type = type;
    }
  }
  
  resendOtp() {
    sessionStorage.clear();
    this.authService.login(this.loginRequest)
      .subscribe(
        (res) => {
          this.toasterService.success("We've just sent a new OTP to your mobile number");
        },
        (error) => {
        });
  }

onSubmit() {
  let userId = JSON.parse(sessionStorage.getItem('userId'));
  let params = {
    'userId': userId,
    'loginPin': this.otp,
    'loginUsing': this.otpParams.loginUsing,
    'deviceId': this.otpParams.deviceId,
  }
  this.authService.validateOtp(params).subscribe(
    (res) => {
      this.authService.setToken(res);
      this.otpEvent.emit({userStatus:res.userStatus});
      this.dataService.updateLogin(true);
    },
    (err) => {
      this.toasterService.error('The OTP you entered is invalid. Please check the code and try again.');
    }
  )
}

setInputStyles() {
  this.config.inputStyles.width = window.innerWidth < 768 ? '30px' : '40px';
  this.config.inputStyles.height = window.innerWidth < 768 ? '30px' : '40px';
  this.config.inputStyles['margin-right'] = window.innerWidth < 768 ? '8px' : '10px';
}

// Handle paste event
onOtpPaste(event: ClipboardEvent) {
  const pastedText = event.clipboardData?.getData('text');
  if (pastedText && pastedText.length === this.config.length) {
    event.preventDefault(); // Prevent the default paste behavior

    // Set the OTP value programmatically
    this.ngOtpInputRef.setValue(pastedText);
  }
}

setFocusToFirstInput() {
  // Attempt to focus the first input directly
  const otpInputs = document.querySelectorAll('.otp-input');
  if (otpInputs.length > 0) {
    (otpInputs[0] as HTMLElement).focus();
  }
}

ngAfterViewInit() {
  // Delay focus to ensure the inputs are fully initialized
  setTimeout(() => {
    this.setFocusToFirstInput();
  }, 0);
}
}

