export const OFFLINE_CONFIGS: { [host: string]: any } = {
    'premiercleanersga.com': {
      theme: {
        colors: [
          { name: 'primaryColor', colorCode: '#abf57c' },
          { name: 'secondaryColor', colorCode: '#00352b' },
          { name: 'secondaryColorLight', colorCode: '#fff' }
        ],
        fontFamily: 'Avenir Next'
      },
      "profile": {
        "vendorId": 139,
        "vendorName": "Premier Cleaners",
        "vendorLogo": "https://internalws.dryo.app/dryows/images/logos/139/b86a18fd1e6d4dd625b630d59df10746.png",
        "vendorBio": "This is a sample text for vendor bio",
        "vendorRealm": "premiercleaners749",
        "vendorHost": "premiercleanersga.com",
        "userId": 749,
        "vendorStatusId": 1,
        "storeId": 146
    }
    },
    'dryocleaners.com': {
      theme: {
        colors: [
          { name: 'primaryColor', colorCode: '#80d2e5' },
          { name: 'secondaryColor', colorCode: '#091a38' },
          { name: 'secondaryColorLight', colorCode: '#fff' }
        ],
        fontFamily: 'Avenir Next'
      },
      profile: {
        vendorId: 2,
        vendorName: 'Dryo Cleaners',
        vendorLogo: '/assets/logos/host2-logo.png',
        vendorBio: 'Default profile for Host 2',
        vendorRealm: 'host2realm',
        vendorHost: 'dryocleaners.com',
        vendorStatusId: 2
      }
    }
  };
