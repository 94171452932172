import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {
  otp: any;
  @ViewChild('ngOtpInput') ngOtpInputRef!: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: true,
    disableAutoFocus: true,
    placeholder: '',
    value: '6',
    inputStyles: {
      'width': '70px',
      'height': '89px',
      'border': '1px solid ',
      'background': 'transparent',
      'border-radius': '0',
      'margin-right': '30px',
      'font-size': '60px',
      'margin-bottom': '20px',
    },
     inputClass: 'otp-input'
  }
  currentUser: any;
  constructor(
    private dialogService: DialogService,
    private userService: UserService,
    private authService: AuthService,
    private toasterService: ToastrService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.otp = '';
    this.currentUser = this.authService.getUser();
    this.setInputStyles();
    window.addEventListener('resize', this.setInputStyles.bind(this));
  }

  onOtpChange(otp) {
    this.otp = otp;
  }
  showCode() {
    let showcode: any = document.getElementById("showcode").childNodes[0];
    const showHTML = showcode.innerHTML === 'visibility_off' ? 'visibility' : 'visibility_off';
    showcode.innerHTML = showHTML;

    let childrens: any = document.getElementsByTagName('ng-otp-input')[0].childNodes[0].childNodes;
    for (let child of childrens) {
      const type = child.type === 'password' ? 'text' : 'password';
      child.type = type;
    }
  }
  resendOtp() { }
  
  //delete account
  onConfirm() {
    this.dialogService.onConfirmationDialog("Are you sure to delete this account ?")
      .afterClosed().subscribe(res => {
        if (res) {
          this.deleetAccount();
        }
      });
  }

  deleetAccount() {
    let body = {
        "userId": this.currentUser.userId,
        "code": this.otp
    }
    this.userService.deleteAccount(body).subscribe(
      (res)=> {
        this.toasterService.success(res.message);
        this.router.navigate(['/login']);
      },
      (err)=> {
      }
    )
  }

  //get confirmation code
  getConfirmationCode() {
    this.userService.getConfirmationCode(this.currentUser.userId).subscribe(
      (res)=> {
        this.toasterService.success('An OTP has been sent to your mobile number. Please enter it to proceed.');
      },
      (err)=> {
      }
    )
  }

  setInputStyles() {
    this.config.inputStyles.width = window.innerWidth < 768 ? '50px' : '70px';
    this.config.inputStyles.height = window.innerWidth < 768 ? '50px' : '89px';
    this.config.inputStyles['margin-right'] = window.innerWidth < 768 ? '20px' : '30px';
  }

  // Handle paste event
onOtpPaste(event: ClipboardEvent) {
  const pastedText = event.clipboardData?.getData('text');
  if (pastedText && pastedText.length === this.config.length) {
    event.preventDefault(); // Prevent the default paste behavior

    // Set the OTP value programmatically
    this.ngOtpInputRef.setValue(pastedText);
  }
}

setFocusToFirstInput() {
  // Attempt to focus the first input directly
  const otpInputs = document.querySelectorAll('.otp-input');
  if (otpInputs.length > 0) {
    (otpInputs[0] as HTMLElement).focus();
  }
}

ngAfterViewInit() {
  // Delay focus to ensure the inputs are fully initialized
  setTimeout(() => {
    this.setFocusToFirstInput();
  }, 0);
}
}
