<div class="add-new-address" [ngClass]="{'pt-200 pl-100 pr-100 register-flow': regFlow}">
  <div class="row" *ngIf="!firstTimeUser">
    <div class="col-sm-12">
        <h1 class="headerTitle" *ngIf="!updateAddress">add new address</h1>
        <h1 class="headerTitle" *ngIf="updateAddress">update address</h1>
        <div class="headerLine"></div>
     </div>
   </div>     
  <form [formGroup]="addressForm" class="add-address">
      <div class="row">

        <div class="col-md-12">
          <mat-form-field dense appearance="legacy" class="field-12" >
            <mat-label>search your address here</mat-label>
            <input matGoogleMapsAutocomplete #addressLine1
            country="us"
            (onAutocompleteSelected)="onAutocompleteSelected($event)"
            (onLocationSelected)="onLocationSelected($event)"
             matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6 pr-40">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label>location type</mat-label>
            <mat-select name="locationType" formControlName="addressType" >
              <mat-option *ngFor="let location of locationTypes" [value]="location.id">
               {{location.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6 pr-40">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label>select country</mat-label>
            <mat-select name="countryId" formControlName="country" disableOptionCentering (selectionChange)="getState()">
              <mat-option *ngFor="let country of countryList" [value]="country.rowId">
               {{country.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-6 pl-40">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label>select state</mat-label>
            <mat-select name="stateId" formControlName="state" disableOptionCentering (selectionChange)="getCity()">
              <mat-option *ngFor="let state of stateList" [value]="state.rowId">
               {{state.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-6 pr-40">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label>select city</mat-label>
             <mat-select name="cityId" formControlName="city" disableOptionCentering>
              <mat-option *ngFor="let city of cityList" [value]="city.rowId">
               {{city.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6 pl-40">
          <mat-form-field dense appearance="legacy" class="field-6 ">
            <mat-label>zip code</mat-label>
            <input formControlName="zipCode" type="text" matInput placeholder="">
          </mat-form-field>
        </div>
      </div>
      <div class="footer-btn">
        <button class="started-btn back-btn" (click)="onBack()" mat-button>back</button>
        <button class="started-btn next-btn" (click)="onSave()"  mat-button >save</button>
      </div>
    </form>
</div>
