<div class="dialog-container">
    <div class="close-icon">
        <button mat-icon-button class="close-button" [mat-dialog-close]="false">
            <mat-icon class="icon" color="warn">close</mat-icon>
        </button>
    </div>
    <div class="content">
        <div mat-dialog-content>
            <p class="content-span barcode-text">{{data.message}}</p>
        </div>
        <div mat-dialog-actions>
            <div class="btn-container button-group">
                <div class="display-inline">
                    <button type="button" class="btn-fill btn-no" [mat-dialog-close]="false">no</button>
                </div>
                <div class="display-inline">
                    <button type="button" class="btn-fill btn-yes" [mat-dialog-close]="true">yes</button>
                </div>
            </div>
        </div>
    </div>
</div>