import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { config } from 'config';
import { DialogService } from 'src/app/core/services/dialog.service';
import { OrderHelperService } from 'src/app/core/services/order-helper.service';
import { OrderService } from 'src/app/core/services/order.service';
import { AuthService } from 'src/app/core/Auth/auth.service';

@Component({
  selector: 'app-garment-selection',
  templateUrl: './garment-selection.component.html',
  styleUrls: ['./garment-selection.component.scss'],
})
export class GarmentSelectionComponent implements OnInit, OnChanges {
  @Input('orderId') orderId: any;
  @Input('garmentList') garmentList: any;
  @Input('currentTab') currentTab: any;
  @Input('editOrderData') editOrderData: any;
  @Input('isEditOrder') isEditOrder: any
  @Input('pickupAddress') pickupAddress: any;
  @Input('deliveryAddress') deliveryAddress: any;
  @Input('defaultAddress') defaultAddress: any
  @Input('userSubscriptionInfo') userSubscriptionInfo: any;
  @Input('serviceVisibility') serviceVisibility: any;
  isButtonDisabled = true;
  @Output() selectGarmentBack: any = new EventEmitter<string>();
  @Output() onCreateUpdateAction: any = new EventEmitter<string>();
  @Output() payLater: any = new EventEmitter<string>();
  garmentSelectionScreen = true;
  garmentCare = false;
  counter = 0;
  addGarmentPage: boolean = false;
  getAddedGarmentForAddStainDamage: any = {};
  stainDamage: any;
  headerTitle: any;
  activeGarmentName: any;
  garmentDetails = {};
  finalGarmentInfo: any;
  defaultCare: any;
  currentGarmentIndex: any;
  finalOrderDetailsData: any = [];
  summaryView: boolean = false;
  selectedIndex: any = 0;
  garmentCategoryId: any;
  currentTabSubGroupIndex: any;
  unSkipAll = false;
  isSummaryVisible = false;
  currentTabSelectedGarment: any = [];
  isRemoveGarmentVisible: boolean = false;
  selectedGarment;
  @ViewChild("dt") tableData: any;
  currentIndex: any = 0;
  confirmOrderScreen: boolean = false;
  totalGarments: unknown;
  // Other properties and methods...

  toggleSummary() {
    this.isSummaryVisible = !this.isSummaryVisible;
  }

  toggleRemoveGarment() {
    this.isRemoveGarmentVisible = !this.isRemoveGarmentVisible;
  }


  constructor(
    private router: Router,
    private dialogService: DialogService,
    private orderHelperService: OrderHelperService,
    private orderService: OrderService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.scrollTop();
    this.renderGarments();
    console.log(this.deliveryAddress);
    console.log(this.pickupAddress);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.garmentList) {
      const previousValue = changes.garmentList.previousValue;
      const currentValue = changes.garmentList.currentValue;

      // Check if previous and current values are different
      if (JSON.stringify(previousValue) !== JSON.stringify(currentValue)) {
        this.renderGarments();
      }
    }
  }

  // initial setup
  renderGarments() {

    this.defaultCare = this.orderHelperService.getDefaultCare();
    let garmentGroups = Object.keys(this.garmentList);
    if (garmentGroups && garmentGroups.length > 0) {
      let addedGarment: any = this.orderHelperService.getAddedGarment();
      for (let garmentGroup of garmentGroups) {
        let garmentGroupName = garmentGroup.toLowerCase();
        this.garmentList[garmentGroup].map((list: any) => {
          let garmentName = list.garmentName.toLowerCase();
          if (addedGarment[garmentGroup] && addedGarment[garmentGroupName][garmentName]) {
            list.count = addedGarment[garmentGroupName][garmentName] ? addedGarment[garmentGroupName][garmentName].length : 0;
          }
        });
      }
    }
  }

  //add garment
  onIncrement(currentTab, index) {

    this.currentTab = currentTab;
    this.currentTabSubGroupIndex = index;
    let skipItem;
    let skipAllItemsArray = [];
    let garmentGroups = Object.keys(this.garmentList);
    skipItem = this.garmentList[currentTab][index].skip;
    for (let garment of garmentGroups) {
      this.garmentList[garment].find(list => {
        if (list.skipAll) {
          skipAllItemsArray.push(list)
        }
      });
    }

    //if (skipAllItemsArray.length == 0) {
    // if (skipItem == false) {
    //   this.dialogService.hasGarmentDialog({})
    //     .afterClosed()
    //     .subscribe(res => { //no
    //       if (res.data === 'no') {
    //         this.garmentDefault = false;
    //         this.garmentCare = false
    //         this.addGarmentPage = true;
    //         this.currentGarmentIndex = index;
    //         this.garmentCategoryId = this.garmentList[currentTab][index].categoryId;
    //       }
    //       else if (res.data == 'skip') { //skip 
    //         this.garmentList[currentTab][index].skip = true;
    //         this.IncrementGarment(currentTab, index);
    //         this.noBarcodeSkipGarmentCare(index);
    //       }
    //       else if (res.data == 'skipall') { //skip all
    //         this.garmentList[currentTab][index].skipAll = true;
    //         this.IncrementGarment(currentTab, index);
    //         this.noBarcodeSkipGarmentCare(index);
    //         this.unSkipAll = true;
    //       } else if (res.data == 'close') {
    //         //popup close
    //       }
    //       else { //if barcode has
    //         this.IncrementGarment(currentTab, index);
    //         this.counter++;
    //         this.summaryView = true;
    //         //set barcode and garment care details
    //         this.getSelectedGarment(index, res.data.barCode);
    //       }
    //     });
    // } else {    //increment counter when skip and skip all
    //   this.IncrementGarment(currentTab, index);
    //   this.noBarcodeSkipGarmentCare(index);
    // }
    //} else {
    //general flow 
    this.IncrementGarment(currentTab, index);
    this.noBarcodeSkipGarmentCare(index);
    //}
  }
  onCountChange(garmentKey: string, index: number, newCount: number): void {
    // Validate the new count (ensure it's not negative)
    if (newCount < 0) {
      newCount = 0; // Reset to 0 if negative
    }
    // Update the garment count in the garment list
    const garment = this.garmentList[garmentKey][index];
    garment.count = newCount;
  }
  IncrementGarment(currentTab, index) {
    if (this.garmentList[currentTab][index].count >= 0) {
      this.garmentList[currentTab][index].count++;
    }
  }

  //get garment based on barcode
  getSelectedGarment(index, barcode) {
    this.orderService.getGarmentCareBarcode(barcode, this.orderId).subscribe(
      (res) => {
        this.setBarcodeGarmentCare(res.garmentCare, index, barcode);
      },
      (err) => {
        //load default care if barcode doesn't having Preferences
        //if barcode doesn't have value
        this.noBarcodeSkipGarmentCare(index, barcode);
      })
  }

  //get default care if no barcode case
  noBarcodeSkipGarmentCare(index, barcode?) {
    let selectedCare: any = this.orderHelperService.selectedCare(this.defaultCare);  //get default care
    let care = {
      bleach: selectedCare.bleach.name,
      washTemperature: selectedCare.wetCleaning.name,
      ironTemperature: selectedCare.iron.name,
      dryClean: selectedCare.dryCleaning.name,
      starch: selectedCare.startch.name,
    }
    this.setBarcodeGarmentCare(care, index, barcode);
  }

  // set barcode and existing/default garment care
  setBarcodeGarmentCare(care, index, barcode?) {
    let currentGarment = this.garmentList[this.currentTab][index];
    let price = currentGarment?.prices[0].price;
    let service = currentGarment.prices[0].service;
    let serviceId = currentGarment.prices[0].serviceId;
    let object = {
      amount: price,
      counter: index,
      currencyId: currentGarment.prices[0].currencyId,
      deliveryDate: '',
      deliveryTime: '',
      orderDetailId: '',
      descriptionAdditional: '',
      barcode: barcode ? barcode : currentGarment.garmentName + currentGarment.count,
      "garment": {
        "garmentLabelId": null,
        "garmentTagId": barcode ? barcode : currentGarment.garmentName + currentGarment.count,
        "garmentStatusId": null,
        "comment": "",
        "description": "",
        "instruction": "",
        "price": price,
        "garmentName": currentGarment.garmentName,
        "garmentId": currentGarment.garmentId,
        "garmentImageURL": '',
        "garmentType": currentGarment.garmentName,
        "careInstructions": {},
        "serviceInstructions": { [service]: serviceId },
        "stains": [],
        "damages": [],
      },
    }
    let garmentName = currentGarment.garmentName.toLowerCase();
    this.orderHelperService.setAddGarment(this.currentTab, garmentName, object);
  }

  //add garment image
  addGarmentImage(garment) {
    let garmentName = this.garmentList[this.currentTab][this.currentTabSubGroupIndex].garmentName.toLowerCase();
    let count = this.garmentList[this.currentTab][this.currentTabSubGroupIndex].count;
    garment['barcode'] = garmentName + count;
    this.orderHelperService.setAddGarment(this.currentTab, garmentName, garment);
    this.setCurrentTabActive();
  }

  //set Garment Detail Id
  setGarmentDetailId(event) {
    this.orderHelperService.setAddGarmentValue(this.currentTab, this.activeGarmentName, event);
    this.setCurrentTabActive();
  }

  //stain success
  stainDamageSuccess(data) {
    this.orderHelperService.setAddGarmentValue(this.currentTab, this.activeGarmentName, data);
  }

  //remove garment
  onDecrement(currentTab, index) {
    if (this.garmentList[currentTab][index].count > 0) {
      let filteredGarment: any = this.orderHelperService.getAddedGarment();
      this.activeGarmentName = this.garmentList[currentTab][index].garmentName.toLowerCase();
      if (this.isEditOrder) {
        let data = {
          garments: filteredGarment[currentTab][this.activeGarmentName],
          orderId: this.orderId
        }
        //edit flow
        // this.dialogService.removeGarmentDialog(data)
        //   .afterClosed()
        //   .subscribe(res => {
        //     if (res) {
        //       this.garmentList[currentTab][index].count = res.count;
        //     }
        //   });
        this.garmentList[currentTab][index].count--;
        this.orderHelperService.removeAddedGarment(currentTab, this.activeGarmentName);
      } else {
        // create flow
        this.garmentList[currentTab][index].count--;
        this.orderHelperService.removeAddedGarment(currentTab, this.activeGarmentName);
      }
    }
  }

  //care page
  onGarmentCare(currentTab, index, count) {
    if (count > 0) {
      this.garmentCare = true;
      this.garmentSelectionScreen = false;
      this.stainDamage = false;
      this.addGarmentPage = false;
      this.activeGarmentName = this.garmentList[currentTab][index].garmentName.toLowerCase();
      let filteredGarment = this.orderHelperService.getAddedGarment();
      this.getAddedGarmentForAddStainDamage = JSON.parse(JSON.stringify(filteredGarment[currentTab][this.activeGarmentName]));
    }
  }

  //stain and damage page
  onStainDamage(val, type, index, count) {
    if (count > 0) {
      this.stainDamage = true;
      this.garmentSelectionScreen = false;
      this.garmentCare = false;
      this.addGarmentPage = false;
      this.headerTitle = val;
      this.activeGarmentName = this.garmentList[type][index].garmentName.toLowerCase();
      let getAddedGarment: any = this.orderHelperService.getAddedGarment();
      let filterGarment = getAddedGarment[type][this.activeGarmentName];
      this.getAddedGarmentForAddStainDamage = JSON.parse(JSON.stringify(filterGarment));
    }
  }

  // garment default page
  garmentPage() {
    this.scrollTop();
    this.garmentCare = false;
    this.garmentSelectionScreen = true;
  }

  //care and stain back
  onBack($event) {
    this.garmentSelectionScreen = true;
    this.garmentCare = false;
    this.addGarmentPage = false;
    this.stainDamage = false;
    this.scrollTop();
    this.setCurrentTabActive();
  }

  addGarmentBack(event) {
    this.onBack(event);
    if (event == 1) {
      this.garmentList[this.currentTab][this.currentGarmentIndex].count++;
    }
  }

  //create / update order
  onCreateUpdateOrder() {
    this.onCreateUpdateAction.emit(true);
  }

  //order back
  createOrderback() {
    this.selectGarmentBack.emit(true);
  }

  scrollTop() {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
  }

  originalOrder(a, b) {
    return 0;
  }

  garmentTotal() {
    return 0;
  }

  tabClick(event) {
    this.currentTab = (event.tab.textLabel);
    this.orderHelperService.setSelectedTab(event.index);
  }

  setCurrentTabActive() {
    this.selectedIndex = this.orderHelperService.getSelectedTab();
  }

  onUnSkipAll() {
    let garmentGroups = Object.keys(this.garmentList);
    for (let garment of garmentGroups) {
      this.garmentList[garment].map(list => {
        list.skip = false;
        list.skipAll = false;
      })
    };
    this.unSkipAll = false;
  }

  getTotalCount() {
    let totalCount = 0;
    for (let key in this.garmentList) {
      for (let item of this.garmentList[key]) {
        totalCount += item.count;
      }
    }
    return totalCount;
  }

  getTotalPrice() {
    let totalPrice = 0;
    for (let key in this.garmentList) {
      for (let item of this.garmentList[key]) {
        totalPrice += item.count * (item.prices![0]?.price ?? 0);
      }
    }
    return totalPrice?.toFixed(2);
  }

  getAmount(count: number, price: number): number {
    return +count * +price;
  }

  get garmentListAvailable(): boolean {
    return this.garmentList && Object.keys(this.garmentList).length > 0;
  }

  

  onMoveToOrderSummary() {
    //cal order invoice 
    this.garmentSelectionScreen = false;
    this.confirmOrderScreen = true;
  }

  confirmOrderBack() {
    this.garmentSelectionScreen = true;
    this.confirmOrderScreen = false;
    this.scrollTop();
  }
  
  confirmOrder(){
    this.onCreateUpdateAction.emit(true);
  }

  onPayLater() {
    this.payLater.emit(true);
  }

  onAction(common, garmentTypes, i, garment) {

  }

  isServiceActive(service: string): boolean {
    // Find the service with the given name
    const filteredData = this.serviceVisibility.find(item => {
        // Normalize and format the service name for comparison
        const formattedServiceName = item.serviceName
            .replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
            .replace(/&/g, ' & ')       // Format ampersands with spaces
            .trim()                     // Remove any extra spaces
            .toLowerCase();             // Convert to lowercase

        // Compare the formatted service name with the input
        return service.toLowerCase() === formattedServiceName;
    });

    // Return true if the service is found and isWebVisible is 1, otherwise false
    return filteredData?.isWebVisible === 1;
}


}
