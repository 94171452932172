import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  fromOrderSummary: any = false;
  vendorName: string = ''; // Default value or fetched dynamically
  supportEmail: string = ''; // Default value or fetched dynamically

  constructor(private router: Router, private dataService: DataService) {
    this.dataService.getVendorConfig().subscribe(config => {
      if(config) {
        this.vendorName = config.vendorName;
        this.supportEmail = `support@${config.vendorHost}`;
      }
    });
   }

  ngOnInit(): void {
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
    this.fromOrderSummary = window.history.state['fromOrderSummary'];
  }
 
  onBack(){
    this.router.navigate(['get-started'], { state: { OrderSummaryScreen: true }});
  }
}
