<div class="change-number">
    <div>
      <h1 class="header-title" style="font-size: 28px;"> please enter your information</h1>
      <div class="line-center">
        <span class="header-line"></span>
      </div>
    </div>
    <div class="col-sm-12">
        <!-- <div class="alert alert-primary" role="alert">
            please provide your information to sign-up for invitations when dryo is available in your area.
        </div> -->
      <form [formGroup]="notifyForm" class="row">
        <div class="col-sm-6">
          <mat-form-field dense appearance="legacy" class="field-6">
            <input type="text" formControlName="firstName" matInput placeholder="firstName">
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field dense appearance="legacy" class="field-6">
            <input type="text" formControlName="lastName" matInput placeholder="lastName">
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field dense appearance="legacy" class="field-6">
            <input type="text" formControlName="email" matInput placeholder="email address">
          </mat-form-field>
        </div>
        <div class="col-sm-6 basic-screen mat-country-selection">
            <mat-form-field dense appearance="legacy" class="field-6">
              <mat-label>phone number</mat-label>
              <ngx-mat-intl-tel-input
              formControlName="phoneNumber"
              [preferredCountries]="['us', 'in']"
              [enableSearch]="true"
              name="phoneNumber"
              #phone
              (countryChanged)="CountryChangedEvent($event)">
            </ngx-mat-intl-tel-input>
          </mat-form-field> 
          </div>
      </form>
      <div class="footer-btn" style="padding-top:50px">
        <button class="next-btn" (click)="onNotify()" mat-button>submit</button>
      </div>
    </div>
  </div>
