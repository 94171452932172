import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormGroup, FormControl, } from '@angular/forms';
import { AppImages } from 'src/app/core/model/app-images.model';
import { AppService } from 'src/app/core/services/app.service';
import QRCode from 'qrcode';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class HomeComponent implements OnInit {
  qrCodeUrl: string | undefined;

   // Replace these with the actual URLs of your app on the respective stores
   playStoreUrl  = 'https://play.google.com/store/apps/details?id=com.cleaners.dryo&pcampaignid=web_share'; 
   appStoreUrl = 'https://apps.apple.com/us/app/premier-cleaners/id6566182626';

  selectedLocaion: any;
  dialCode: any;
  @ViewChild('autoFocus') autoFocus: ElementRef;

  constructor(
    private appService: AppService) {
  }
  currentUser: any;
  showMenu: any;
  lastScroll: number;
  loaded: any;
  zipcode: FormControl;
  modalBox: any;
  zipCheckSubmitted: any;
  subscriptionSubmitted: any;
  subscriptionEmail: any;
  subscriptionPhone: any;
  emailLinkSubmitted: any;
  placesZip: any;
  mobileLinkSubmitted: any;
  currentLocation: any;
  countryList: any;
  separateDialCode = true;
  zipCheckFrom: FormGroup;

  selectedCountry = 'in';

  orderIcon: String;
  collectIcon: String;
  cleanIcon: String;
  dShapeIcon: String;
  mobilePhone: String;
  deliverIcon: String;
  iosAppIcon: String;
  googleAppIcon: String;
  phone2Icon: String;
  videoIcon: String;

   detectPlatform() {
    const userAgent = navigator.userAgent || navigator.vendor;

    let isAndroid = /android/i.test(userAgent);
    let isIOS = /iPad|iPhone|iPod/.test(userAgent) && !('MSStream' in window);

    const appStoreUrl = isAndroid
      ? this.playStoreUrl
      : this.appStoreUrl;

    this.generateQRCode(appStoreUrl);
   }

   generateQRCode(url: string): void {
    QRCode.toDataURL(url)
      .then((qrCode) => {
        this.qrCodeUrl = qrCode;
      })
      .catch((err) => {
        console.error('Error generating QR code', err);
      });
  }

  ngOnInit() {
  
    this.detectPlatform();

    // initialize icons
    this.orderIcon = this.appService.getImagePath(AppImages.HOW_WE_WORK_ORDER);
    this.collectIcon = this.appService.getImagePath(AppImages.HOW_WE_WORK_COLLECT);
    this.cleanIcon = this.appService.getImagePath(AppImages.HOW_WE_WORK_CLEAN);
    this.deliverIcon = this.appService.getImagePath(AppImages.HOW_WE_WORK_DELIVER);
    this.dShapeIcon = this.appService.getImagePath(AppImages.D_SHAPE);
    this.mobilePhone = this.appService.getImagePath(AppImages.MOBILE_PHONE);
    this.iosAppIcon = this.appService.getImagePath(AppImages.IOS_APP);
    this.googleAppIcon = this.appService.getImagePath(AppImages.GOOGLE_APP);
    this.phone2Icon = this.appService.getImagePath(AppImages.PHONE_2);
    this.videoIcon = this.appService.getImagePath(AppImages.VIDEO);
  }

  hideMenuFn(hashItem) {
    this.showMenu = false;
    this.scrollIntoView(hashItem);
  }
  scrollIntoView(anchorHash) {
    setTimeout(() => {
      const anchor = document.getElementById(anchorHash);
      let topOfElement = 0;
      if (anchor) {
        anchor.focus();
        anchor.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        topOfElement = anchor.offsetTop - 65;
        window.scroll({ top: topOfElement, behavior: "smooth" });
      }
      else {
        window.scroll(0, 0);
      }
    });
  }

  countryChangedEvent(event) {
    this.dialCode = event.dialCode;
  }
}