<div class="not-found-container">
  <div class="error-container">
    <h1 class="error-message">404</h1>
  </div>
  <h1 class="page-not-found">Page Not Found</h1>
  <p>The page you're looking for doesn’t exist or has been moved.</p>

  <div class="actions">
    <button class="go-home-btn" (click)="goHome()">Go to Home</button>
  </div>
</div>