<div class="order-summary">
    <div class="row">
        <div class="col-md-12 mb-2">
            <h1 class="header-title">order summary</h1>
            <div class="line"></div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-7">
            <div class="form-row">
                <label>basic package</label>
                <span> {{summaryDetails.currencySymbol}} {{summaryDetails.basicPackage}}</span>
            </div>
            <hr class="light-line" />
            <div class="form-row">
                <label>subtotal</label>
                <span> {{summaryDetails.currencySymbol}} {{summaryDetails.basicPackage}} </span>
            </div>
            <div class="form-row">
                <label for="townborn">discount (Activation code)</label>
               
                <div *ngIf="summaryDetails.discount; else noDiscount">
                    <span > {{summaryDetails.currencySymbol}} {{summaryDetails.discount}}</span>
                  </div>
                  <ng-template #noDiscount>
                    <span > {{summaryDetails.currencySymbol}} 0 </span>
                  </ng-template>
            </div>
            <hr class="light-line" />
            <div class="form-row">
                <label>total cost</label>
                <span> {{summaryDetails.currencySymbol}} {{summaryDetails.totalCost}}</span>
            </div>
            <hr class="light-line" />
        </div>
        <div class="col-sm-5 coupon-activation">
            <mat-form-field dense appearance="legacy" style="width:100%">
                <mat-label>activation code</mat-label>
                <input type="text" matInput placeholder="" >
                <button class="coupon-btn" mat-button >apply</button>
            </mat-form-field>
            <mat-form-field dense appearance="legacy" style="width: 100%">
                <mat-label>coupon code</mat-label>
                <input type="text" matInput placeholder="" [(ngModel)] = "activationCode">
                <button class="coupon-btn" (click) = "applyActivationCode()" mat-button>apply</button>
            </mat-form-field>
        </div>
    </div>
    <hr class="dark-line" />
    <div class="row">
        <div class="col-sm-6 payment-address">
            <h3 class="">Payment details</h3>
            <button class="change-btn back-btn" (click)="changePayment()">change</button>
            <p *ngIf="summaryDetails.discount">pay {{summaryDetails.currencySymbol}} {{summaryDetails.totalCost}} </p>
            <p *ngIf="!summaryDetails.discount">pay {{summaryDetails.currencySymbol}} {{summaryDetails.basicPackage}}</p>
        </div>
        <div class="col-sm-6 billing-address">
            <h3>billing address</h3>
            <ng-container *ngIf="regAddress">
                <p>
                    {{regAddress.firstName}},<br> {{regAddress.address[0].addressLine1}}, 
                    <span *ngIf="regAddress?.address[0]?.addressLine2">{{regAddress.address[0].addressLine2}}, </span>
                     <br> {{regAddress.address[0].city}} {{regAddress.address[0].zipCode}}
                </p>
              </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pr-40">
            <div class="field-6 custom-checkbox">
                <mat-checkbox class="i-agree" [(ngModel)]="termsPolicy">
                    <a> i agree to the dryo <span (click)="onTermsNprivacy('terms_conditions')">terms and conditions</span> and 
                        <span (click)="onTermsNprivacy('privacy_policy')"> privacy policies</span></a>
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="footer-btn col-sm-12">
            <button class="started-btn back-btn" mat-button matStepperPrevious>back</button>
            <button class="started-btn next-btn" mat-button (click)="onCreatePurchase()">register
              </button>
          </div>
    </div>
</div>
