import { HttpClient} from '@angular/common/http';
import { Injectable} from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, map, retry, share } from 'rxjs/operators';
import { SocialMediaInfo } from 'src/app/core/model/social-media-info.model';
import { VendorUIInfo } from 'src/app/core/model/vendor-ui-info.model';
import { MenuItemInfo } from '../model/menu-item-info.model';
import { UIColorInfo } from '../model/ui-color-info.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private vendorUIInfo: VendorUIInfo;
  private uiColorInfoMap: Map<string, string> = new Map<string, string>();
  private headerMenu: MenuItemInfo[] = [];
  private footerMenu: MenuItemInfo[] = [];
  private socialMediaLinks: SocialMediaInfo[] = [];

  private loading: boolean;
  private spinnerObservable$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  constructor(private http: HttpClient) {
  }    

  public subscribeToVendorUIInfo() : Subscription {    

    let uiInfoObservable$ : Observable<VendorUIInfo> = this.getUIInfoFromHttp2();
    // this.getVendorUIInfoFromHttp() // load from the mock application

    this.loading = true;
    this.spinnerObservable$.next(this.loading);

    let uiInfoSubscription =  uiInfoObservable$ // load from config.json

    .pipe(share(), map((response) => {    
      response.uiColor.forEach((element: UIColorInfo) => {
          this.uiColorInfoMap.set(element.colorName, element.hexValue);        
       });

      this.socialMediaLinks = [...response.socialMediaLinks];
      
      this.headerMenu = [...response.headerMenu];

      this.footerMenu = [...response.footerMenu];
      
      return response;
    }))
    
    .subscribe((data:VendorUIInfo) => {        
          this.vendorUIInfo = { ...data };      
        }, error => {
          
        }, () => {
          this.loading = false;
          this.spinnerObservable$.next(this.loading);
        }      
    );    
    return uiInfoSubscription;
  }    
    

    private getUIInfoFromHttp2(): Observable<VendorUIInfo> {
      let vendorUIInfoUrl = './assets/json/config.json';          
      return this.http.get<VendorUIInfo>(vendorUIInfoUrl)
      .pipe(
        retry(3), // retry a failed request up to 3 times          
        catchError(this.handleError<VendorUIInfo>('get VendorUIInfo from config.json'))
      );; 
    }

    private handleError<T>(operation = 'getUIInfo', result?: T) {
      return (error: any): Observable<T> => {    
        console.error(error); // log to console instead
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }

  getVendorUIInfo() {
     return this.vendorUIInfo;
   }

  getVendorName() {
    let vendorName = ''; 

    if(!this.loading)
        vendorName = this.vendorUIInfo.name;

    return vendorName;       
  }

   getSocialMediaLinksJSONArray() {
      let socialMediaLinksJSONArray: any;

      if(!this.loading)
        socialMediaLinksJSONArray = this.vendorUIInfo.socialMediaLinks;

      return socialMediaLinksJSONArray;         
    }    

    getSocialMediaLinks(): SocialMediaInfo[] {   
      if(this.socialMediaLinks.length != 0)
        return this.socialMediaLinks;      

      if(!this.loading) 
         this.getSocialMediaLinksJSONArray().forEach((element: SocialMediaInfo) => {
         this.socialMediaLinks.push(element);
        });

      return this.socialMediaLinks;      
    }  

    getUIColorInfoMap() : Map<string, string> {
      if(this.uiColorInfoMap.size != 0)
         return this.uiColorInfoMap;

      if(!this.loading)
          this.vendorUIInfo.uiColor.forEach((element: UIColorInfo) => {
          this.uiColorInfoMap.set(element.colorName, element.hexValue);
         })

      return this.uiColorInfoMap;
    }

    getHeaderMenu(): MenuItemInfo[] {   

      if(this.headerMenu.length != 0)
      return this.headerMenu;     

      if(!this.loading) 
           this.vendorUIInfo.headerMenu.forEach((element: MenuItemInfo) => {
           this.headerMenu.push(element);
        })
      
      return this.headerMenu;      
    }  
    
    getFooterMenu(): MenuItemInfo[] {   

      if(this.footerMenu.length != 0)
        return this.footerMenu;     

      if(!this.loading) 
        this.vendorUIInfo.footerMenu.forEach((element: MenuItemInfo) => {
            this.footerMenu.push(element);
        })  
      
      return this.footerMenu;      
  }  

  getLoading(): boolean {
    return this.loading;
  }

  getSpinnerObservable(): BehaviorSubject<boolean> {
    return this.spinnerObservable$;
  }

}

