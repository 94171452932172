<!-- <div *ngIf="!garment.imageURL" class="empty"> </div> -->

<ng-container *ngIf="garmentSelectionScreen && garmentListAvailable">
  <div class="row individual-account material-sub-tabs">
    <div class="col-sm-9">
      <div class="tabs">
        <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="selectedIndex">
          <ng-container *ngFor="let garmentTypes of garmentList | keyvalue: originalOrder;">
            <ng-container *ngIf="isServiceActive(garmentTypes.key)">
              <mat-tab [label]="garmentTypes.key" *ngIf="garmentTypes.key != 'adjustments'">
                <div class="col-sm-12">
                  <div class="garment-wrap row">
                    <ng-container *ngFor="let garment of garmentTypes.value; let i = index">
                      <ng-container *ngIf="garment.prices![0]?.isWebVisible ==1">
                        <div class="card garment-card">
                          <!-- Card Header -->
                          <div class="card-header">
                            <div class="garment-image" [ngClass]="{'active': garment.count > 0}">
                              <div *ngIf="garment.imageURL" [ngClass]="{'active': garment.count > 0}">
                                <img [src]="garment.imageURL" alt="Garment Image">
                              </div>
                              <img *ngIf="!garment.imageURL"
                                src="../../../../assets/images/garment/Icon__top - blouse_dark.svg"
                                alt="Default Garment Image">
                            </div>
                            <div class="price-badge">
                              {{ garment.prices![0]?.price | currency: 'USD':'symbol':'1.2-2' }}
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="garment-title">
                              {{ garment.garmentName }}
                            </div>
                            <div class="counter">
                              <div class="plusminus">
                                <button class="rounded-icon-btn" (click)="onDecrement(garmentTypes.key, i)">
                                  <span class="material-icons">remove</span>
                                </button>
                                <input type="number" [(ngModel)]="garment.count" [min]="0"
                                  (ngModelChange)="onCountChange(garmentTypes.key, i, garment.count)"
                                  class="count-input" />
                                <button class="rounded-icon-btn" (click)="onIncrement(garmentTypes.key, i)">
                                  <span class="material-icons">add</span>
                                </button>
                              </div>
                              <div>
                                <!-- Three dots menu trigger -->
                                <button [disabled]="!garment.count" mat-icon-button [matMenuTriggerFor]="menu"
                                  class="three-dots rounded-icon-btn">
                                  <span class="material-icons">more_horiz</span>
                                </button>

                                <!-- Mat Menu -->
                                <mat-menu #menu="matMenu">
                                  <button mat-menu-item
                                    (click)="onGarmentCare(garmentTypes.key, i, garment.count)">care</button>
                                  <button mat-menu-item
                                    (click)="onStainDamage('mark stain', garmentTypes.key, i, garment.count)">stain</button>
                                  <button mat-menu-item
                                    (click)="onStainDamage('damage', garmentTypes.key, i, garment.count)">damage</button>
                                </mat-menu>
                              </div>
                            </div>
                          </div>

                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                  <!-- Add Unskip Option -->
                  <ng-container *ngIf="unSkipAll">
                    <a (click)="onUnSkipAll()" class="unskip-all">Click here to unskip and add garments</a>
                  </ng-container>
                </div>
              </mat-tab>
            </ng-container>
          </ng-container>
        </mat-tab-group>
      </div>
    </div>
    <div class="col-sm-3">
      <!-- Sidebar Overlay for Summary -->
      <div class="garment-summary-overlay">
        <div class="garment-summary">
          <h1 class="title">Summary</h1>
          <div>
            <ng-container *ngFor="let garment of garmentList | keyvalue">
              <ng-container *ngFor="let data of garment.value">
                <div class="row-wrap" *ngIf="data.count">
                  <div class="garment">
                    {{data.garmentName}}
                    <div>{{garment.key}}</div>
                  </div>
                  <div class="count"> {{data.count}} X {{data?.prices[0]?.price | number:'1.2-2' }}</div>
                  <div class="amount">{{data.prices[0].currencySymbol}} {{getAmount(data.count,data.prices[0].price) |
                    number:'1.2-2'}}</div>
                </div>
              </ng-container>

            </ng-container>
            <div *ngIf="getTotalCount()" class="total-summary">
              <div>Total</div>
              <div>{{getTotalCount()}}</div>
              <div>${{getTotalPrice()}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-btn fixed-footer">
      <button (click)="createOrderback()" class="back-btn" mat-button>back</button>
      <button (click)="onMoveToOrderSummary()" class="next-btn" mat-button>next</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!garmentListAvailable">
  <div class="flex-loading">
    <p>Loading...</p>
  </div>
</ng-container>




<!--add garment-->
<ng-container *ngIf="addGarmentPage">
  <app-add-garment [counter]="counter" [orderId]="orderId" [garmentCategoryId]="garmentCategoryId"
    (garmentDetailId)="addGarmentImage($event)" (garmentEvent)="addGarmentBack($event)">
  </app-add-garment>
</ng-container>

<!--garment care-->
<ng-container *ngIf="garmentCare">
  <app-garment-care [addedGarment]="getAddedGarmentForAddStainDamage" [counter]="counter" [orderId]="orderId"
    (garmentDetailId)="setGarmentDetailId($event)" (careEvent)="onBack($event)">
  </app-garment-care>
</ng-container>

<!--stain and damage-->
<ng-container *ngIf="stainDamage">
  <app-stain-damage [addedGarment]="getAddedGarmentForAddStainDamage" [counter]="counter" [orderId]="orderId"
    (stainDamageSuccess)="stainDamageSuccess($event)" (stainDamageBack)="onBack($event)" [title]="headerTitle">
  </app-stain-damage>
</ng-container>

<ng-container *ngIf="confirmOrderScreen">
  <app-confirm-order [isEditOrder]="isEditOrder" [garmentList]="garmentList" [pickupDefaultAddress]="pickupAddress"
    [userSubscription]="userSubscriptionInfo" [deliveryDefaultAddress]="deliveryAddress"
    [defaultAddress]="defaultAddress" (confirmOrderSubmit)="confirmOrder()" (confirmOrderBack)="confirmOrderBack()"
    (payLater)="onPayLater()" [orderId]="orderId">
  </app-confirm-order>
</ng-container>