import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/Auth/auth.service';
import { GetStartedService } from 'src/app/core/services/get-started.service';
import { HelperService } from 'src/app/core/services/helper.service';
import { UserService } from 'src/app/core/services/user.service';
import PlaceResult = google.maps.places.PlaceResult;
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  @Input('firstTimeUser') firstTimeUser: any;
  @Output() ProfileEvent: any = new EventEmitter<string>();
  profileForm: FormGroup
  currentUser: any = {};
  countryList: any = [];
  stateList: [];
  cityList: [];
  profileInformation: any;
  @ViewChild('addressLine1') addressLine1: any;
  latitude: any;
  longitude: any;
  currentLocation: any;
  selectedLocaion: any;
  selectedCountry: any;
  addressLine1Value: any;
  locationTypes: any;
  genderTypes = ["male","female","others"];
  maxDate: Date;
  showBackButton: boolean = true;

  constructor(
    private fb: FormBuilder,
    private UserService: UserService,
    private getStartedService: GetStartedService,
    private helperService: HelperService,
    private toasterService: ToastrService,
    private router: Router,
    private authService: AuthService) {
      this.maxDate = new Date(); 
  }

  ngOnInit(): void {
    this.profileFormInit();
    this.getCountry();
    this.currentUser = this.authService.getUser();
    this.getUserProfile();
    this.getAddressTypes();
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });

      // Check the initial URL
      this.toggleBackButtonVisibility();
    // Listen for route changes
    this.router.events.subscribe(() => {
      this.toggleBackButtonVisibility();
    });
  }

  getUserProfile() {
    this.UserService.getUserProfile(this.currentUser.userId).subscribe(
      (res) => {
        this.profileInformation = res;
        sessionStorage.setItem("userProfile", JSON.stringify(res?.userDetails));
        this.profileForm.patchValue(res.userDetails);
        this.profileForm.patchValue(res.userDetails.address[0]);
        this.profileForm.controls['addressLine1'].setValue(res.userDetails.address[0].addressLine1);
        this.profileForm.controls['dob'].setValue(res.userDetails.dob);
        this.addressLine1.nativeElement.value = res.userDetails.address[0].addressLine1;
        if(res.userDetails && res.userDetails.address.length >0){
         // Assuming countryList is an array of country objects and res.userDetails.address[0].country is a string
          let getCountryId = this.countryList.find(val =>
            val.name.toLowerCase() === res.userDetails.address[0].country.toLowerCase()
          )?.rowId;
         this.profileForm.controls.country.setValue(getCountryId);
            this.getStatesByCountryId(res.userDetails.address[0]); // fecth the selected states
        }
      },
      (err) => {

      }
    )
  }

  profileFormInit(components?) {
    this.profileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.email]],
      gender: ['', Validators.required],
      dob: [''],
      addressTypeId: [''],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      city: ['', ],
      state: ['', ],
      zipCode: ['', ],
      country: ['', ],
    });
  }

  getCountry() {
    this.getStartedService.getCountry()
      .subscribe(
        (data: any) => {
          if (data) {
            this.countryList = data.response;
          }
        },
        (error) => {
        }
      )
  }

formatDate(dateString) {
    // Create a Date object from the input string
    const date = new Date(dateString);
    
    // Extract year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    
    // Format the date as yyyy-MM-dd
    return `${year}-${month}-${day}`;
}

  onSave() {
    let dob = this.formatDate(this.profileForm.value.dob);
    if(this.profileForm.valid) {
    let data = {
      "user": {
        "userId": this.currentUser.userId,
        "firstName": this.profileForm.value.firstName,
        "lastName": this.profileForm.value.lastName,
        "dob": dob,
        "gender": this.profileForm.value.gender,
        "phoneNumber": this.profileForm.value.phoneNumber,
        "countryCode": this.profileInformation.userDetails.countryCode,
        "emailId": this.profileForm.value.emailId,
      },
      "address": {
        "addressId": this.profileInformation.userDetails.address[0].id,
        "addressTypeId": this.profileInformation.userDetails.address[0].addressTypeId,
        "addressLine1": this.profileForm.value.addressLine1,
        "addressLine2": this.profileForm.value.addressLine2,
        "cityId": this.profileForm.value.city,
        "stateId": this.profileForm.value.state,
        "countryId": this.profileForm.value.country,
        "zipCode": this.profileForm.value.zipCode,
        "unitNo": this.profileInformation.userDetails.address[0].unitNo,
        "buildingName": this.profileInformation.userDetails.address[0].buildingName,
        "suite": this.profileInformation.userDetails.address[0].suite,
        "companyName": this.profileInformation.userDetails.address[0].companyName,
        "locationDescription": this.profileInformation.userDetails.address[0].locationDescription,
      },
      "vendor": {
        "vendorId": '',
        "storeId": '',
      },
      "customer": {
        "priceListId": 0,
        "note": "",
      }
    }
    this.UserService.saveUserProfile(data).subscribe(
      (response: any) => {
        this.toasterService.success('Profile updated successfully');
        this.ProfileEvent.emit(1);
      },
      (error) => {
      }

    )} else {
      this.profileForm.markAllAsTouched();
    }
   
  }

  onBack() {
    this.ProfileEvent.emit(-1);
  }

  onLocationSelected(location: any) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  onAutocompleteSelected(result: PlaceResult, a?) {
    let components: any = this.helperService.onFormatAddress(result, this.countryList);
    this.addressLine1Value = components.completeAddress;
    this.addressLine1.nativeElement.value = components.completeAddress;
    this.profileForm.controls['addressLine1'].setValue(components.completeAddress);
    this.profileForm.controls['country'].setValue(components.selectedCountry);
    this.profileForm.controls['state'].setValue(components.state);
    this.profileForm.controls['city'].setValue(components.locality);
    this.profileForm.controls['zipCode'].setValue(components.postal_code);
    
    if(components.selectedCountry) {
      this.getAndSetStates(components); // fecth the selected states
   }
  }

  getAddressTypes() {
    this.getStartedService.getAddressTypes()
      .subscribe(
        (data: any) => {
          if (data) {
            this.locationTypes = data.addressTypeList;
          } else {
          }
        },
        (error) => {
        });
  }


  getState(): Promise<void> {
    const countryId = this.profileForm.value.country;
    return this.getStartedService.getState(countryId).pipe(
      map((data: any) => {
        if (data) {
          this.stateList = data.response;
        }
      })
    ).toPromise();
  }

  getCity(): Promise<void> {
    let stateId = this.profileForm.value.state;
    return this.getStartedService.getCity(stateId).pipe(
      map(
        (data: any) => {
          if (data) {
            this.cityList = data.response;
          }
        },
      )).toPromise()
  }

  /* get state and city on initial load based on country id*/
  async getStatesByCountryId(components)  {
    await this.getState();
   let filterSelectedState = this.stateList?.filter((val:any) => {
       if (val.name == components.state) {
         return val;
       }
   }).map((item:any) => item.rowId);
   this.profileForm.controls['state'].setValue(filterSelectedState ? filterSelectedState[0] : 0);
   if(components.country && filterSelectedState && filterSelectedState.length > 0) {
     this.getCitiesByStateId(components); // fecth the selected states
  }
 };

 async getCitiesByStateId(components)  {
  await this.getCity();
 let filterSelecteCity = this.cityList?.filter((val:any) => {
     if (val.name == components?.city) {
       return val;
     }
 }).map((item:any) => item.rowId);
 this.profileForm.controls['city'].setValue(filterSelecteCity ? filterSelecteCity[0] : 0);
};


 /* auto complete flow start */

 async getAndSetStates(components) {
  try {
    // Ensure getState is an async function and wait for it to resolve
    await this.getState();

    // Check if stateList is properly set and filter it
    let filterSelectedState = this.stateList?.filter((val: any) => {
      if (val.name.toLowerCase() === components?.administrative_area_level_1.toLowerCase()) {
        return val;
      }
      return null;
    }).map((item: any) => item.rowId);

    // Set the value in the form control
    this.profileForm.controls['state'].setValue(filterSelectedState ? filterSelectedState[0] : 0);

    // If the country is selected and the state is valid, fetch cities
    if (components.selectedCountry && filterSelectedState && filterSelectedState.length > 0) {
      await this.getAndSetCities(components); // Ensure this is awaited too
    }
  } catch (error) {
    console.error('Error in getAndSetStates:', error);
  }
}


 async getAndSetCities(components)  {
   await this.getCity();
  let filterSelecteCity = this.cityList?.filter((val:any) => {
      if (val.name.toLowerCase() == components?.locality.toLowerCase()) {
        return val;
      }
  }).map((item:any) => item.rowId);
  this.profileForm.controls['city'].setValue(filterSelecteCity ? filterSelecteCity[0] : 0);
};
/* auto complete flow end */

toggleBackButtonVisibility(): void {
  // Check if the current URL contains 'profile'
  this.showBackButton = this.router.url.includes('/my-profile');
}

}
