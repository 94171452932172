<!-- Table View for Web/Desktop -->


<ng-container *ngIf="!isMobileView">
  <div class="row order-history-details material-new-tabs">
    <div class="col-md-12 pt-30 col-xs-12">

      <ng-container *ngIf="activeOrders?.length > 0;">
        <p-table #dt2 [lazy]="true" (onLazyLoad)="getOrders($event)" dataKey="orderNumber" [value]="activeOrders"
          [paginator]="true" [rows]="rows" [totalRecords]="orderAllRecords" [responsive]="true"
          [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [loading]="loading">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 4rem"></th>
              <th style="width: 150px;">
                <div class="flex justify-content-center align-items-center">
                  <p-columnFilter placeholder="order number" type="text" field="orderNumber"
                    [showMenu]="false"></p-columnFilter>
                </div>
              </th>
              <th>
                <div class="flex justify-content-center align-items-center">
                  <p-columnFilter placeholder="order date" type="text" field="orderDate"
                    [showMenu]="false"></p-columnFilter>
                </div>
              </th>
              <th style="width: 180px;">
                <div class="flex justify-content-center align-items-center">
                  <p-columnFilter placeholder="pickup date & time" type="text" field="pickupDate"
                    [showMenu]="false"></p-columnFilter>
                </div>
              </th>
              <!-- <th>
                  <div class="flex justify-content-center align-items-center">
                    <p-columnFilter placeholder="pickup time" type="text" field="pickupStartTime"
                      [showMenu]="false"></p-columnFilter>
                  </div>
                </th> -->
              <th style="width: 180px;">
                <div class="flex justify-content-center align-items-center">
                  <p-columnFilter placeholder="delivery date & time" type="text" field="deliveryDate"
                    [showMenu]="false"></p-columnFilter>
                </div>
              </th>
              <th>
                  <div class="flex justify-content-center align-items-center">
                    <p-columnFilter placeholder="quantity" type="text" field="quantity"
                      [showMenu]="false"></p-columnFilter>
                  </div>
                </th>
              <th>
                <div class="flex justify-content-center align-items-center">
                  <p-columnFilter placeholder="status" [showMenu]="false" type="text" field="status"></p-columnFilter>
                </div>
              </th>
              <th>
                <div class="flex justify-content-center align-items-center">
                  <p-columnFilter placeholder="payment" [showMenu]="false" type="text" field="payment"></p-columnFilter>
                </div>
              </th>
              <th width="200px">action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-expanded="expanded">
            <tr>
              <td>
                <button type="button" pButton pRipple [pRowToggler]="rowData"
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              </td>
              <td>{{rowData.orderNumber}}</td>
              <td>{{convertTimestamp(rowData.orderDate)}}</td>
              <td>{{rowData.pickupDate}} {{rowData.pickupStartTime }} - {{rowData.pickupEndTime }}</td>
              <!-- <td>{{rowData.pickupStartTime }} - {{rowData.pickupEndTime }}</td> -->
              <td>{{rowData.deliveryDate}} {{rowData.deliveryStartTime}} - {{rowData.deliveryEndTime }}</td>
              <td>{{ getTotalQuantity(rowData) }}</td>
              <td>{{rowData.status}}</td>
              <td>{{getPaymentStatus(rowData)}}</td>
              <td>
                <div class="order-action">

                    <button label="edit Order" [disabled]="rowData.status === 'Cancelled'"
                    (click)="cancelOrder(rowData)">cancel order</button>

                    <button  label="cancel Order" [disabled]="rowData.status === 'Cancelled'"
                    (click)="myOrder(rowData)">edit order</button>

                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" pTemplate="rowexpansion" let-rowData>
            <tr *ngIf="rowData.groupedOrderDetails.length">
              <td colspan="9">
                <p-table [value]="rowData.groupedOrderDetails" [responsive]="true">
                  <ng-template pTemplate="header">
            <tr>
              <th>Service</th>
              <th>Garment Type</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Total</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-service>
            <ng-container *ngFor="let garment of service.garments; let j = index">
              <tr>
                <td>{{service.serviceInstruction}}</td>
                <td>{{garment.garmentType}}</td>
                <td>{{garment.price}}</td>
                <td>{{garment.quantity}}</td>
                <td>{{garment.total}}</td>
              </tr>
            </ng-container>
          </ng-template>
        </p-table>
        </td>
        </tr>
        <tr *ngIf="rowData.groupedOrderDetails.length ==0 && rowData.status !='Quick tickets'">
          <td colspan="8">No data Available</td>
        </tr>
        <tr *ngIf="rowData.groupedOrderDetails.length == 0 && rowData.status =='Quick tickets'">
          <td colspan="8">Quantity : {{rowData.others?.quickOrderGarmentsCount}}</td>
        </tr>
        <tr *ngIf="rowData.groupedOrderDetails?.length" class="total-row">
          <td colspan="5"></td>
          <td colspan="2">Quantity : {{getTotalQuantity(rowData)}}</td>
          <td>Amount: $ {{getTotalPrice(rowData)}}</td>
        </tr>
        </ng-template>
        </p-table>
      </ng-container>
    </div>
  </div>

  <div *ngIf="loading" class="no-orders">
    Loading, please wait...
  </div>

  <ng-container *ngIf="!loading && activeOrders?.length == 0">
    <div class="no-orders">
      No orders available
    </div>
  </ng-container>
</ng-container>

<!-- Card View for Mobile -->
<ng-container *ngIf="isMobileView">
  <div class="order-cards">
    <!-- Loading State -->
    <div *ngIf="loading" class="no-orders">
      Loading, please wait...
    </div>

    <!-- Active Orders -->
    <ng-container *ngIf="!loading">
      <ng-container *ngIf="activeOrders && activeOrders.length; else noOrders">
        <div *ngFor="let order of activeOrders" class="order-card">
          <div class="order-card-header">
            <span class="order-number">Order #{{ order.orderNumber }}</span>
            <span class="order-status">{{ order.status }}</span>
          </div>
          <div class="order-card-body">
            <div class="order-info">
              <span class="label">Order Date:</span>
              <span class="value">{{ convertTimestamp(order.orderDate) }}</span>
            </div>
            <div class="order-info">
              <span class="label">Pickup Date:</span>
              <span class="value">{{ convertTimestamp(order.pickupDate) }}</span>
            </div>
            <div class="order-info">
              <span class="label">Pickup Time:</span>
              <span class="value">{{ order.pickupStartTime }} - {{ order.pickupEndTime }}</span>
            </div>
            <div class="order-info">
              <span class="label">Delivery Date:</span>
              <span class="value">{{ convertTimestamp(order.deliveryDate) }}</span>
            </div>
            <div class="order-info">
              <span class="label">Delivery Time:</span>
              <span class="value">{{ order.deliveryStartTime }} - {{ order.deliveryEndTime }}</span>
            </div>
            <div class="order-info">
              <span class="label">Payment status:</span>
              <span class="value">{{ getPaymentStatus(order) }}</span>
            </div>
          </div>
          <div *ngIf="order.groupedOrderDetails?.length" class="order-items">
            <ng-container *ngFor="let service of order.groupedOrderDetails">
              <div class="order-item" *ngFor="let garment of service.garments">
                <div class="item-detail">
                  <span class="label">Service:</span>
                  <span class="value">{{ service.serviceInstruction }}</span>
                </div>
                <div class="item-detail">
                  <span class="label">Garment:</span>
                  <span class="value">{{ garment.garmentType }}</span>
                </div>
                <div class="item-detail">
                  <span class="label">Price:</span>
                  <span class="value">{{ garment.price }}</span>
                </div>
                <div class="item-detail">
                  <span class="label">Qty:</span>
                  <span class="value">{{ garment.quantity }}</span>
                </div>
                <div class="item-detail">
                  <span class="label">Total:</span>
                  <span class="value">{{ garment.total }}</span>
                </div>
              </div>
            </ng-container>
          </div>
          <div *ngIf="order.groupedOrderDetails?.length == 0 && order.status == 'Quick tickets'" class="order-items">
            <div class="order-item">
              <div class="item-detail">
                <span class="label">Quantity:</span>
                <span class="value">{{ order.others?.quickOrderGarmentsCount }}</span>
              </div>
              </div>
          </div>
          <div class="order-card-footer">
            <span>Quantity: {{ getTotalQuantity(order) }}</span>
            <span>Amount: ${{ getTotalPrice(order) }}</span>
          </div>
          <div class="order-action">
            <button label="edit Order" [disabled]="order.status === 'Cancelled'"
            (click)="cancelOrder(order)">cancel order</button>

            <button  label="cancel Order" [disabled]="order.status === 'Cancelled'"
            (click)="myOrder(order)">edit order</button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- Pagination Controls -->
  <ng-container *ngIf="!loading && activeOrders && activeOrders.length;">
    <p-paginator [rows]="rows" [totalRecords]="totalRecords" [first]="first"
      (onPageChange)="onPageChange($event)"></p-paginator>
  </ng-container>

  <ng-template #noOrders>
    <div class="no-orders">No orders available</div>
  </ng-template>
</ng-container>

<!-- Card View for Mobile end-->