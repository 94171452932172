import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from 'src/app/guest/home/home.component';
import { LoginComponent } from 'src/app/guest/login/login.component';
import { GetStartedComponent } from 'src/app/guest/get-started/get-started.component';
import { PricingComponent } from 'src/app/guest/pricing/pricing.component';
import { LocationsComponent } from './guest/locations/locations.component';
import { TechnicalSupportComponent } from './guest/technical-support/technical-support.component';
import { OtpAuthorizationComponent } from './guest/otp-authorization/otp-authorization.component';
import { FaqComponent } from './on-board-user/faq/faq.component';
import { PrivacyPolicyComponent } from './on-board-user/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './on-board-user/terms-of-use/terms-of-use.component';
import { InvestorRelationsComponent } from './on-board-user/investor-relations/investor-relations.component';
import { AddAddressComponent } from './shared/add-address/add-address.component';
import { NoServiceableAreaComponent } from './guest/no-serviceable-area/no-serviceable-area.component';
import { UserSetupComponent } from './user-setup/user-setup.component';
import { NotFoundComponent } from './not-found/not-found.component';


const routes: Routes = [
  {
    path: '', 
    component: HomeComponent
  },
  {
    path: 'get-started',
    component:GetStartedComponent
  },
  {
    path:'home',
    component: HomeComponent
  },
  {
    path:'serviceable-area',
    component:NoServiceableAreaComponent
  },
  {
    path:'pricing',
    component:PricingComponent
  },
  {
    path: 'login', 
    component:LoginComponent
  },
  {
    path: 'user-setup',
    component: UserSetupComponent
  },
  {
    path: 'locations', 
    component:LocationsComponent
  },
  {
    path: 'technical-support',
    component:TechnicalSupportComponent
  },
  {
    path:'otp-authorization',
    component:OtpAuthorizationComponent
  },
  {
    path:'faq', 
    component:FaqComponent
  },
  {
    path:'privacy-policy',
    component:PrivacyPolicyComponent
  },
  {
    path:'terms-of-use',
    component:TermsOfUseComponent
  },
  {
    path:'investor-relations',
    component:InvestorRelationsComponent
  },
  {
    path:'add-address',
    component:AddAddressComponent
  },
  {
    path: '',
    loadChildren: () => import(`./on-board-user/onboarduser.module`).then(m => m.OnboarduserModule),
  },
  {path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
