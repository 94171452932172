<div class="main-wrapper">
	<div class="left-side">
		<div class="row">
			<app-menu-list-item></app-menu-list-item>
		</div>
	</div>
	<div class="main-content">
		<div class="row">
			<div class="col-sm-12">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
    
</div>