import { Injectable } from '@angular/core';
import { DataService } from './data.service';
@Injectable({
  providedIn: 'root'
})
export class OrderHelperService {
  public addGarmentCounter: number = 0;
  vendorId: any;
  public order = {
    "currencyId":2,
    "deliveryAddressId": 0,
    "deliveryDate": "",
    "deliveryEndTime": "",
    "deliveryStartTime": "",
    "pickupAddressId": 0,
    "pickupDate": "",
    "pickupStartTime": "",
    "pickupEndTime": "",
    "deliveryReceiptId": 0,
    "express": false,
    "notifyMethodId": 0,
    "orderAmount": 0,
    "orderDate": "",
    "orderDetails": [],
    "orderId": "",
    "orderNotes": "",
    "storeDropOffDate": "",
    "storeOrder": 0,
    "storeReadyByTime": "",
    "storeRedayByDate": "",
    "userId": 0,
    "vendorId": 0,
    "storeId": 0,
    "storeReadyByDate": '',
    "chrgAmt": 0,
    "discount": 0,
    "taxAmount":0,
//     "others":{
//       "priceListId":0,
//       "deliveryType":0,
//       "isQuickOrder":false,
//       "quickOrderGarmentsCount":0,
//       "appType":"VENTAB"
//    }
  };
  addGarment = {};
  myOrder: any = {};
  selectedTab: any = 0;
 
  

  constructor(private dataService: DataService) {
    this.dataService.getVendorConfig().subscribe((config)=> {
      this.vendorId = config.vendorId;
    });
   }

  // set current order
  public setOrder(data) {
    this.order = {
      "currencyId": data?.currencyId,
      "deliveryAddressId": data?.deliveryAddressId,
      "deliveryDate": data?.deliveryDate,
      "deliveryEndTime": data?.deliveryEndTime,
      "deliveryStartTime": data?.deliveryStartTime,
      "pickupAddressId": data?.pickupAddressId,
      "pickupDate": data?.pickupDate,
      "pickupStartTime": data?.pickupStartTime,
      "pickupEndTime": data?.pickupEndTime,
      "deliveryReceiptId": data?.deliveryReceiptId,
      "express": data?.express,
      "notifyMethodId": data?.notifyMethodId,
      "orderAmount": data?.orderAmount,
      "orderDate": data?.orderDate,
      "orderDetails": data?.orderDetails,
      "orderId": data?.orderId,
      "orderNotes": data?.orderNotes,
      "storeDropOffDate": data?.storeDropOffDate,
      "storeOrder": data?.storeOrder,
      "storeReadyByTime": data?.storeReadyByTime,
      "storeRedayByDate": data?.storeRedayByDate,
      "userId": data?.userId,
      "vendorId": this.vendorId,
      "storeId": data?.storeId,
      "storeReadyByDate": data?.storeReadyByDate,
      "chrgAmt": data?.chrgAmt,
      "discount": data?.discount,
      "taxAmount": data?.taxAmount,
    }
  }

  //get current order
  public getOrder() {
    return this.order;
  }

  //set garment counter
  public setAddGarmentCounter() {
    this.addGarmentCounter++;
  }

  //get garment counter
  public getAddGarmentCounter() {
    return this.addGarmentCounter;
  }

  //set default care
  public setDefaultCare(defaultCare) {
    sessionStorage.setItem('defaultCare', JSON.stringify(defaultCare))
  }

  //get default care
  public getDefaultCare() {
    let care = sessionStorage.getItem('defaultCare');
    let defaultCare = JSON.parse(care);
    return defaultCare;
  }

  //get selected care id for care update
  public selectedCare(garmentsCare?) {
    let defaultCare = garmentsCare ? garmentsCare : this.getDefaultCare();
    let selectedCare = {};
    let garmentGroups = Object.keys(defaultCare);
    for (let garment of garmentGroups) {
      let selected = defaultCare[garment].find(list => list.selected);
      selectedCare[garment] = selected ? selected : { name: '' }
    }
    return selectedCare;
  }

  public initAddGarment() {
    this.addGarment = {};
  }

  //add garment 
  public setAddGarment(serviceName, garmentName, garment) {
    if (!this.addGarment[serviceName]) {
      this.addGarment[serviceName] = {};
    }
    if (!this.addGarment[serviceName][garmentName]) {
      this.addGarment[serviceName][garmentName] = [];
      this.addGarment[serviceName][garmentName].push(garment);
    } else {
      this.addGarment[serviceName][garmentName].push(garment);
    }
  }

  //set garment label and order detail id
  public setAddGarmentValue(serviceName, garmentName, data) {
    let index =  this.addGarment[serviceName][garmentName].findIndex(item=> item.garment.garmentTagId == data.garment.garmentTagId);
    if(index != -1) {
      this.addGarment[serviceName][garmentName][index]['stains'] = data.stains ? data.stains : [];
      this.addGarment[serviceName][garmentName][index]['damages'] =  data.damages ? data.damages : [];
      this.addGarment[serviceName][garmentName][index].orderDetailId = data?.orderDetailId;
      this.addGarment[serviceName][garmentName][index]['garment'].garmentLabelId = data?.garment.garmentLabelId;
    }
  }

  // get garment barcode and details
  public getAddedGarment() {
    return this.addGarment;
  }

  //remove added garment
  public removeAddedGarment(serviceName, garmentName) {
    //let garmentIndex =  this.addGarment[TabName][group].findIndex(garment=> garment.barcode == barcode);
    this.addGarment[serviceName][garmentName].splice(0, 1);
  }
  
   //set my order
   public setMyOrderById(order) {
    sessionStorage.setItem('myOrder', JSON.stringify(order))
  }

  //get my order
  public getMyOrderById() {
    let order = sessionStorage.getItem('myOrder');
    let myOrder = JSON.parse(order);
    return myOrder;
  }

  public setSelectedTab(tab) {
    this.selectedTab = tab;
  }

  public getSelectedTab() {
    return this.selectedTab;
  }

}
