import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/core/services/dialog.service';
import { GetStartedService } from 'src/app/core/services/get-started.service';

@Component({
  selector: 'app-no-serviceable-area',
  templateUrl: './no-serviceable-area.component.html',
  styleUrls: ['./no-serviceable-area.component.scss']
})
export class NoServiceableAreaComponent implements OnInit {
  zipCode: any;
  dialCode: any;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private getStartedService: GetStartedService,
    private toasterService: ToastrService,
    private dialogService: DialogService,
    private router: Router
  ) { }

  notifyForm = this.fb.group({
    firstName: [''],
    lastName: [''],
    email: ['', Validators.required],
    phoneNumber: ['', Validators.required],
  });

  ngOnInit(): void {
    // this.route.queryParams.subscribe(params => {
    //   this.zipCode = params['zip'];
    // });
    this.zipCode = window.history.state['zip'];
  }

  onNotify() {
    this.notifyForm.markAllAsTouched();
    if (this.notifyForm.valid) {
      let notify = this.notifyForm.value;
      var phoneComponents = {
        dialCode: this.dialCode,
        number: notify.phoneNumber.substring(this.dialCode.length + 1, notify.phoneNumber.length)
      };
      let body = {
        "firstName": notify.firstName,
        "lastName": notify.lastName,
        "phoneNumber": phoneComponents.number,
        "countryCode": phoneComponents.dialCode,
        "emailId": notify.email,
        "userType": "customer",
        "zipCode": this.zipCode,
        "countryId": "101"
      }
      this.getStartedService.serviceableAreaNotify(body).subscribe(
        (res: any) => {
          let info = {
            title: '',
            msg: res.message
          }
          this.dialogService.successMsg(info)
            .afterClosed().subscribe(res => {
               this.router.navigate(['/home']);
            });
        },
        (err) => {
        }
      )
    }

  }

  CountryChangedEvent(e) {
    this.dialCode = e.dialCode;
  }
}
