import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppImages } from 'src/app/core/model/app-images.model';
import { MenuItemInfo } from 'src/app/core/model/menu-item-info.model';
import { SocialMediaInfo } from 'src/app/core/model/social-media-info.model';
import { AppService } from 'src/app/core/services/app.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  playStoreUrl  = 'https://play.google.com/store/apps/details?id=com.cleaners.dryo&pcampaignid=web_share'; 
  appStoreUrl = 'https://apps.apple.com/us/app/premier-cleaners/id6566182626';
  socialMediaInfo: SocialMediaInfo[] = [];  
  vendorName: String;
  dryoLogoIcon: String;
  upArrowIcon: String;
  footerMenu: MenuItemInfo[] = [];
  loading: boolean;

  constructor(private dataService: DataService, private appService: AppService, private configService: ConfigService) {
      // initialize icons
      this.dataService.getVendorConfig().subscribe(config => {
        if(config) {
          this.dryoLogoIcon = config.vendorLogo;
          this.vendorName = config.vendorName;
        }
      });
      // this.dryoLogoIcon = appService.getDryoLogoImagePath();
      this.upArrowIcon = appService.getImagePath(AppImages.UP_ARROW);
  }  
  
  ngOnInit() {  
  }
  
  getSocialMediaLinks(): SocialMediaInfo[] { // returs an array of SocialMediaLink objects
    this.loading = this.configService.getLoading();
    if (!this.loading && this.socialMediaInfo.length === 0)
        this.socialMediaInfo = this.configService.getSocialMediaLinks(); 
    return this.socialMediaInfo;
  }

  // getSocialMediaLinks2() { // returns a JSON Array
  //     return  this.configService.getSocialMediaLinksJSONArray();      
  // }

  getVendorName() { // returns a string
    this.loading = this.configService.getLoading();
    if (!this.loading && this.vendorName === undefined) 
      this.vendorName = this.configService.getVendorName();     
    return this.vendorName;
  }

  topFunction(){
    document.body.scrollTop = 0;
    window.scroll({ top: 0, behavior: "smooth" });
  }

  getSpinnerObservable(): BehaviorSubject<boolean> {
    const spinnerObservable$ = this.configService.getSpinnerObservable();
    return spinnerObservable$;
   }
 
   getFooterMenu(): MenuItemInfo[] { // returs an array of FooterrMenu items    
    this.loading = this.configService.getLoading();
     if (!this.loading && this.footerMenu.length === 0)
         this.footerMenu = this.configService.getFooterMenu(); 
     return this.footerMenu;
   }

   getVendorColor(): string {
    // Assume you have a method that determines the color based on vendor ID
    return '#abf57c'; // Example method to get the color
  }

  redirectToStore() {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    if (/android/i.test(userAgent)) {
      // Redirect to Google Play Store
      window.open(this.playStoreUrl, '_blank');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
      // Redirect to Apple App Store
      window.open(this.appStoreUrl, '_blank');
    } else {
      // Fallback or desktop behavior
      window.open(this.appStoreUrl, '_blank');
    }
  }
  
}
